import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { gptModels } from "../../../../constants/GPTModel";

const CreateAssistantModal = ({
  showCreateAssistantModal,
  handleCloseCreateModal,
  handleShowCreateModal,
  assistantData,
  setAssistantData,
  handleToolChange,
  handleFileChange,
  removeFile,
  formatBytes,
  showFileLimitMessage,
  handleCreateAssistant,
}) => {
  const codeInterpreterChecked = useMemo(
    () => assistantData?.tools.some((tool) => tool.type === "code_interpreter"),
    [assistantData.tools]
  );

  const retrievalChecked = useMemo(
    () => assistantData?.tools.some((tool) => tool.type === "retrieval"),
    [assistantData?.tools]
  );
  return (
    <>
      <Modal show={showCreateAssistantModal} onHide={handleCloseCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Create Assistant</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6 className="mb-0">Assistant Name:</h6>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                value={assistantData?.name}
                onChange={(e) =>
                  setAssistantData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }))
                }
                placeholder="Enter name"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                <h6 className="mb-0">instructions:</h6>
              </Form.Label>
              <Form.Control
                value={assistantData?.instructions}
                onChange={(e) =>
                  setAssistantData((prevData) => ({
                    ...prevData,
                    instructions: e.target.value,
                  }))
                }
                as="textarea"
                placeholder="Enter instructions"
                rows={2}
              />
            </Form.Group>
            <div>
              <h6 className="mb-0">Tools</h6>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Code interpreter"
                      value="code_interpreter"
                      checked={codeInterpreterChecked}
                      onChange={(e) => handleToolChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Retrieval"
                      value="retrieval"
                      checked={retrievalChecked}
                      onChange={(e) => handleToolChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div>
              <h6>Model:</h6>
              <Form.Select
                value={assistantData?.model || "default"}
                onChange={(e) =>
                  setAssistantData((prevData) => ({
                    ...prevData,
                    model: e.target.value === "default" ? "" : e.target.value,
                  }))
                }
                aria-label="Default select example"
              >
                <option>Select Model</option>
                {gptModels.map((model) => (
                  <option key={model.id} value={model.value}>
                    {model.label}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>
                  <h6 className="mb-0 mt-3">Upload File:</h6>
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept=".txt,.pdf,.docs"
                  disabled={!codeInterpreterChecked && !retrievalChecked}
                />
                <div className="text-muted mt-2">
                  {assistantData?.files?.length > 0 && (
                    <ul>
                      {assistantData?.files.map((file, index) => (
                        <li key={index}>
                          {typeof file === "string"
                            ? `${file} (${formatBytes(file?.size)})`
                            : `${file?.name} (${formatBytes(file?.size)})`}

                          <span
                            onClick={() => removeFile(index)}
                            className="ms-2"
                            style={{ cursor: "pointer", color: "red" }}
                          >
                            Remove
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {showFileLimitMessage && (
                    <p style={{ color: "red" }}>
                      Max 5 files at a time with a maximum limit of 50 MB size.
                    </p>
                  )}
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="rounded-1" variant="secondary" onClick={handleCloseCreateModal}>
            Close
          </Button>
          <Button className="rounded-1" variant="primary" onClick={handleCreateAssistant}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateAssistantModal;
