import React from "react";
import { FaRegLightbulb } from "react-icons/fa";
import { MdSettingsSuggest } from "react-icons/md";

const HomeService = () => {
  return (
    <div className="service-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="single-service-wrap">
              <div className="thumb">
                <MdSettingsSuggest className="service-icon" />
              </div>
              <div className="single-service-details">
                <h4>
                  AI-Powered Magic: Instantly Create Captivating Social Media Posts with Ease!
                </h4>
                <p>
                  Elevate your property listings with AI! Our platform empowers Realtors to effortlessly create captivating social media posts for new properties. With templates and AI-driven room remodeling, showcase your listings like never before. Save time and maximize engagement. Join us today and revolutionize your property marketing.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-10">
            <div className="single-service-wrap responsive-item">
              <div className="thumb">
                <FaRegLightbulb className="service-icon" />
              </div>
              <div className="single-service-details">
                <h4>
                  AI-Powered Magic: Instantly Create Captivating Social Media Posts with Ease!
                </h4>
                <p>
                  Unlock the Power of AI: Effortlessly Regenerate Room Images for Stunning Transformations!
                  Revolutionize room transformations with AI! Our platform offers seamless room image regeneration, powered by artificial intelligence. Easily remodel any space with just a few clicks. Enhance property listings and captivate buyers with stunning visuals. Experience the future of interior design today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeService;
