import React, { useEffect, useState } from "react";
import ChatContent from "../../../Common/ChatAssistant/ChatContent";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import Spinner from "react-bootstrap/Spinner";

const SalesAssistantUI = () => {
  const [threadId, setThreadId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/get-thread-info?type=sales`, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        setThreadId(response?.data?.thread?.threadId);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="content-generator-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12">
            <div className="">
              <h2 className="text-center">Sales Assistant</h2>
              <p className="text-center">
                This is your Sales assistant. If you have any sales-related
                issues, please let me help you.
              </p>
            </div>
            <div className="content-generator-inner card">
              <div className="main__chatbody">
                <div className="col-12">
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100vh" }}
                    >
                      <Spinner animation="grow" />
                    </div>
                  ) : (
                    <ChatContent threadId={threadId} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesAssistantUI;
