import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";

const ViewUserPlan = () => {
  let { planId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserPlanData = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/user-plan/${planId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        // console.log(response?.data?.data)
        setPlan(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserPlanData();

    return () => controller && controller.abort();
  }, [planId]);

  // console.log({error, loading})
  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="order-content">
      <Container>
        <Card className="shadow border-0 rounded p-4 pb-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h2 className="text-center">Plan - {plan?.planName}</h2>
            <button
              className="btn btn-white shadow-sm rounded-2 align-items-center "
              onClick={() => navigate(`/dashboard/user-plan`)}
            >
              <span className="btn-text">Back</span>
            </button>
          </div>
          <div className="order-container">
            <div className="mb-3 order-header">
              <p className="d-flex align-items-center gap-2 flex-wrap">
                <strong>Stripe Price Key:</strong>
                <span>{plan?.stripePriceKey}</span>
              </p>
            </div>
            <div className="order-body">
              <div className="row g-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfBiography}
                    </b>
                    <p className="mt-2">No. Of Biography</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfBlogPost}
                    </b>
                    <p className="mt-2">No. of Blog</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfEmailCampaign}
                    </b>
                    <p className="mt-2">No. of Email</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfImproveContent}
                    </b>
                    <p className="mt-2">No. of Improve Content</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfInstagramQuotes}
                    </b>
                    <p className="mt-2">No. of Instagram Quote</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfLeadsGeneration}
                    </b>
                    <p className="mt-2">No. of Lead Generation</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfNewsletterCampaign}
                    </b>
                    <p className="mt-2">No. of Newsletter</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfRoom}
                    </b>
                    <p className="mt-2">No. of Room Generation</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfSocialMediaContent}
                    </b>
                    <p className="mt-2">No. of Property Description</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfStepByStepGuide}
                    </b>
                    <p className="mt-2">No. of Step by Step Guide</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default ViewUserPlan;
