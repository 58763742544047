// @flow strict

import * as React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo2 from "../../assests/images/logo2.png";

function LimitWarningModal({ handleCloseModal }) {
  const navigate = useNavigate();

  return (
    <div className="limit-warning-modal p-4">
      <img className="warning-logo mb-3" alt="logo" src={logo2} />
      <p className="warning-text  my-3">
        You have reached the maximum number of credits that you can use. You can
        upgrade to increase your credit limit and create more content for your
        business.
      </p>
      <div className="d-flex justify-content-center align-content-end">
        <Button
          variant="success"
          onClick={() => navigate("/buy-credits")}
          className="btn upgrade-btn px-4 my-2"
        >
          Upgrade Now
        </Button>
      </div>
      <div className="d-flex justify-content-center align-content-end">
        <Button
          variant="text"
          onClick={handleCloseModal}
          className="no-upgrade-btn p-0"
        >
          No Thanks
        </Button>
      </div>
    </div>
  );
}

export default LimitWarningModal;
