import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import DeleteModal from "../../component/Modal";

const UserPlan = () => {
  const [dataLoader, setDataLoader] = useState(false);
  const navigate = useNavigate();

  const [userPlan, setUserPlan] = useState(null);
  // Modal State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const fetchUserList = async () => {
    try {
      setDataLoader(true);
      const response = await axiosSecure.get(
        `/user-plans`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      console.log(response)
      setUserPlan(response?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setDataLoader(false);
    }
  };

  const handleDelete = async () => {
    if (itemToDelete) {
      const requestPayload = {
        _id: itemToDelete._id,
      };
      try {
        const response = await axiosSecure.post(
          "/admin/user-plan/delete",
          requestPayload,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        toast.success(response?.data?.message);
        fetchUserList();
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setItemToDelete(null);
      }
    }
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (item) => {
    setShowDeleteModal(true);
    setItemToDelete(item);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(`/user-plans`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        // console.log(response)
        setUserPlan(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setDataLoader(false);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, []);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   const fetchUserData = async () => {
  //     try {
  //       setDataLoader(true);
  //       const response = await axiosSecure.get(`/orders`, {
  //         headers: { Authorization: getAuthorizationHeader() },
  //       });
  //       console.log(response)
  //       // setUserPlan(response?.data?.data);
  //     } catch (err) {
  //       toast.error(err?.response?.data?.message);
  //     } finally {
  //       setDataLoader(false);
  //     }
  //   };
  //   fetchUserData();

  //   return () => controller && controller.abort();
  // }, []);

  if (dataLoader) {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className="flex-grow-1">
        <div className="bg-white mt-1 p-4 rounded-2">
          <div className="row align-items-centner pt-4 pb-3">
            <div className="col-lg-7 col-md-7 col-sm-7 col-8">
              <h2>User Plan Listing</h2>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-5 col-4">
              <div className="d-flex justify-content-end">
                <Link to="/dashboard/user-plan/add" replace className="btn btn-base rounded-2">
                  Add Plan
                </Link>
              </div>
            </div>
          </div>
          {userPlan && userPlan?.length > 0 ? (
            <div className="mt-4 d-flex flex-column gap-3">
              {userPlan?.map((plan, index) => (
                <div className="user-plan-card p-4 rounded-2" key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="plan-title">{plan?.planName}</h3>
                    <h4 className="plan-title">$ {plan?.price}</h4>
                  </div>
                  <div className="plan-items">
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Biography:</span>
                      <span>{plan?.numberOfBiography}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Blog Post:</span>
                      <span>{plan?.numberOfBlogPost}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Email:</span>
                      <span>{plan?.numberOfEmailCampaign}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Improve Content:</span>
                      <span>{plan?.numberOfImproveContent}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Instagram Quotes:</span>
                      <span>{plan?.numberOfInstagramQuotes}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Leed Generation:</span>
                      <span>{plan?.numberOfLeadsGeneration}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Newsletter:</span>
                      <span>{plan?.numberOfNewsletterCampaign}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Room Generation:</span>
                      <span>{plan?.numberOfRoom}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Property Description:</span>
                      <span>{plan?.numberOfSocialMediaContent}</span>
                    </p>
                    <p className="d-flex gap-2">
                      <span className="fw-semibold">No. of Step By Step Guide:</span>
                      <span>{plan?.numberOfStepByStepGuide}</span>
                    </p>
                  </div>
                  <div className="d-flex justify-content-end">
                    <ButtonGroup
                      aria-label="Basic example"
                      className="bg-white p-2"
                    >
                      <Button
                        size="sm"
                        variant="text"
                        onClick={() =>
                          navigate(`/dashboard/user-plan/edit/${plan?._id}`)
                        }
                      >
                        <i className="bi bi-pencil-square"></i>
                      </Button>
                      <Button
                        size="sm"
                        variant="text"
                        onClick={() =>
                          navigate(`/dashboard/user-plan/view/${plan?._id}`)
                        }
                      >
                        <i className="bi bi-eye"></i>
                      </Button>
                      <Button
                        size="sm"
                        variant="text"
                        onClick={() => handleDeleteClick(plan)}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Row>
              <Col>
                {/* <Table responsive>
                  <thead>
                    <tr>
                    <th>Plan Name</th>
                    <th>Price</th>
                    <th>Rooms</th>
                    <th>Biography</th>
                    <th>Property Description</th>
                    <th>BlogPost</th>
                    <th>Newsletter</th>
                    <th>Email Campaign</th>
                    <th>Instagram Quotes</th>
                    <th>Leads Generation</th>
                    <th>Improve Content</th>
                    <th>Step By Step Guide</th>
                    <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table> */}
                <Card className="mt-3 p-3">
                  <Card.Body>
                    <Card.Title className="text-danger text-center">
                      No User plans data available
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <DeleteModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            onDelete={handleDelete}
            // itemName={itemToDelete && itemToDelete.name}
            itemName={`Are you sure delete this user?`}
          />
          {/* <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              itemsPerPage={10}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div> */}
        </div>
      </Container>
    </>
  );
};

export default UserPlan;
