export const ROUTESCONSTANTS = {
  buyCredit: "/buy-credits",
  home: "/",
  generateContent: "/generate-follow-up-content",
  generateBiography: "/generate-biography",
  generateBlog: "/generate-blog",
  generateInsta: "/generate-insta-quotes",
  generateNewsLetter: "/generate-news-letter",
  leedGeneration: "/lead-generation",
  generateEmail: "/generate-email",
  generateRoom: "/generate-room",
  signup: "/sign-up",
  signin: "/sign-in",
  forgetPassword: "/forgot-password",
  resetPassword: "/reset-password",
  verifyResetPassToken: "/verify-reset-pass-token",
  dashboard: "/dashboard",
  profile: "/dashboard/profile",
  subscriptionHistory: "/dashboard/subscription-history",
  assistants: "/dashboard/assistants",
  
  subscriptionHistoryView: "/dashboard/subscription-history/:orderId",
  editProfile: "/dashboard/profile-update",
  updatePassword: "/dashboard/password-update",
  superAdminAllPrompts: "prompts",
  superAdminAddPrompt: "prompt/add",
  superAdminEditPrompt: "prompt/edit/:promptId",
  superAdminClients: "clients",
  superAdminClientsView: "clients/:clientId",
  superAdminUserPlan: "user-plan",
  superAdminAddUserPlan: "user-plan/add",
  superAdminEditUserPlan: "user-plan/edit/:planId",
  superAdminViewUserPlan: "user-plan/view/:planId",
  savedContent: "/saved-content",
  savedRoom: "/saved-room",
  savedRoomWithId: "/saved-room/:roomId",
  termsCondition: "/terms-condition-page",
  paymentSuccess: "/payment-success",
  contentPrompt: "/content-prompt",
  improveContent: "/improve-content",
  stepBystepGuide: "/step-by-step-guide",
  marketingAssistant: "/marketing-assistant",
  salesAssistant: "/sales-assistant",
  assistantId: "/assistants/:assistantId",
  connectRealtor: "/docs/connect-realtor",
};