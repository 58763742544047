import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FaEllipsisH } from "react-icons/fa";
import { MdDelete, MdDriveFileRenameOutline } from "react-icons/md";
import { shortenText } from "../../../Utility/utility";

const ListItem = ({
  thread,
  i,
  activeItem,
  handleThreatClick,
  handleThreadDeleteClick,
  handleUpdateThread,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <li
        className={`single__thread ${i === activeItem ? "active" : ""}`}
        onClick={() => handleThreatClick(thread, i)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {shortenText(thread?.name, 15)}
        {isHovered && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover id="popover-positioned-bottom">
                <Popover.Body className="pophover__body">
                  <div
                    onClick={() => handleThreadDeleteClick(thread?._id)}
                    style={{ color: "#BF3131" }}
                    className="pophover__item"
                  >
                    <div>
                      <MdDelete /> <span> Delete Chat </span>
                    </div>
                  </div>
                  <div
                    className="pophover__item"
                    onClick={() =>
                      handleUpdateThread(thread?._id, thread?.name)
                    }
                  >
                    <MdDriveFileRenameOutline />
                    <span>Rename</span>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <span className="ellipsis__btn" onClick={toggleDropdown}>
              <FaEllipsisH />
            </span>
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};

export default ListItem;
