import React from "react";
import LoadingDots from "../../Common/LoadingDots";

const LoadingComp = () => {
  return (
    <article className="product-case">
      <div className="room-cover d-flex align-items-center justify-content-center px-3">
        <h3 className="text-center">Uploading Photo</h3>
      </div>
      <header className="py-3 text-center">
        <button disabled className="btn dot-loading">
          <span className="">
            <LoadingDots color="white" size="large" />
          </span>
        </button>
      </header>
    </article>
  );
};

export default LoadingComp;
