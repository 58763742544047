import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { convertDate } from "../../../Utility/utility";
import logo from "../../../assests/images/logo2.png";

const InvoicePdf = ({ subscription, user }) => {
  console.log(subscription, "My Subscription");

  return (
    <Document>
      <Page size="A4" style={{ paddingVertical: 40 }} wrap>
        <View style={styles.container}>
          {/* Address */}
          <View style={styles.addressWrapper}>
            <View style={styles.section}>
              <Text style={[styles.addressText, { marginBottom: 20 }]}>
                Date:
                <Text style={{ color: "#374151" }}>
                  {convertDate(new Date())}
                </Text>
              </Text>

              <Text
                style={[styles.addressText, { color: "#374151", fontSize: 12 }]}
              >
                {user.firstName} {user.lastName}
              </Text>
              <Text style={styles.addressText}>{user?.email}</Text>
              <Text style={styles.addressText}>{user?.phoneNumber}</Text>
              <Text style={styles.addressText}>{user.organizationName}</Text>
            </View>

            <View style={[styles.section]}>
              <View style={styles.imageWrapper}>
                <Image style={styles.image} alt="logo" src={logo} />
              </View>
              <Text style={styles.addressTextRight}>Real Estate AI</Text>
              <Text style={styles.addressTextRight}>
                3019 Lynn Street, Somerville, Massachusetts, United States
              </Text>
            </View>
          </View>

          <View style={[styles.dateInvoicesWrapper, { width: "100%" }]}>
            <View style={styles.allDateWrapper}>
              <View style={styles.allDateRow}>
                <Text style={styles.allDateCell}>Activated At:</Text>
                <Text style={styles.allDateCell}>
                  {convertDate(subscription?.activatedAt)}
                </Text>
              </View>

              <View style={styles.allDateRow}>
                <Text style={styles.allDateCell}>Expiration Date:</Text>
                <Text style={styles.allDateCell}>
                  {convertDate(subscription?.expiresAt)}
                </Text>
              </View>
            </View>
            <View style={styles.allInvoiceIdWrapper}>
              <View style={styles.allInvoiceIdRow}>
                <Text style={styles.allInvoiceIdCell}>InvoiceId:</Text>
                <Text style={styles.allInvoiceIdCell}>
                  {subscription?.invoiceId}
                </Text>
              </View>

              <View style={styles.allInvoiceIdRow}>
                <Text style={styles.allInvoiceIdCell}>SubscriptionId:</Text>
                <Text style={styles.allInvoiceIdCell}>
                  {subscription?.subscriptionId}
                </Text>
              </View>
            </View>
          </View>

          {/* Table */}
          <View style={styles.orderTable}>
            <View style={styles.tbody}>
              <View style={styles.tr}>
                <Text style={[styles.td, { width: 50, textAlign: "center" }]}>
                  #
                </Text>
                <Text style={[styles.td, { flex: 1 }]}>Plan Name</Text>
                <Text style={[styles.td, { width: 75, textAlign: "center" }]}>
                  Quantity
                </Text>
                <Text style={[styles.td, { flex: 1, textAlign: "center" }]}>
                  Client
                </Text>
                <Text style={[styles.td, { width: 100, textAlign: "right" }]}>
                  Total
                </Text>
              </View>

              <View style={styles.tr} key={0} wrap={false}>
                <Text style={[styles.td, { width: 50, textAlign: "center" }]}>
                  {0 + 1}
                </Text>
                <Text style={[styles.td, { flex: 1 }]}>
                  {subscription?.plan[0]?.planName}
                </Text>
                <Text style={[styles.td, { width: 75, textAlign: "center" }]}>
                  1
                </Text>
                <Text style={[styles.td, { flex: 1, textAlign: "center" }]}>
                  {subscription?.plan[0]?.numberOfClient}
                </Text>
                <Text style={[styles.td, { width: 100, textAlign: "right" }]}>
                  {subscription?.amountTotal / 100}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ width: "100%" }} wrap={false}>
            {/* Border */}
            <View style={styles.singleBorder} />

            {/* Total */}
            <View style={styles.totalCountWrapper}>
              <View style={styles.totalCountRow}>
                <Text style={styles.totalCountCell}>Sub Total</Text>
                <Text style={styles.totalCountCell}>
                  ${subscription?.amountSubtotal / 100}
                </Text>
              </View>
              <View style={styles.totalCountRow}>
                <Text style={styles.totalCountCell}>Discount</Text>
                <Text style={styles.totalCountCell}>$0.00</Text>
              </View>
              <View style={styles.totalCountRow}>
                <Text style={styles.totalCountCell}>Tax</Text>
                <Text style={styles.totalCountCell}>$0.00</Text>
              </View>
            </View>
            <View style={{ width: "100%" }} wrap={false}>
              <View style={styles.singleBorder} />
              <View style={styles.totalCountWrapper}>
                <View style={styles.totalCountRow}>
                  <Text style={[styles.totalCountCell, { fontSize: 12 }]}>
                    Total
                  </Text>
                  <Text style={[styles.totalCountCell, { fontSize: 12 }]}>
                    ${subscription?.amountTotal / 100}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
    flex: 1,
    marginLeft: 50,
    marginRight: 50,
  },

  addressWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: 30,
  },

  dateInvoicesWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-between",
    marginBottom: 40,
  },

  imageWrapper: {
    display: "flex",
    width: "200px",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginBottom: 30,
  },

  image: {
    width: 140,
    alignContent: "right",
    marginBottom: "-20pt",
  },

  section: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
  },

  addressText: {
    fontSize: 11,
    color: "#6B7280",
    fontWeight: 400,
    marginBottom: 5,
  },
  addressTextRight: {
    fontSize: 11,
    color: "#6B7280",
    fontWeight: 400,
    marginBottom: 5,
    textAlign: "right",
  },

  orderTable: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  thead: {
    width: "100%",
    backgroundColor: "#F3F4F6",
    display: "flex",
    flexDirection: "row",
  },

  th: {
    fontSize: 11,
    // fontFamily: "Lato Bold",
    color: "#374151",
    padding: "12pt 16pt",
    borderRightWidth: 1,
    borderRightColor: "#ffffff",
    borderRightStyle: "solid",
  },

  tbody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  tr: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },

  td: {
    fontSize: 11,
    color: "#6B7280",
    padding: "12pt 16pt",
    borderTopWidth: 1,
    borderTopColor: "#F3F4F6",
    borderTopStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#ffffff",
    borderRightStyle: "solid",
  },

  singleBorder: {
    width: "50%",
    display: "flex",
    marginLeft: "auto",
    borderTopWidth: 1,
    borderTopColor: "#F3F4F6",
    borderTopStyle: "solid",
    marginBottom: 2,
  },

  // Date CSS Start
  allDateWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "auto",
    borderTopWidth: 1,
    borderTopColor: "#F3F4F6",
    borderTopStyle: "solid",
    marginRight: "10pt",
  },

  allDateRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    // gap: "5pt"
  },

  allDateCell: {
    fontSize: 11,
    color: "#6B7280",
    padding: "8pt 0",
    marginRight: "20pt",
  },
  // Date CSS End

  // InvoiceId CSS Start
  allInvoiceIdWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginLeft: "auto",
    borderTopWidth: 1,
    borderTopColor: "#F3F4F6",
    borderTopStyle: "solid",
    marginRight: "10pt",
  },

  allInvoiceIdRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  allInvoiceIdCell: {
    fontSize: 11,
    color: "#6B7280",
    padding: "8pt 0",
    marginRight: "20pt",
  },

  totalCountWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    borderTopWidth: 1,
    borderTopColor: "#F3F4F6",
    borderTopStyle: "solid",
  },

  totalCountRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  totalCountCell: {
    fontSize: 11,
    color: "#6B7280",
    padding: "8pt 16pt 2pt",
  },
});
