import React from "react";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { Link } from "react-router-dom";
import { getLogedInUser } from "../../../Utility/utility";
import PageHeader from "../../../component/PageHeader";
import SalesAssistantUI from "../../../component/AIContentGenerate/SalesAssistant";

const SalesAssistant = () => {
  return (
    <>
      <PageHeader />
      {!getLogedInUser ? (
        <section className="content-generator-area">
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-xl-8 col-lg-9 content-g-signin-text text-center">
                <h6 className="million-title">
                  Over 1 million users have used RealEstate AI so far.
                </h6>
                <h1 className="fw-bold mb-3">
                  Generate news letter for real estate without any thinking!
                </h1>
                <p>
                  Enjoy the benefits of a free account and unlock the
                  opportunity to transform your space. Sign up now and receive 3
                  credits to create stunning room images and captivating social
                  media posts.
                </p>
                <p className="mb-3 fw-medium">
                  If you’re already a member, simply sign in here.
                </p>
                <Link
                  className="btn btn-base d-inline-flex align-items-center"
                  to={ROUTESCONSTANTS.signin}
                >
                  <span className="btn-text">Sign in</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <SalesAssistantUI />
      )}
    </>
  );
};

export default SalesAssistant;
