import React from "react";
import HeroBanner from "../../component/Homepage/Banner/HomeBanner";
import HomeShowcase from "../../component/Homepage/HomeDemo";
import HowWorks from "../../component/Homepage/HowWorks/HowWorks";
import HomeLevelup from "../../component/Homepage/Levelup";
import HomeService from "../../component/Homepage/Service/HomeService";

function Home() {
  return (
    <>
      <HeroBanner />
      <HomeService />
      <HowWorks />
      <HomeShowcase />
      <HomeLevelup />
    </>
  );
}

export default Home;
