import React, { useMemo, useState } from "react";
import { MdAccountCircle, MdLogin } from "react-icons/md";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { limitWord } from "../../../Utility/utility";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";
import DropdownBackdrop from "./Backdrop";

const MainNavigation = ({ firstName }) => {
  const [dropdownOpened, toggleDropdownOpened] = useState(false);
  const navigate = useNavigate();
  const truncatedFirstName = useMemo(() => {
    if (firstName) return limitWord(firstName, 10);
    return "...";
  }, [firstName]);

  const handleDropdownClick = (clickFor) => {
    if (clickFor === "logout") {
      logout();
      navigate(0);
    } else navigate(clickFor);
    toggleDropdownOpened((opened) => !opened);
  };

  return (
    <div className="nav-right-part nav-right-part-desktop">
      <ul className="nav-items-list d-flex justify-content-end align-items-center">
        <li>
          {/* "active" should be added while switching the route */}
          <NavLink
            className={({ isActive }) =>
              `regular-nav-link d-inline-flex align-items-center px-3 ${
                isActive ? "active" : " "
              } `
            }
            to={ROUTESCONSTANTS.home}
          >
            <span>Home</span>
          </NavLink>
        </li>
        <li>
          {/* "active" should be added while switching the route */}
          <NavLink
            className={({ isActive }) =>
              `regular-nav-link d-inline-flex align-items-center px-3 ${
                isActive ? "active" : " "
              }`
            }
            to={ROUTESCONSTANTS.assistants}
          >
            <span>Assistants</span>
          </NavLink>
        </li>
        <li>
          {/* "active" should be added while switching the route */}
          <NavLink
            className={({ isActive }) =>
              `regular-nav-link d-inline-flex align-items-center px-3 ${
                isActive ? "active" : " "
              }`
            }
            to={ROUTESCONSTANTS.generateRoom}
          >
            <span>Room Generator</span>
          </NavLink>
        </li>
        <li>
          {/* "active" should be added while switching the route */}
          <NavLink
            className={({ isActive }) =>
              `regular-nav-link d-inline-flex align-items-center px-3 ${
                isActive ? "active" : " "
              }`
            }
            to={ROUTESCONSTANTS.contentPrompt}
          >
            <span>Content Generator</span>
          </NavLink>
        </li>
        <li className="dropdown-list-parent">
          {firstName ? (
            <>
              <button
                className="signup-link after-signedIn btn btn-base d-inline-flex align-items-center"
                onClick={() => toggleDropdownOpened((opened) => !opened)}
              >
                <span className="login-icon-wrapper d-inline-flex justify-content-center align-items-center">
                  <span className="login-icon-cover d-inline-flex justify-content-center align-items-center">
                    <MdAccountCircle className="login-icon" />
                  </span>
                </span>{" "}
                <span className="btn-text">{truncatedFirstName}</span>
              </button>
              {dropdownOpened && (
                <>
                  <div className="dropdown-list-wrapper">
                    <button
                      className="dropdown-item-button"
                      onClick={() =>
                        handleDropdownClick(ROUTESCONSTANTS.dashboard)
                      }
                    >
                      Dashboard
                    </button>
                    <button
                      className="dropdown-item-button"
                      onClick={() =>
                        handleDropdownClick(ROUTESCONSTANTS.buyCredit)
                      }
                    >
                      Buy Credits
                    </button>
                    <button
                      className="dropdown-item-button"
                      onClick={() =>
                        handleDropdownClick(ROUTESCONSTANTS.savedContent)
                      }
                    >
                      Saved Content
                    </button>
                    <button
                      className="dropdown-item-button"
                      onClick={() =>
                        handleDropdownClick(ROUTESCONSTANTS.savedRoom)
                      }
                    >
                      Saved Rooms
                    </button>
                    <button
                      className="dropdown-item-button"
                      onClick={() => handleDropdownClick("logout")}
                    >
                      Logout
                    </button>
                  </div>
                  <DropdownBackdrop
                    onClick={() => toggleDropdownOpened(false)}
                  />
                </>
              )}
            </>
          ) : (
            <Link
              className="signup-link btn btn-base d-inline-flex align-items-center"
              to={ROUTESCONSTANTS.signin}
            >
              <span className="login-icon-wrapper d-inline-flex justify-content-center align-items-center">
                <span className="login-icon-cover d-inline-flex justify-content-center align-items-center">
                  <MdLogin className="login-icon" />
                </span>
              </span>{" "}
              <span className="btn-text">{"Sign in"}</span>
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default MainNavigation;
