// @flow strict
import React from "react";
import { RiBardLine } from "react-icons/ri";
import { TbCopy } from "react-icons/tb";
import { toast } from "react-toastify";

function SingleMail({ content, index }) {

  const handleCopyBlog = async (html) => {
    // Create a temporary textarea element
    const tempElement = document.createElement("textarea");

    // Set the value of the textarea to the text content of the HTML,
    // preserving the newlines and formatting.
    tempElement.value = html.replace(/<br\s*\/?>/gi, "\n")
      .replace(/<\/p>\s*<p>/gi, "\n")
      .replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags

    // Append the textarea to the body
    document.body.appendChild(tempElement);

    // Select the textarea content
    tempElement.select();
    tempElement.setSelectionRange(0, 99999); // For mobile devices

    // Copy the content
    document.execCommand("copy");

    // Remove the textarea element
    document.body.removeChild(tempElement);

    // Notify the user that the text has been copied
    toast.success("Email copied to clipboard!");
  };


  return (
    <div className="border mt-4 rounded-1 generated-single-mail">
      <div className="d-flex justify-content-between align-items-center p-3">
        <p className="text-black mail-title">
          <RiBardLine className="icon" />
          <span>Email - {index + 1}</span>
        </p>
        <button
          className="email-copy-btn"
          onClick={() => handleCopyBlog(content?.body)}
        >
          <TbCopy />
          <span>Copy</span>
        </button>
      </div>
      <div
        style={{ borderTop: "1px solid #f1f1f1" }}
        className="bg-white p-3 rounded-1 text-black"
      >
        <p>
          <span style={{ fontWeight: 500 }}>Subject: </span>
          <span>{content?.subject}</span>
        </p>
        <p style={{ whiteSpace: 'pre-line' }} className="text-start mt-5">
          {content?.body}
        </p>
      </div>
    </div>
  );
};

export default SingleMail;