import React from "react";
import Select from "react-select";

const SelectBox = React.forwardRef(
  (
    { defaultValue, onChange, options, className, label, name, onBlur },
    ref
  ) => {
    return (
      <Select
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        name={name}
        className={className}
        placeholder={label ? label : "Select..."}
        onBlur={onBlur}
        ref={ref}
      />
    );
  }
);

export default SelectBox;
