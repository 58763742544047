import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import PaginationComponent from "../../component/Pagination/Pagination";
import SavedRoomList from "../../component/SavedRoomList";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service";

function SavedRooms() {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [loading, setLoading] = useState(false);
  const [savedRoomList, setSavedRoomList] = useState([]);
  const [totalRoomCount, setTotalRoomCount] = useState(0);
  const navigate = useNavigate();

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchRoomData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(
          `/generated-rooms?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        setSavedRoomList(response?.data?.data);
        setTotalRoomCount(Number(response?.data?.paginationData?.totalCount));
      } catch (err) {
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchRoomData();

    return () => controller && controller.abort();
  }, [currentPage, limit]);

  if (loading) {
    return (
      <>
        <PageHeader />
        <section className="saved-rooms">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="user_card_wrapper m-auto">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <PageHeader />
      <section className="saved-rooms">
        <div className="container">
          <div className="section-title text-center">
            <h2>Saved Rooms</h2>
          </div>
          <SavedRoomList list={savedRoomList} />
          <div className="d-flex justify-content-center relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalRoomCount}
              itemsPerPage={limit}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default SavedRooms;
