export const gptModels = [
    {
      id: 1,
      value: "gpt-4-1106-preview",
      label: "gpt-4-1106-preview",
    },
    {
      id: 2,
      value: "gpt-4-0613",
      label: "gpt-4-0613",
    },
    {
      id: 3,
      value: "gpt-4-0314",
      label: "gpt-4-0314",
    },
    {
      id: 4,
      value: "gpt-4",
      label: "gpt-4",
    },
    {
      id: 5,
      value: "gpt-3.5-turbo-16k-0613",
      label: "gpt-3.5-turbo-16k-0613",
    },
    {
      id: 6,
      value: "gpt-3.5-turbo-16k",
      label: "gpt-3.5-turbo-16k",
    },
    {
      id: 7,
      value: "gpt-3.5-turbo-1106",
      label: "gpt-3.5-turbo-1106",
    },
    {
      id: 8,
      value: "gpt-3.5-turbo-16k-0613",
      label: "gpt-3.5-turbo-16k-0613",
    },
    {
      id: 9,
      value: "gpt-3.5-turbo",
      label: "gpt-3.5-turbo",
    },
  ];