import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosOpen } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { getLogedInUser } from "../../Utility/utility";
import RealtorProfile from "./RealtorProfile";

const RegisterForm = () => {
  const logedInUser = getLogedInUser();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loading, showLoading] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [isRealtorVisible, setIsRealtorVisible] = useState(false);
  const [realtorProfile, setRealtorProfile] = useState(null);
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    website: "",
    phoneNumber: "",
    password: "",
    confirmPassword: ""
  });

  // Form submission
  const handleSubmit = async () => {
    if (!inputValues.firstName || !inputValues.email || !inputValues.password || !inputValues.confirmPassword) {
      toast.error("Please fill all the fields");
      return;
    };
    if (inputValues.password !== inputValues.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    };

    showLoading(true);
    const requestPayload = {
      ...inputValues,
      userType: 2,
    };

    try {
      const response = await axiosOpen.post("auth/register", requestPayload);
      const userDetails = {
        user: response?.data?.data,
        token: response?.data?.token,
        realtorProfile: realtorProfile,
      };

      if (userDetails.user._id && realtorProfile) {
        try {
          await axiosOpen.post("auth/save-profile", {
            userId: userDetails.user._id,
            profile: realtorProfile,
          });
        } catch (error) {
          throw new Error(error?.response?.data?.message);
        }
      }
      toast.success(response.data.message);
      localStorage.userDetails = JSON.stringify(userDetails);
      navigate(ROUTESCONSTANTS.home);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      showLoading(false);
    }
  };

  if (logedInUser) {
    return <Navigate to={ROUTESCONSTANTS.home} replace={true} />;
  }

  return (
    <>
      <PageHeader />
      <div className="signup-page-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <div className="signup-inner card">
                <div className="text-center mb-4">
                  <h2>Sign Up</h2>
                  <p
                    onClick={() => setIsRealtorVisible(true)}
                    className="reator-profile-button">
                    Connect with Realtor profile
                  </p>
                </div>
                <div className="form-group mb-3">
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={inputValues.firstName}
                    onChange={(e) => setInputValues({ ...inputValues, firstName: e.target.value })}
                  />

                </div>

                <div className="form-group mb-3">
                  {/* <label htmlFor="lastName">Last Name</label> */}
                  <Form.Control
                    placeholder="Last Name"
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={inputValues.lastName}
                    onChange={(e) => setInputValues({ ...inputValues, lastName: e.target.value })}
                  />

                </div>

                <div className="form-group mb-3 emailField">
                  {/* <label htmlFor="email">Email</label> */}
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={inputValues.email}
                    onChange={(e) => setInputValues({ ...inputValues, email: e.target.value })}
                  />

                </div>

                <div className="form-group mb-3">
                  {/* <label htmlFor="website">Website</label> */}
                  <Form.Control
                    placeholder="Website Link"
                    type="text"
                    className="form-control"
                    id="website"
                    name="website"
                    value={inputValues.website}
                    onChange={(e) => setInputValues({ ...inputValues, website: e.target.value })}
                  />

                </div>

                <div className="form-group mb-3">
                  {/* <label htmlFor="phoneNumber">Phone Number</label> */}
                  <Form.Control
                    placeholder="Phone Number"
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={inputValues.phoneNumber}
                    onChange={(e) => setInputValues({ ...inputValues, phoneNumber: e.target.value })}
                  />

                </div>

                <div
                  className="form-group mb-3"
                  style={{ position: "relative" }}
                >
                  {/* <label htmlFor="password">Password</label> */}
                  <Form.Control
                    placeholder="Enter password"
                    type={visible ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    value={inputValues.password}
                    onChange={(e) => setInputValues({ ...inputValues, password: e.target.value })}
                  />


                  {visible ? (
                    <AiOutlineEye
                      size={25}
                      onClick={() => setVisible(false)}
                      className="visibleIcon"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      size={25}
                      onClick={() => setVisible(true)}
                      className="visibleIcon"
                    />
                  )}
                </div>

                <div
                  className="form-group mb-3"
                  style={{ position: "relative" }}
                >
                  {/* <label htmlFor="confirmPassword">Confirm Password</label> */}
                  <Form.Control
                    placeholder="Enter confirm password"
                    type={confirmVisible ? "text" : "password"}
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={inputValues.confirmPassword}
                    onChange={(e) => setInputValues({ ...inputValues, confirmPassword: e.target.value })}
                  />

                  {confirmVisible ? (
                    <AiOutlineEye
                      size={25}
                      onClick={() => setConfirmVisible(false)}
                      className="visibleIcon"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      size={25}
                      onClick={() => setConfirmVisible(true)}
                      className="visibleIcon"
                    />
                  )}
                </div>

                <div className="col-12 my-4">
                  <button
                    type="submit"
                    className="btn btn-base w-100 create-account-btn"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Create Account
                  </button>
                </div>
                <div className="col-12 text-center">
                  <span>Already have an account?</span>{" "}
                  <Link to={ROUTESCONSTANTS.signin} className="singin_like">
                    <strong>Sign In</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RealtorProfile
        isRealtorVisible={isRealtorVisible}
        setIsRealtorVisible={setIsRealtorVisible}
        setInitialValues={setInputValues}
        setRealtorProfile={setRealtorProfile}
      />
    </>
  );
};

export default RegisterForm;
