import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, Navigate, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getLogedInUser } from "../../Utility/utility";
import { axiosOpen } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../constants/Routes";
const LOGIN_URL = "auth/login";

const LoginForm = () => {
  const logedInUser = getLogedInUser();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // Define Yup validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
  };
  // Form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    const requestPayload = {
      ...values,
    };

    if (rememberMe) {
      localStorage.setItem("email", values.email);
      secureLocalStorage.setItem("password", values.password);
    }

    try {
      const response = await axiosOpen.post(LOGIN_URL, requestPayload);

      const userDetails = {
        user: response?.data?.data,
        token: response?.data?.token,
        realtorProfile: response?.data?.realtorProfile,
      };
      toast.success(response.data.message);
      localStorage.userDetails = JSON.stringify(userDetails);
      navigate(ROUTESCONSTANTS.home);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRememberMe = (e) => {
    if (!e.target.checked) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = secureLocalStorage.getItem("password");

    if (savedEmail && savedPassword) {
      initialValues.email = savedEmail;
      initialValues.password = savedPassword;
      // setEmail(savedEmail);
      // setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  if (logedInUser) {
    return <Navigate to={ROUTESCONSTANTS.home} replace={true} />;
  }

  return (
    <>
      <PageHeader />
      <div className="signup-page-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form className="signup-inner card">
                    <div className="text-center mb-4">
                      <h2>Sign In</h2>
                    </div>

                    <div className="form-group mb-3 emailField">
                      {/* <label htmlFor="email">Email</label> */}
                      <Field
                        placeholder="Email"
                        type="email"
                        className={`form-control ${errors.email && touched.email ? "is-invalid" : ""
                          }`}
                        id="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div
                      className="form-group mb-3"
                      style={{ position: "relative" }}
                    >
                      {/* <label htmlFor="password">Password</label> */}
                      <Field
                        placeholder="Enter password"
                        type={visible ? "text" : "password"}
                        className={`form-control ${errors.password && touched.password
                          ? "is-invalid"
                          : ""
                          }`}
                        id="password"
                        name="password"
                      />

                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                      {visible ? (
                        <AiOutlineEye
                          size={25}
                          onClick={() => setVisible(false)}
                          className="visibleIcon"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={25}
                          onClick={() => setVisible(true)}
                          className="visibleIcon"
                        />
                      )}
                    </div>

                    <div className="col-12">
                      <label className="my-2 my-checkbox d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={rememberMe}
                          onChange={handleRememberMe}
                        />
                        Remember me
                      </label>
                    </div>

                    <div className="col-12 mb-4">
                      <button className="btn btn-base w-100 sign-form-btn">
                        Sign In
                      </button>
                    </div>
                    <div className="col-12 link-wrapper">
                      <Link to={ROUTESCONSTANTS.forgetPassword}>
                        Forgot Password
                      </Link>
                      <Link to={ROUTESCONSTANTS.signup}>Signup</Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
