import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { logout } from "../../../service";
import PaginationComponent from "../../Pagination/Pagination";
import PromptCard from "./PromptCard";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { getLogedInUser } from "../../../Utility/utility";

function AllPrompt() {
  const logedInUser = getLogedInUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [promptList, setPromptList] = useState([]);
  const [inactivePromptWarning, setInactivePromptWarning] = useState(false);
  const [totalPromptCount, setTotalPromptCount] = useState(0);
  const navigate = useNavigate();

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchPromptData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(
          `/admin/prompts?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        setPromptList(response?.data?.data);
        if (
          response.data.data.length === 0 ||
          !response.data.data[0].isActive
        ) {
          setInactivePromptWarning(true);
        } else {
          setInactivePromptWarning(false);
        }
        setTotalPromptCount(Number(response?.data?.paginationData?.totalCount));
      } catch (err) {
        console.log(err);
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      } finally {
        setLoading(false);
      }
    };

    if (logedInUser && logedInUser.userType === 1) {
      fetchPromptData();
    }

    return () => controller && controller.abort();
  }, [currentPage, limit]);

  const fetchPromptDataForced = async () => {
    setLoading(true);
    try {
      const response = await axiosSecure.get(
        `/admin/prompts?page=${currentPage}&limit=${limit}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setPromptList(response?.data?.data);
      if (response.data.data.length === 0 || !response.data.data[0].isActive) {
        setInactivePromptWarning(true);
      } else {
        setInactivePromptWarning(false);
      }
      setTotalPromptCount(Number(response?.data?.paginationData?.totalCount));
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      }
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const activeThisPrompt = async (nominatedPrompt) => {
    setLoading(true);
    try {
      const requestPayload = {
        promptId: nominatedPrompt._id,
        title: nominatedPrompt.title,
        prompt: nominatedPrompt.prompt,
        isActive: true,
        templateIdentifier: nominatedPrompt.templateIdentifier,
      };
      const response = await axiosSecure.put(
        `/admin/prompt/edit`,
        requestPayload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response.data.message);
      await fetchPromptDataForced();
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      }
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteThisPrompt = async (nominatedPromptId) => {
    setLoading(true);
    try {
      const requestPayload = {
        promptId: nominatedPromptId,
      };
      const response = await axiosSecure.put(
        `/admin/prompt/delete`,
        requestPayload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response.data.message);
      await fetchPromptDataForced();
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      }
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <div className="user_card_wrapper m-auto">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return logedInUser && logedInUser.userType === 1 ? (
    <div className="all-prompt-container">
      {inactivePromptWarning ? (
        <div className="prompt-body">
          <div className="prompt-header d-flex">
            <h4 className="prompt-title warning-title text-center mb-0">
              No Active Prompt Found. The content generation process is running
              with the default prompt.
            </h4>
          </div>
        </div>
      ) : null}
      {promptList.length > 0 &&
        promptList.map((prompt) => (
          <PromptCard
            key={prompt._id}
            prompt={prompt}
            deleteThisPrompt={() => deleteThisPrompt(prompt._id)}
            makeItActive={() => activeThisPrompt(prompt)}
            editThisPrompt={() =>
              navigate(`/dashboard/prompt/edit/${prompt._id}`)
            }
          />
        ))}
      <div className="d-flex justify-content-center">
        <PaginationComponent
          total={totalPromptCount}
          itemsPerPage={limit}
          currentPage={currentPage}
          onPageChange={(page) => handlePagination(page)}
        />
      </div>
    </div>
  ) : (
    <h2 className="text-center">Page Not Found</h2>
  );
}

export default AllPrompt;
