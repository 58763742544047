import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import "./ChatList.scss";
import DeleteThreadModal from "./DeleteModal";
import ThreadModal from "./ThreadModal";

const ChatList = ({ setThreadId, threadId, type }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [thread, setThread] = useState("");
  const [isThreadLoading, setIsThreadLoading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const createAssistantThread = async () => {
    const payload = {
      name: thread,
      type: type,
    };

    try {
      setIsThreadLoading(true);

      const response = await axiosSecure.post(
        "/create-assistant-tread",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      const responseData = response?.data;

      const { success, messages, thread } = responseData;
      handleClose();
      toast.success(messages);

      // setThread(thread?.name)

      // if (success && messages === "Tread created" && tread) {
      //   const { id } = tread;
      //   toast.success(messages);
      // } else {
      //   toast.error("Failed to create assistant thread:", messages);
      // }
    } catch (error) {
      console.error("Error while fetching data:", error);
    } finally {
      setIsThreadLoading(false);
    }
  };

  // Delete Thread Functionalities
  const deleteThreadHandler = async () => {
    try {
      // setIsThreadLoading(true);

      const payload = {
        thread_id: itemToDelete,
      };

      const response = await axiosSecure.post(`/delete-thread`, payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      const responseData = response?.data;
      const { success, messages } = responseData;

      toast.success(messages);
      handleClose();

      // if (success && messages === "Conversation deleted successfully") {
      //   toast.success(messages);
      //   allThreads();
      // } else {
      //   toast.error("Failed to delete thread:", messages);
      // }
    } catch (error) {
      console.error("Error while fetching data:", error);
    } finally {
      setItemToDelete(null);
      // setIsThreadLoading(false);
    }
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (threadId) => {
    setShowDeleteModal(true);
    setItemToDelete(threadId);
  };

  return (
    <>
      <div className="main__chatlist">
        <div className="chatlist__heading">
          {/* <button className="btn-nobg">
            <FaEllipsisH />
          </button> */}

          <div className="search_wrap">
            <input type="text" placeholder="Search Here" required />
            <button className="search-btn">
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="chatList__search">
          <div className="chatlist__items">
            <div className="chatlist__item">
              <div className="user__name" onClick={handleShow}>
                Create Assistant
              </div>
            </div>
          </div>
        </div>
      </div>

      <ThreadModal
        show={show}
        handleClose={handleClose}
        thread={thread}
        setThread={setThread}
        createAssistantThread={createAssistantThread}
      />

      {/* Delete Confirm Modal */}
      <DeleteThreadModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={deleteThreadHandler}
        itemName={`Are you sure delete this Thread?`}
      />
    </>
  );
};

export default ChatList;
