// @flow strict
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { BsCheck2All } from "react-icons/bs";
import { Link } from "react-router-dom";
import { unlimitedCreditEmails } from "../../../config/unlimitedCreditEmails";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { isExpired } from "../../../Utility/utility";

function GenerateBiographyUI({
  setAdditionalInfo,
  additionalInfo,
  handleSubmit,
  outputData,
  loading,
  handleCopyBiography,
  logedInUser,
  isCopied,
  remaining,
}) {

  console.log(isExpired())
  return (
    <div className="content-generator-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            {unlimitedCreditEmails.includes(logedInUser.email) ? (
              <h6 className="text-center million-title mb-3">
                You have an unlimited amount of credit available.
              </h6>
            ) : (
              isExpired() ?
                <h6 className="text-center mb-3">
                  <span className="text-danger">You subscription plan has been expired.</span>
                  <span>{" "}Buy more
                    credits{" "}</span>
                  <Link
                    to={ROUTESCONSTANTS.buyCredit}
                    className="text-blue-theme million-title"
                  >
                    here
                  </Link>
                  .
                </h6>
                :
                <h6 className="text-center million-title mb-3">
                  You have {remaining?.biographyRemaining} credit left. Buy more
                  credits{" "}
                  <Link
                    to={ROUTESCONSTANTS.buyCredit}
                    className="text-blue-theme"
                  >
                    here
                  </Link>
                  .
                </h6>
            )}
            <div className="content-generator-inner card">
              <div className="text-center mb-4">
                <h2>Biography Generator</h2>
              </div>
              {/* <form onSubmit={handleSubmit} className="">

                <div className="row">
                  <div className="col-12">
                    <TextField
                      placeholder="First Name"
                      value={logedInUser?.firstName}
                      disabled
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      placeholder="Last Name"
                      value={logedInUser?.lastName}
                      disabled
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      placeholder="Email"
                      value={logedInUser?.email}
                      disabled
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      placeholder="Phone Number"
                      value={logedInUser?.phoneNumber}
                      disabled
                    />
                  </div>
                  <div className="col-12">
                    <TextField
                      placeholder="Website"
                      value={logedInUser?.website}
                      disabled
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <Form.Control
                      as="textarea"
                      placeholder="Additional Info About Yourself (optional)"
                      style={{ height: "100px" }}
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                  </div>


                </div>
              </form> */}
              <div className="col-12 mb-4 d-flex justify-content-center">
                {loading ? (
                  <Button
                    type="submit"
                    className="btn btn-base w-100 content-generation-btn"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ps-1">
                      Just a moment, we're generating your biography...
                    </span>
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="btn btn-base w-100 content-generation-btn"
                    disabled={
                      !unlimitedCreditEmails.includes(logedInUser.email) &&
                      remaining?.biographyRemaining === 0
                    }
                    onClick={handleSubmit}
                  >
                    Generate Your Biography
                  </Button>
                )}
              </div>
              {outputData !== "" && (
                <div className="border-top mt-4 pt-4">
                  <div className="mb-3">
                    <div dangerouslySetInnerHTML={{ __html: outputData }}></div>
                  </div>
                  <div className="mt-4 d-flex justify-content-center">
                    <Button
                      className="btn btn-base content-generation-btn"
                      onClick={() => handleCopyBiography(outputData)}
                      disabled={isCopied}
                    >
                      {isCopied ? (
                        <p className="d-flex align-items-center gap-1 text-white">
                          <BsCheck2All className="fw-bold fs-6" />
                          <span>Copied</span>
                        </p>
                      ) : (
                        "Copy"
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateBiographyUI;
