import React, { useEffect, useMemo } from "react";
import PageHeader from "../../component/PageHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPass = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Access query parameters
  const query = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  // Use the query object
  useEffect(() => {
    if (query && query.resetPassToken) {
      localStorage.setItem("resetPassToken", query.resetPassToken);
      navigate("/reset-password");
    } else {
      toast.error("Something went wrong.");
    }
  }, [navigate, query]);

  return (
    <div>
      <PageHeader />
      <div className="signin-page-area pd-top-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 text-center">
              <h5>Reset Password Token</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
