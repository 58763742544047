import React, { useContext } from "react";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { SidebarContext } from "../../../contexts/SidebarContext";
import MainNavigation from "./MainNavigation";
import { getLogedInUser } from "../../../Utility/utility";

// import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  const { activeMenu, setActiveMenu } = useContext(SidebarContext);
  const { width } = useWindowSize();
  const location = useLocation();
  const logedInUser = getLogedInUser();

  // console.log(logedInUser);

  return (
    <div
      className={
        location.pathname !== ROUTESCONSTANTS.home
          ? "navbar-area navbar-area-1 full-bg"
          : "navbar-area navbar-area-1"
      }
    >
      <nav className="navbar navbar-expand-lg">
        <div className="container nav-container">
          {/* <div className="responsive-mobile-menu">
            <button
              className="menu toggle-btn d-block d-lg-none"
              data-target="#dkt_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div> */}
          <div className="logo">
            <Link to={ROUTESCONSTANTS.home}>
              <img src={process.env.PUBLIC_URL + "/logo.png"} alt="img" />
            </Link>
          </div>
          {width <= 991 ? (
            <>
              <button
                className="mobile-nav-gun me-3 d-inline-flex justify-content-center align-items-center"
                onClick={() => setActiveMenu(!activeMenu)}
              >
                <HiBars3CenterLeft />
              </button>
            </>
          ) : (
            <MainNavigation firstName={logedInUser?.firstName ?? null} />
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
