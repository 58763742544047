// @flow strict
import * as React from 'react';
import realtor1 from "../../assests/images/realtor-1.webp";
import realtor2 from "../../assests/images/realtor-2.webp";
import realtor3 from "../../assests/images/realtor-3.webp";
import realtor4 from "../../assests/images/realtor-4.webp";
import realtor5 from "../../assests/images/realtor-5.webp";

function ConnectRealtor() {
  return (
    <section className="connect-realtor">
      <div className="container">
        <div className="section-title text-center">
          <h2>Connect Realtor Profile</h2>
        </div>
        <div className="">
          <div className="d-flex align-items-start gap-3 gap-md-5 my-5">
            <div className="">
              <p className="works-card-title">
                Find Realtor Profile
              </p>
              <p className="realtor-desc">
                Go to the realtor website <a target="_blank" href="https://www.realtor.com/realestateagents/" rel="noreferrer">Find Agent</a>  page on the Realtor website and search for your Realtor profile.
              </p>
              <div className="realtor-img">
                <img
                  src={realtor1}
                  alt="realtor1"
                />
              </div>
              <p className="realtor-desc">
                After obtaining the real estate agent's profile, click on their name to view their profile page on the Realtor website.
              </p>
              <div className="realtor-img">
                <img
                  src={realtor2}
                  alt="realtor2"
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-start gap-3 gap-md-5 my-5">
            <div className="">
              <p className="works-card-title">
                Get Realtor Profile ID
              </p>
              <p className="realtor-desc">
                If you view the realtor profile on the Realtor website, you will see a profile page like this:
              </p>
              <div className="realtor-img">
                <img
                  src={realtor3}
                  alt="realtor3"
                />
              </div>
              <p className="realtor-desc">
                In the URL of the profile page, you can find the profile ID like this "5680ba987e54f701001f2c6f". Please copy the profile ID from the URL.
              </p>
              <div className="realtor-img">
                <img
                  src={realtor4}
                  alt="realtor4"
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-start gap-3 gap-md-5 my-5">
            <div className="">
              <p className="works-card-title">
                Connect Realtor Profile
              </p>
              <p className="realtor-desc">
                Now you can connect your Realtor profile using the id that you have taken from your Realtor profile.
              </p>
              <div className="realtor-img">
                <img
                  src={realtor5}
                  alt="realtor5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectRealtor;