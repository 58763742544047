import React from "react";

const StepedLabel = ({ step, label }) => {
  return (
    <h3 className="step-wrapper d-flex align-items-center">
      <span>
        <span className="count align-items-center justify-content-center">
          {step}
        </span>
      </span>
      <span className="">{label}</span>
    </h3>
  );
};

export default StepedLabel;
