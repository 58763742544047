import React from "react";
import SuperAdminDashboard from "./SuperAdmin";
import PageHeader from "../../component/PageHeader";
import { getLogedInUser } from "../../Utility/utility";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { Navigate } from "react-router-dom";

function UserDashboard() {
  const logedInUser = getLogedInUser();

  return (
    <>
      <PageHeader />
      {logedInUser ? (
        <SuperAdminDashboard userType={logedInUser.userType} />
      ) : (
        <Navigate to={ROUTESCONSTANTS.home} replace={true} />
      )}
    </>
  );
}

export default UserDashboard;
