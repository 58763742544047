import React from 'react'

const TermsCondition = () => {
    return (
        <div className='termsCondition'>
            <div className="container">
                <div class="mb-4">
                    <div class="cardHeader">
                        <h3> Terms and Condition</h3>
                        <div className="line mb-4" />
                    </div>

                    <div class="card-body">
                        <p className="date">Last updated: May 25, 2023</p>
                        <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate provident in consequatur!
                            Ipsum veritatis delectus magni amet? Maxime soluta possimus iure quaerat dolor doloremque sint,
                            vitae totam assumenda quasi dignissimos quos sed rerum hic aliquam? Ratione inventore accusantium
                            molestias, delectus porro facilis quo mollitia quod asperiores hic. Quis atque illum architecto
                            enim nemo dolor tenetur perspiciatis blanditiis quam, quidem in veniam facere ipsum. Optio
                            deserunt amet quaerat nemo voluptatem nesciunt aliquid saepe vitae blanditiis minus excepturi
                            et a est deleniti voluptatibus, <br /><br />

                            <h6>1. OUR SERVICES</h6>
                            The information provided when using the Services is not intended for distribution
                            to or use by any person or entity in any jurisdiction or country where such
                            distribution or use would be contrary to law or regulation or which would subject
                            us to any registration requirement within such jurisdiction or country. Accordingly,
                            those persons who choose to access the Services from other locations do so on their
                            own initiative and are solely responsible for compliance with local laws, if and to
                            the extent local laws are applicable.<br /><br />

                            <h6>2. INTELLECTUAL PROPERTY RIGHTS</h6>
                            We are the owner or the licensee of all intellectual property rights in our Services,
                            including all source code, databases, functionality, software, website designs, audio,
                            video, text, photographs, and graphics in the Services (collectively, the "Content"),
                            as well as the trademarks, service marks, and logos contained therein (the "Marks").
                            Our Content and Marks are protected by copyright and trademark laws (and various other
                            intellectual property rights and unfair competition laws) and treaties in the United
                            States and around the world.
                            The Content and Marks are provided in or through the Services "AS IS" for your
                            personal, non-commercial use or internal. <br /><br />

                            <h6>3. USER REGISTRATION</h6>
                            You may be required to register to use the Services. You agree to keep your password
                            confidential and will be responsible for all use of your account and password.
                            We reserve the right to remove, reclaim, or change a username you select if we
                            determine, in our sole discretion, that such username is inappropriate, obscene,
                            or otherwise objectionable.<br /><br />

                            <h6>4. FREE TRIAL</h6>
                            We offer a __________-day free trial to new users who register with the Services.
                            The account will be charged according to the user's chosen subscription at the end
                            of the free trial.<br /><br />

                            <h6>5. CANCELLATION</h6>
                            All purchases are non-refundable. You can cancel your subscription at any time by
                            logging into your account. Your cancellation will take effect at the end of the
                            current paid term.
                            If you are unsatisfied with our Services, please email us at __________.<br /><br />

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsCondition;
