import React from "react";
import RoomCard from "./RoomCard";

const SavedRoomList = ({ list }) => {
  if (!list.length) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <h3>No Room Found</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      {list.map((room) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={room?._id}>
          <RoomCard
            imgUrl={room?.inputImage}
            prompt={room?.prompt}
            id={room?._id}
          />
        </div>
      ))}
    </div>
  );
};

export default SavedRoomList;
