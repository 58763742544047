import React from "react";
import { Card, Container, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../Utility/utility";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import PaginationComponent from "../Pagination/Pagination";

const SubscriptionHistoryData = ({
  subscriptions,
  dataLoader,
  handlePagination,
  totalHistory,
  currentPage
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Container>
        <Card className="shadow border-0 rounded p-4">
          <div
            className="d-flex p-3 border-bottom justify-content-between
                     align-items-center"
          >
            <h2 className="">Subscription History</h2>
          </div>

          {
            dataLoader ? (
              <div className="d-flex w-100 justify-content-center align-items-center h-100" >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (subscriptions && subscriptions.length > 0 ?
              <Table responsive>
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    <th>SubTotal</th>
                    <th>Total</th>
                    <th>Activated At</th>
                    <th>ExpiresAt</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    subscriptions.map((subscription) => (
                      <tr key={subscription._id}>
                        <td>
                          {subscription?.plan[0]?.planName}{" "}
                        </td>
                        <td>${subscription?.amountSubtotal / 100}</td>
                        <td>${subscription?.amountTotal / 100} </td>
                        <td>{convertDate(subscription?.activatedAt)}</td>
                        <td>{convertDate(subscription?.expiresAt)}</td>
                        <td>
                          <span
                            onClick={() =>
                              navigate(
                                `${ROUTESCONSTANTS.subscriptionHistory}/${subscription?._id}`
                              )}
                            className="viewHistory"
                          >
                            <i className="bi bi-eye"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              :
              (
                <Card>
                  <Card.Body>
                    <Card.Title className="text-danger text-center">
                      No Subscription History available
                    </Card.Title>
                  </Card.Body>
                </Card>
              )
            )}
          <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalHistory}
              itemsPerPage={10}
              currentPage={currentPage}
              onPageChange={handlePagination}
            />
          </div>
        </Card>
      </Container>
    </div >
  );
};

export default SubscriptionHistoryData;
