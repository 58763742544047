// @flow strict

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { MdCheckCircle } from 'react-icons/md';
import { Link } from 'react-router-dom';

function HomeLevelup() {
  return (
    <section className="level-up-section">
      <div className="container">
        <div className="section-title mb-5 text-center">
          <h2>Ready to level-up?</h2>
          <p className="text-center mx-auto sub-title">
            Let's build 20x faster, engage with your customers, & never struggle with the blank content again.
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <Link to="/content-prompt">
            <Button className='btn btn-base rounded-3'>Get Started for Free</Button>
          </Link>
        </div>
        <div className="d-flex justify-content-center align-content-center gap-5 mt-5">
          <p className="d-flex gap-2 justify-content-center align-content-center">
            <MdCheckCircle className="level-icon" size={24} />
            <span className="fs-6">No credit card required</span>
          </p>
          <p className="d-flex gap-2 justify-content-center align-content-center">
            <MdCheckCircle className="level-icon" size={24} />
            <span className="fs-6">Generate new dream room.</span>
          </p>
          <p className="d-flex gap-2 justify-content-center align-content-center">
            <MdCheckCircle className="level-icon" size={24} />
            <span className="fs-6">10+ Content Templates.</span>
          </p>
        </div>
        {/* <p className="text-center mt-4">No credit card required</p> */}
      </div>
    </section>
  );
};

export default HomeLevelup;