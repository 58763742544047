import React from "react";
import AssistantPages from "../../component/AssistantsPage";

const AllAssistants = () => {
  return (
    <>
      <div>
        <AssistantPages />
      </div>
    </>
  );
};

export default AllAssistants;
