// @flow strict

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import SavedContentUI from "../../component/SavedContent/SavedContentUI";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service";

function SavedContent() {
  const [totalContent, setTotalContent] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [savedContents, setSavedContents] = React.useState([]);
  const navigate = useNavigate();

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const fetchSavedContent = async () => {
      try {
        // setDataLoader(true);
        const response = await axiosSecure.get(
          `/contents?page=${currentPage}&limit=10`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        setSavedContents(response?.data?.data);
        setCurrentPage(Number(response?.data.paginationData?.currentPage));
        setTotalContent(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    fetchSavedContent();

    return () => controller && controller.abort();
  }, [currentPage, navigate]);

  return (
    <>
      <PageHeader />
      <SavedContentUI
        savedContents={savedContents}
        totalContent={totalContent}
        handlePagination={handlePagination}
        currentPage={currentPage}
      />
    </>
  );
}

export default SavedContent;
