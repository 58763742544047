import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import { gptModels } from "../../constants/GPTModel";

function UpdateAssistantModal({
  show,
  handleClose,
  assistant,
  setAllAssistant,
}) {
  const [showFileLimitMessage, setShowFileLimitMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assistantData, setAssistantData] = useState({
    name: assistant?.name,
    instructions: assistant?.instructions,
    tools: assistant?.tools,
    model: assistant?.model,
    files: assistant?.files,
    id: assistant?.assistant_id,
    deleteIds: []
  });

  // All Update Functionalities Start Here
  const handleToolChange = (selectedTool) => {
    setAssistantData((prevData) => {
      const toolsArray = prevData?.tools || [];
      const findItem = toolsArray.find((item) => item.type === selectedTool);
      if (findItem) {
        // If tools is already selected, remove it
        return {
          ...prevData,
          tools: toolsArray.filter((tool) => tool.type !== selectedTool),
        };
      } else {
        // If tools is not selected, add it
        return {
          ...prevData,
          tools: [...toolsArray, { type: selectedTool }],
        };
      }
    });
  };

  // handle file changes;
  const handleFileChange = (e) => {
    const files = e.target.files;

    if (!assistantData || !assistantData.files) {
      return;
    }

    const totalFileSize = assistantData.files.reduce((total, file) =>
      file?.size ? total + file?.size : total, 0
    );

    if (
      files.length + assistantData.files.length > 5 ||
      totalFileSize > 50 * 1024 * 1024
    ) {
      setShowFileLimitMessage(true);
    } else {
      setShowFileLimitMessage(false);
      setAssistantData((prevData) => ({
        ...prevData,
        files: [...prevData.files, ...files],
      }));
      // e.target.value = null; // Clear the file input field
    }
  };

  // Remove file from files array
  const removeFile = (index) => {
    setAssistantData((prevData) => {
      const updatedFiles = [...prevData.files];
      updatedFiles.splice(index, 1);
      const totalFileSize = updatedFiles.reduce(
        (total, file) => total + file?.size,
        0
      );
      if (updatedFiles.length <= 5 && totalFileSize <= 50 * 1024 * 1024) {
        setShowFileLimitMessage(false);
      }
      return { ...prevData, files: updatedFiles };
    });
  };

  // Handle Create Assistant
  const handleUpdateAssistant = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", assistantData.name);
    formData.append("instructions", assistantData.instructions);
    formData.append("model", assistantData.model);
    formData.append("deleteIds", JSON.stringify(assistantData.deleteIds));

    if (assistantData.tools) {
      formData.append("tools", JSON.stringify(assistantData.tools));
    }

    (assistantData.files || []).forEach((file) => {
      if (file && !file.id) {
        formData.append("files", file);
      }
    });

    try {
      const response = await axiosSecure.patch(`/assistants/update/${assistantData?.id}`,
        formData, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      toast.success(response?.data?.message);

      if (response.data?.assistant) {
        setAllAssistant((prevData) => {
          const temp = JSON.parse(JSON.stringify(prevData));
          return temp.map((item, i) => {
            if (item.assistant_id === response.data?.assistant?.assistant_id) {
              return response.data?.assistant;
            }
            return item;
          });
        });
      };

      handleClose();
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const codeInterpreterChecked = useMemo(
    () => assistantData?.tools.some((tool) => tool.type === "code_interpreter"),
    [assistantData.tools]
  );

  const retrievalChecked = useMemo(
    () => assistantData?.tools.some((tool) => tool.type === "retrieval"),
    [assistantData?.tools]
  );

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>Update Assistant</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6 className="mb-0">Assistant Name:</h6>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                value={assistantData?.name}
                onChange={(e) =>
                  setAssistantData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }))
                }
                placeholder="Assistant Name"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                <h6 className="mb-0">instructions:</h6>
              </Form.Label>
              <Form.Control
                value={assistantData?.instructions}
                onChange={(e) =>
                  setAssistantData((prevData) => ({
                    ...prevData,
                    instructions: e.target.value,
                  }))
                }
                as="textarea"
                placeholder="Enter instructions"
                rows={2}
              />
            </Form.Group>
            <div>
              <h6 className="mb-0">Tools</h6>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Code interpreter"
                      value="code_interpreter"
                      checked={codeInterpreterChecked}
                      onChange={(e) => handleToolChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Retrieval"
                      value="retrieval"
                      checked={retrievalChecked}
                      onChange={(e) => handleToolChange(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div>
              <h6>Model:</h6>
              <Form.Select
                value={assistantData?.model || "default"}
                onChange={(e) =>
                  setAssistantData((prevData) => ({
                    ...prevData,
                    model: e.target.value === "default" ? "" : e.target.value,
                  }))
                }
                aria-label="Default select example"
              >
                <option>Select Model</option>
                {gptModels.map((model) => (
                  <option key={model.id} value={model.value}>
                    {model.label}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>
                  <h6 className="mb-0 mt-3">Upload File:</h6>
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept=".txt,.pdf,.docs"
                />
                <div className="text-muted mt-2">
                  {assistantData?.files?.length > 0 && (
                    <ul>
                      {assistantData?.files.map((file, index) => (
                        file &&
                        <li key={index}>
                          {file?.name}
                          <span
                            onClick={() => {
                              if (file.id) {
                                setAssistantData((prevData) => ({
                                  ...prevData,
                                  deleteIds: [...prevData.deleteIds, file.id],
                                }))
                              }
                              removeFile(index)
                            }}
                            style={{ cursor: "pointer", color: "red", marginLeft: "8px" }}
                          >
                            Remove
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                  {showFileLimitMessage && (
                    <p style={{ color: "red" }}>
                      Max 5 files at a time with a maximum limit of 50 MB size.
                    </p>
                  )}
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="rounded-1" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="rounded-1"
            variant="primary"
            disabled={loading}
            onClick={handleUpdateAssistant}>
            {loading ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateAssistantModal;
