export const numbers = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
]

export const propertyTypes = [
  {
    id: 1,
    label: "single-family homes",
    value: "Single-Family-Homes"
  },
  {
    id: 2,
    label: "condos",
    value: "Condos"
  },
  {
    id: 3,
    label: "townhouses",
    value: "Townhouses"
  }
];


export const states = [
  { label: "Alabama", value: "alabama" },
  { label: "Alaska", value: "alaska" },
  { label: "Arizona", value: "arizona" },
  { label: "Arkansas", value: "arkansas" },
  { label: "California", value: "california" },
  { label: "Colorado", value: "colorado" },
  { label: "Connecticut", value: "connecticut" },
  { label: "Delaware", value: "delaware" },
  { label: "Florida", value: "florida" },
  { label: "Georgia", value: "georgia" },
  { label: "Hawaii", value: "hawaii" },
  { label: "Idaho", value: "idaho" },
  { label: "Illinois", value: "illinois" },
  { label: "Indiana", value: "indiana" },
  { label: "Iowa", value: "iowa" },
  { label: "Kansas", value: "kansas" },
  { label: "Kentucky", value: "kentucky" },
  { label: "Louisiana", value: "louisiana" },
  { label: "Maine", value: "maine" },
  { label: "Maryland", value: "maryland" },
  { label: "Massachusetts", value: "massachusetts" },
  { label: "Michigan", value: "michigan" },
  { label: "Minnesota", value: "minnesota" },
  { label: "Mississippi", value: "mississippi" },
  { label: "Missouri", value: "missouri" },
  { label: "Montana", value: "montana" },
  { label: "Nebraska", value: "nebraska" },
  { label: "Nevada", value: "nevada" },
  { label: "New Hampshire", value: "new_hampshire" },
  { label: "New Jersey", value: "new_jersey" },
  { label: "New Mexico", value: "new_mexico" },
  { label: "New York", value: "new_york" },
  { label: "North Carolina", value: "north_carolina" },
  { label: "North Dakota", value: "north_dakota" },
  { label: "Ohio", value: "ohio" },
  { label: "Oklahoma", value: "oklahoma" },
  { label: "Oregon", value: "oregon" },
  { label: "Pennsylvania", value: "pennsylvania" },
  { label: "Rhode Island", value: "rhode_island" },
  { label: "South Carolina", value: "south_carolina" },
  { label: "South Dakota", value: "south_dakota" },
  { label: "Tennessee", value: "tennessee" },
  { label: "Texas", value: "texas" },
  { label: "Utah", value: "utah" },
  { label: "Vermont", value: "vermont" },
  { label: "Virginia", value: "virginia" },
  { label: "Washington", value: "washington" },
  { label: "West Virginia", value: "west_virginia" },
  { label: "Wisconsin", value: "wisconsin" },
  { label: "Wyoming", value: "wyoming" },
  { label: "District of Columbia", value: "district_of_columbia" },
  { label: "Puerto Rico", value: "puerto_rico" }
];

export const proccessStage = [
  { id: 1, label: "Initial Inquiry", value: "initial_inquiry" },
  { id: 2, label: "Property Viewing", value: "property_viewing" },
  { id: 3, label: "Negotiation", value: "negotiation" }
]


export const leadSource = [
  {
    id: 1,
    label: "Referral",
    value: "Referral",
  },
  {
    id: 2,
    label: "Online Inquiry",
    value: "Online_Inquiry",
  },
  {
    id: 3,
    label: "Open House Visit",
    value: "Open_House_Visit",
  },
];

export const interestOptions = [
  {
    id: 1,
    label: "Outdoor Activities",
    value: "outdoor_activities",
  },
  {
    id: 2,
    label: "Home Design",
    value: "home_design",
  }
]

export const styleTonsData = [
  { id: 1, label: "Friendly", value: "friendly" },
  { id: 2, label: "Luxury", value: "luxury" },
  { id: 3, label: "Relaxed", value: "relaxed" },
  { id: 4, label: "Professional", value: "professional" },
  { id: 5, label: "Bold", value: "bold" },
  { id: 6, label: "Adventurous", value: "adventurous" },
  { id: 7, label: "Persuasive", value: "persuasive" },
  { id: 8, label: "Empathetic", value: "empathetic" }
];

export const newsLetterSchedule = [
  { id: 0, label: "Daily", value: "daily" },
  { id: 1, label: "Weekly", value: "weekly" },
  { id: 2, label: "Monthly", value: "monthly" },
  { id: 3, label: "Quarterly", value: "quarterly" },
];

export const emailRecipients = [
  { id: 0, label: "Buyers", value: "buyers" },
  { id: 1, label: "Sellers", value: "sellers" },
  { id: 2, label: "Investors", value: "investors" },
];

// English US, English UK
export const languages = [
  {
    id: "01",
    label: "English US",
    value: "english_us",
  },
  {
    id: "02",
    label: "English UK",
    value: "english_uk",
  }
]