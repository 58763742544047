// @flow strict
import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { convertDate } from "../../Utility/utility";

function SavedContentCard({ content }) {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const contents = content?.content || [];
  const contentText = contents[0]?.content;

  return (
    <>
      <div className="saved-content-item">
        <div dangerouslySetInnerHTML={{ __html: contentText }}></div>
        <div className="d-flex justify-content-between align-items-center">
          <span>
            <strong>Created Date: </strong>
            {convertDate(content.createdAt)}
          </span>
          <Button
            type="button"
            variant="outline"
            onClick={handleShow}
            className="btn p-1 px-3"
          >
            <BsFillEyeFill />
          </Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <div className="saved-content-modal p-4 rounded">
          <h4 className="border-bottom pb-3 mb-3">Saved Generated Content</h4>
          {contents.length > 0 &&
            contents.map((week, i) => (
              <div key={i} className="mb-3">
                <h5>Post:</h5>
                <div dangerouslySetInnerHTML={{ __html: week.content }}></div>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
}

export default SavedContentCard;
