// @flow strict
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import SelectBox from "../../../Common/SelectBox";
import { unlimitedCreditEmails } from "../../../config/unlimitedCreditEmails";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { emailRecipients } from "../../../Utility/content-options-data";
import { isExpired } from "../../../Utility/utility";
import SingleMail from "./SingleMail";

function GenerateEmailUI({
  setRecipient,
  recipient,
  handleSubmit,
  outputData,
  loading,
  remaining,
  logedInUser,
}) {
  return (
    <div className="content-generator-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            {unlimitedCreditEmails.includes(logedInUser.email) ? (
              <h6 className="text-center million-title mb-3">
                You have an unlimited amount of credit available.
              </h6>
            ) : (
              isExpired() ?
                <h6 className="text-center mb-3">
                  <span className="text-danger">You subscription plan has been expired.</span>
                  <span>{" "}Buy more
                    credits{" "}</span>
                  <Link
                    to={ROUTESCONSTANTS.buyCredit}
                    className="text-blue-theme million-title"
                  >
                    here
                  </Link>
                  .
                </h6>
                :
                <h6 className="text-center million-title mb-3">
                  You have {remaining?.emailCampaignRemaining} credit left. Buy
                  more credits{" "}
                  <Link
                    to={ROUTESCONSTANTS.buyCredit}
                    className="text-blue-theme"
                  >
                    here
                  </Link>
                  .
                </h6>
            )}
            <div className="content-generator-inner card">
              <form onSubmit={handleSubmit} className="">
                <div className="text-center mb-4">
                  <h2>Email Generator</h2>
                </div>
                <div className="row">
                  <div className="col-12 mb-4">
                    <SelectBox
                      defaultValue={recipient}
                      onChange={(data) => setRecipient(data.value)}
                      options={emailRecipients}
                      className="basic-single"
                      classNamePrefix="select"
                      label="Select Recipient:"
                    />
                  </div>

                  <div className="col-12 mb-4">
                    {loading ? (
                      <Button
                        type="submit"
                        className="btn btn-base w-100 content-generation-btn"
                        disabled={loading}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ps-1">
                          Just a moment, we're generating your mails...
                        </span>
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn btn-base w-100 content-generation-btn"
                        disabled={recipient === ""}
                      >
                        Generate Emails
                      </Button>
                    )}
                  </div>
                </div>
              </form>
              {outputData?.length > 0 && (
                outputData?.map((content, i) => (
                  <SingleMail key={i} content={content} index={i} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateEmailUI;
