import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { axiosOpen } from "../../api/axios";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosOpen.put("/auth/forgot-password", {
        email,
      });

      toast.success(response.data.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  // Validate Email
  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleEmailBlur = () => {
    validateEmail();
  };
  return (
    <>
      <PageHeader />
      <div className="signin-page-area pd-top-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <form onSubmit={handleSubmit} className="signin-inner card mt-5">
                <div className="text-center mb-4">
                  <h2>Email Verification</h2>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <label className="single-input-inner style-bg-border">
                      <input
                        type="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleEmailBlur}
                        required
                      />
                    </label>
                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {" "}
                      {emailError && <span>{emailError}</span>}{" "}
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <button className="btn btn-base sign-form-btn w-100">
                      Send verification link
                    </button>
                  </div>
                  <div
                    className="col-12"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="col-12 link-wrapper">
                      <Link to={ROUTESCONSTANTS.signin}>Signin</Link>
                      <Link to={ROUTESCONSTANTS.signup}>Signup</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
