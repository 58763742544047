// @flow strict

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LimitWarningModal from "../../../Common/Modal/LimitWarningModal";
import useRemaining from "../../../Hooks/useRemaining";
import { allCities } from "../../../Utility/cities";
import { getLogedInUser } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import GenerateContentUI from "../../../component/AIContentGenerate/GenerateContent/GenerateContentUI";
import PageHeader from "../../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";
import { unlimitedCreditEmails } from "../../../config/unlimitedCreditEmails";

function GenerateContent() {
  const logedInUser = getLogedInUser();
  const [outputData, setOutputData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = React.useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    property: "",
    state: "",
    city: "",
    street: "",
    targetBuyer: "",
    styleTons: "",
    additionalInfo: "",
    bed: "",
    bath: "",
    SqFt: "",
    platform: "",
  });
  const { remaining, setRemaining } = useRemaining();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeStatesData = (value) => {
    onChangeInputData("state", value);
    const foundCities = allCities[value];
    const inputCities = foundCities.map((city) => {
      return {
        value: city.split(" ").join("_").toLowerCase(),
        label: city,
      };
    });
    setCities(inputCities);
  };

  const onChangeInputData = (name, value) => {
    setInputData((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      temp[name] = value;
      return temp;
    });
  };

  const handleStateError = (name, value) => {
    if (!inputData[name]) {
      setErrors((prev) => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp[name] = true;
        return temp;
      });
    } else {
      setErrors((prev) => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp[name] = false;
        return temp;
      });
    }
  };

  const handleCheckErrorOnSubmit = (inputData) => {
    let hasError = false;

    // Check each field for empty values
    for (const fieldName in inputData) {
      if (
        fieldName !== "additionalInfo" &&
        inputData[fieldName].trim() === ""
      ) {
        setErrors((prev) => {
          const temp = JSON.parse(JSON.stringify(prev));
          temp[fieldName] = true;
          return temp;
        });
        hasError = true;
      }
    }

    return hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // unlimitedCreditEmails
    if (
      !unlimitedCreditEmails.includes(logedInUser.email) &&
      remaining &&
      remaining?.socialMediaContentRemaining === 0
    ) {
      setShowModal(true);
      return;
    }

    const isError = handleCheckErrorOnSubmit(inputData);

    if (isError || !logedInUser) {
      return;
    }

    setLoading(true);

    const payload = JSON.stringify({
      property: inputData.property,
      state: inputData?.state,
      city: inputData.city,
      street: inputData.street,
      targetBuyer: inputData.targetBuyer,
      styleTons: inputData.styleTons,
      additionalInfo: inputData.additionalInfo,
      language: "english_us",
      bed: inputData.bed,
      bath: inputData.bath,
      SqFt: inputData.SqFt,
      platform: inputData.platform,
      phoneNumber: logedInUser?.phoneNumber,
      email: logedInUser?.email,
      website: logedInUser?.website,
      firstName: logedInUser?.firstName,
      lastName: logedInUser?.lastName,
      templateIdentifier: "property-description",
    });

    try {
      const response = await axiosSecure.post("/call-open-ai", payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      const answer = response?.data?.data;

      setOutputData([{ week: 1, content: answer }]);
      setLoading(false);
      setRemaining((prev) => ({
        ...prev,
        socialMediaContentRemaining: prev?.socialMediaContentRemaining - 1,
      }));
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
      setLoading(false);
    }
  };

  const handleSaveContent = async () => {
    const payload = JSON.stringify({
      content: outputData,
      userInput: {
        type: "Apartment",
        state: inputData.state,
        city: inputData.city,
        street: inputData.street,
        price_range: `$${inputData.priceMin} - $${inputData.priceMax}`,
      },
    });

    try {
      const response = await axiosSecure.post("/content/add", payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      // console.log(response?.data);
      toast.success(response?.data?.message);
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  // console.log(remaining);

  return (
    <>
      <PageHeader />
      {!logedInUser ? (
        <section className="content-generator-area">
          <div className="container">
            <div className=" row justify-content-center ">
              <div className="col-xl-8 col-lg-9 content-g-signin-text text-center">
                <h6 className="million-title">
                  Over 1 million users have used RealEstate AI so far.
                </h6>
                <h1 className="fw-bold mb-3">
                  Generate social media post for a new property listing without
                  any thinking!
                </h1>
                <p>
                  Enjoy the benefits of a free account and unlock the
                  opportunity to transform your space. Sign up now and receive 3
                  credits to create stunning room images and captivating social
                  media posts.
                </p>
                <p className="mb-3 fw-medium">
                  If you’re already a member, simply sign in here.
                </p>
                <Link
                  className="btn btn-base d-inline-flex align-items-center"
                  to={ROUTESCONSTANTS.signin}
                >
                  <span className="btn-text">Sign in</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <GenerateContentUI
          inputData={inputData}
          onChangeInputData={onChangeInputData}
          handleSubmit={handleSubmit}
          errors={errors}
          handleStateError={handleStateError}
          outputData={outputData}
          loading={loading}
          logedInUser={logedInUser}
          handleSaveContent={handleSaveContent}
          setInputData={setInputData}
          cities={cities}
          onChangeStatesData={onChangeStatesData}
          remaining={remaining}
        />
      )}
      <Modal
        scrollable={true}
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <LimitWarningModal handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
}

export default GenerateContent;
