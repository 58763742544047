import React from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";

import { ToastContainer } from "react-toastify";
import {
  ForgotPassword,
  GenerateRoom,
  HomePage,
  LoginForm,
  RegisterForm,
  ResetPassword,
  SavedRooms,
  TermsCondition,
} from "./Pages";
import AssistantChatBox from "./Pages/AIContentGenerate/AssistantChatBox";
import GenerateBiography from "./Pages/AIContentGenerate/GenerateBiography";
import GenerateBlog from "./Pages/AIContentGenerate/GenerateBlog";
import GenerateContent from "./Pages/AIContentGenerate/GenerateContent";
import GenerateEmail from "./Pages/AIContentGenerate/GenerateEmail";
import GenerateInstaQuotes from "./Pages/AIContentGenerate/GenerateInstaQuotes";
import GenerateNewsLetter from "./Pages/AIContentGenerate/GenerateNewsLetter";
import ImproveContent from "./Pages/AIContentGenerate/ImproveContent";
import LeadGeneration from "./Pages/AIContentGenerate/LeadGeneration";
import MarketingAssistant from "./Pages/AIContentGenerate/MarketingAssistant";
import SalesAssistant from "./Pages/AIContentGenerate/SlaesAssistant";
import StepByStepGuide from "./Pages/AIContentGenerate/StepByStepGuide";
import AllAssistants from "./Pages/Assistants";
import ChangePassword from "./Pages/ChangePassword";
import ContentPrompt from "./Pages/ContentPrompt";
import DashboardInfo from "./Pages/Dashboard/Dashboard";
import Dashboard from "./Pages/Dashboard/index";
import ConnectRealtor from "./Pages/Docs/ConnectRealtor";
import PaymentSuccess from "./Pages/PaymentSuccess";
import Pricing from "./Pages/Pricing";
import SavedContent from "./Pages/SavedContent";
import SavedRoomDetails from "./Pages/SavedRooms/SavedRoomDetails";
import SubscriptionHistory from "./Pages/SubscriptionHistory";
import SubscriptionHistoryView from "./Pages/SubscriptionHistory/View";
import UpdateProfile from "./Pages/UpdateProfile";
import UserList from "./Pages/UserList/UserList";
import UserPlan from "./Pages/UsersPlan";
import AddUserPlan from "./Pages/UsersPlan/Add";
import EditUserPlan from "./Pages/UsersPlan/Edit";
import ViewUserPlan from "./Pages/UsersPlan/View";
import ResetPass from "./Pages/resetPassToken";
import PromptsAddForm from "./component/Prompts/Add";
import EditPrompt from "./component/Prompts/Edit";
import AllPrompt from "./component/Prompts/allPrompt";
import SelectedProfileInfo from "./component/UserList/SelectedUserProfile";
import UserProfile from "./component/UserProfile/UserProfile";
import GeneralLayout from "./component/layout/GeneralLayout";
import { ROUTESCONSTANTS } from "./constants/Routes";

function App() {
  return (
    <GeneralLayout>
      <ToastContainer autoClose={3000} />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path={ROUTESCONSTANTS.generateRoom} element={<GenerateRoom />} />
        <Route path={ROUTESCONSTANTS.connectRealtor} element={<ConnectRealtor />} />
        <Route
          path={ROUTESCONSTANTS.generateContent}
          element={<GenerateContent />}
        />
        <Route
          path={ROUTESCONSTANTS.generateBiography}
          element={<GenerateBiography />}
        />
        <Route path={ROUTESCONSTANTS.generateBlog} element={<GenerateBlog />} />
        <Route
          path={ROUTESCONSTANTS.generateInsta}
          element={<GenerateInstaQuotes />}
        />
        <Route
          path={ROUTESCONSTANTS.leedGeneration}
          element={<LeadGeneration />}
        />
        <Route
          path={ROUTESCONSTANTS.improveContent}
          element={<ImproveContent />}
        />
        <Route
          path={ROUTESCONSTANTS.stepBystepGuide}
          element={<StepByStepGuide />}
        />
        <Route
          path={ROUTESCONSTANTS.generateNewsLetter}
          element={<GenerateNewsLetter />}
        />
        <Route
          path={ROUTESCONSTANTS.generateEmail}
          element={<GenerateEmail />}
        />
        <Route
          path={ROUTESCONSTANTS.contentPrompt}
          element={<ContentPrompt />}
        />

        <Route
          path={ROUTESCONSTANTS.marketingAssistant}
          element={<MarketingAssistant />}
        />

        <Route
          path={ROUTESCONSTANTS.assistantId}
          element={<AssistantChatBox />}
        />

        <Route
          path={ROUTESCONSTANTS.salesAssistant}
          element={<SalesAssistant />}
        />

        <Route
          path={ROUTESCONSTANTS.termsCondition}
          element={<TermsCondition />}
        />
        <Route path={ROUTESCONSTANTS.signin} element={<LoginForm />} />
        <Route path={ROUTESCONSTANTS.signup} element={<RegisterForm />} />
        <Route
          path={ROUTESCONSTANTS.forgetPassword}
          element={<ForgotPassword />}
        />
        <Route
          path={ROUTESCONSTANTS.resetPassword}
          element={<ResetPassword />}
        />
        <Route
          path={ROUTESCONSTANTS.verifyResetPassToken}
          element={<ResetPass />}
        />
        <Route path={ROUTESCONSTANTS.buyCredit} element={<Pricing />} />
        <Route
          path={ROUTESCONSTANTS.paymentSuccess}
          element={<PaymentSuccess />}
        />
        <Route path={ROUTESCONSTANTS.dashboard + "/"} element={<Dashboard />}>
          <Route index element={<DashboardInfo />} />
          <Route path={ROUTESCONSTANTS.profile} element={<UserProfile />} />
          <Route
            path={ROUTESCONSTANTS.editProfile}
            element={<UpdateProfile />}
          />
          <Route
            path={ROUTESCONSTANTS.updatePassword}
            element={<ChangePassword />}
          />
          <Route
            path={ROUTESCONSTANTS.subscriptionHistory}
            element={<SubscriptionHistory />}
          />
          <Route
            path={ROUTESCONSTANTS.assistants}
            element={<AllAssistants />}
          />

          <Route
            path={ROUTESCONSTANTS.subscriptionHistoryView}
            element={<SubscriptionHistoryView />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminAddPrompt}
            element={<PromptsAddForm />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminEditPrompt}
            element={<EditPrompt />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminAllPrompts}
            element={<AllPrompt />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminClients}
            element={<UserList />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminClientsView}
            element={<SelectedProfileInfo />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminUserPlan}
            element={<UserPlan />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminAddUserPlan}
            element={<AddUserPlan />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminEditUserPlan}
            element={<EditUserPlan />}
          />
          <Route
            path={ROUTESCONSTANTS.superAdminViewUserPlan}
            element={<ViewUserPlan />}
          />
        </Route>
        <Route path={ROUTESCONSTANTS.savedContent} element={<SavedContent />} />
        <Route path={ROUTESCONSTANTS.savedRoom} element={<SavedRooms />} />
        <Route
          path={ROUTESCONSTANTS.savedRoomWithId}
          element={<SavedRoomDetails />}
        />
        {/* <Route path="/*" element={<RouterGuard />} /> */}
      </Routes>
    </GeneralLayout>
  );
}

// for development
function UnderConstructionInner() {
  return <h1>This page is under development</h1>;
}

export default App;
