import React from "react";
import { Card } from "react-bootstrap";

const UsagePlan = ({ title, data, icon }) => {
  return (
    <Card>
      <Card.Body className="cardBody">
        <div className="content">
          <p className="text-muted">{title}</p>
          <h5 style={{ fontWeight: 500 }}>{data ?? "No Previous Data"}</h5>
        </div>
        <div className="icon">
          {icon}
        </div>
      </Card.Body>
    </Card>
  );
};

export default UsagePlan;
