import React from "react";

import ChatContent from "../../Common/AllChatThreads/ChatContent";

const AssistantChatBoxUI = () => {

  return (
    <div className="content-generator-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-12">
            <div className="">
              <h2 className="text-center">Your Assistant</h2>
              <p className="text-center">
                This is your assistant. If you have any assist-related issues,
                please let me help you.
              </p>
            </div>
            <div className="content-generator-inner card">
              <div className="main__chatbody">
                <div className="col-12">
                  <ChatContent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantChatBoxUI;
