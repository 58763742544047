import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service/service";
import UpdateCreditModal from "./CreditUpdateModal";

function SelectedProfileInfo() {
  let { clientId } = useParams();
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axiosSecure.get(`/admin/client/${clientId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        setProfile(response?.data?.data);
      } catch (err) {
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      }
    };
    fetchClientData();
  }, [clientId, navigate]);

  return (
    <>
      <div className="user-list-view">
        <div className="d-flex justify-content-between align-items-center mb-3 pb-3 border-bottom">
          <h2>Client Details</h2>
          <button
            className="btn btn-white shadow-sm rounded-2 align-items-center "
            onClick={() =>
              navigate(`/dashboard/${ROUTESCONSTANTS.superAdminClients}`)
            }
          >
            <span className="btn-text">Back</span>
          </button>
        </div>
        <div className="user-profile-content">
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">First Name:</p>
            <p className="content-value">{profile.firstName}</p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Last Name:</p>
            <p className="content-value">{profile?.lastName || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Email:</p>
            <p className="content-value">{profile.email}</p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Phone:</p>
            <p className="content-value">{profile?.phoneNumber || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Website:</p>
            <p className="content-value">{profile?.website || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Joined At:</p>
            <p className="content-value">
              {new Date(profile?.createdAt).toDateString()}
            </p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Active Plan:</p>
            <p className="content-value">{profile?.plan?.planName || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-4">
            <p className="content-title">Plan Expiry Date:</p>
            <p className="content-value">
              {new Date(profile?.subscriptionExpiration).toDateString()}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between border-bottom my-4 py-3">
          <h3 className="">Remaining Credits</h3>
          <button
            className="btn btn-white shadow-sm rounded-2 align-items-center "
            onClick={handleShow}
          >
            <span className="btn-text">Update Credit</span>
          </button>
        </div>
        <div className="px-md-4 py-2">
          <div className="row g-3">
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.biographyRemaining}
                </b>
                <p className="mt-2">No. Of Biography</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.blogPostRemaining}
                </b>
                <p className="mt-2">No. of Blog</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.emailCampaignRemaining}
                </b>
                <p className="mt-2">No. of Email</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.improveContentRemaining}
                </b>
                <p className="mt-2">No. of Improve Content</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.instagramQuotesRemaining}
                </b>
                <p className="mt-2">No. of Instagram Quote</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.leadsGenerationRemaining}
                </b>
                <p className="mt-2">No. of Lead Generation</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.newsletterCampaignRemaining}
                </b>
                <p className="mt-2">No. of Newsletter</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">{profile?.remainingCount?.roomRemaining}</b>
                <p className="mt-2">No. of Room Generation</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.socialMediaContentRemaining}
                </b>
                <p className="mt-2">No. of Property Description</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="custom-card">
                <b className="mt-2">
                  {profile?.remainingCount?.stepByStepGuideRemaining}
                </b>
                <p className="mt-2">No. of Step by Step Guide</p>
              </div>
            </div>
          </div>
        </div>
        <h3 className="my-4 py-3 border-bottom">Active Plan Details</h3>
        <div className="d-flex justify-content-start align-items-start px-3 px-md-4">
          <ul className="row">
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Biography: {profile?.plan?.numberOfBiography}</span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Blog: {profile?.plan?.numberOfBlogPost}</span>
            </li>
            <li className="pb-2 text-left  col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Email: {profile?.plan?.numberOfEmailCampaign}</span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Improve Content: {profile?.plan?.numberOfImproveContent}
              </span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Instagram Quotes: {profile?.plan?.numberOfInstagramQuotes}
              </span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Leed Generation: {profile?.plan?.numberOfLeadsGeneration}
              </span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Newsletter: {profile?.plan?.numberOfNewsletterCampaign}
              </span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Room Generation: {profile?.plan?.numberOfRoom}</span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Property Details:{" "}
                {profile?.plan?.numberOfSocialMediaContent}
              </span>
            </li>
            <li className="pb-2 text-left col-12 col-lg-6">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Step By Step Guide:{" "}
                {profile?.plan?.numberOfStepByStepGuide}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <UpdateCreditModal
          handleClose={handleClose}
          handleShow={handleShow}
          show={profile && show}
          profile={profile}
          setProfile={setProfile}
          remainingCount={profile?.remainingCount}
        />
      </Modal>
    </>
  );
}

export default SelectedProfileInfo;
