import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getLogedInUser } from "../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import UserListUI from "../../component/UserList/UserListUI";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service/service";

const UserList = () => {
  const logedInUser = getLogedInUser();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoader, setDataLoader] = useState(false);
  const [clientList, setClientList] = useState(null);
  const [totalClientCount, setTotalClientCount] = useState(0);

  // Modal State
  const navigate = useNavigate();

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  const handleChangeUserActivity = async (uid, activity) => {
    const payload = JSON.stringify({
      isActive: activity,
      uid: uid,
    });

    try {
      const response = await axiosSecure.put(
        "/admin/client/update-status",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setClientList((prevState) => {
        const temp = JSON.parse(JSON.stringify(prevState));
        const index = temp.findIndex((item) => item._id === uid);
        temp[index].isActive = activity;
        return temp;
      });
      toast.success(response?.data?.message);
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  const handleAssistantPermission = async (user_Id, permission) => {
    const payload = {
      permission: permission,
      userId: user_Id,
    };
    try {
      const response = await axiosSecure.put(
        "admin/update-user-permission",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setClientList((prevState) => {
        const temp = JSON.parse(JSON.stringify(prevState));
        const index = temp.findIndex((item) => item._id === user_Id);
        temp[index].assistantPermission = permission;
        return temp;
      });
      toast.success(response?.data?.message);
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchClientData = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(
          `/admin/clients?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setClientList(response?.data?.data);
        setCurrentPage(Number(response?.data.paginationData?.currentPage));
        setTotalClientCount(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      } finally {
        setDataLoader(false);
      }
    };

    if (logedInUser && logedInUser.userType === 1) {
      fetchClientData();
    }

    return () => controller && controller.abort();
  }, [currentPage, limit, navigate]);

  if (dataLoader) {
    return (
      <div className="user-list-container">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return logedInUser && logedInUser.userType === 1 ? (
    <>
      <UserListUI
        clientList={clientList}
        totalClientCount={totalClientCount}
        limit={limit}
        currentPage={currentPage}
        handlePagination={handlePagination}
        handleChangeUserActivity={handleChangeUserActivity}
        handleAssistantPermission={handleAssistantPermission}
      />
    </>
  ) : (
    <h2 className="text-center">Page Not Found</h2>
  );
};

export default UserList;
