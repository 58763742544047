import React, { useEffect, useState } from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";

const PaymentSuccess = () => {
  const logedinUser = JSON.parse(localStorage.getItem('userDetails'))
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  // Access query parameters
  const { session_id, planId } = Object.fromEntries(searchParams);

  useEffect(() => {
    if (planId && session_id) {
      async function getSessionData() {
        try {
          const response = await axiosSecure.get(
            `/stripe-session?sessionId=${session_id}&planId=${planId}`,
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );

          const order = response?.data?.data?.order;
          console.log(order);
          setOrder(order);
          const newUserDetails = JSON.stringify({
            token: logedinUser?.token,
            user: {
              ...logedinUser?.user,
              activePlanId: order?.planId,
              subscriptionExpiration: order?.expiresAt,
              subscriptionId: order?.subscriptionId
            },
          })
          localStorage.setItem('userDetails', newUserDetails);
          navigate("/payment-success", { replace: true });
        } catch (err) {
          console.log(err?.response?.data?.message);
        }
      }
      getSessionData();
    }
  }, [logedinUser, navigate, planId, session_id]);

  return (
    <div className="subscription-success">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="subscription-content text-center">
            <h1 className="title">Thank You!</h1>
            <p><FiCheckCircle className="success-icon" /></p>
            <p className="description">
              Your subscription payment has been successfully processed.
            </p>

            <button
              onClick={() => navigate("/dashboard", { replace: true })}
              className="btn rounded-pill btn-base px-4">
              Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
