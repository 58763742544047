import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const UpdateThreadModal = ({
  allThreadsList,
  selectedThreadId,
  showUpdateModal,
  handleUpdateThreadName,
  handleUpdateModalClosed,
  handleModalSubmit,
  updatedThreadName,
  setUpdatedThreadName,
}) => {
  return (
    <>
      <Modal show={showUpdateModal} onHide={handleUpdateModalClosed}>
        <Modal.Header closeButton>
          <Modal.Title>Update Thread Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <input
            type="text"
            value={
              allThreadsList.find((thread) => thread._id === selectedThreadId)
                ?.name || ""
            }
            onChange={(e) => handleUpdateThreadName(e.target.value)}
          /> */}

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h6 className="mb-0">Assistant Name:</h6>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                // value={
                //   allThreadsList.find(
                //     (thread) => thread._id === selectedThreadId
                //   )?.name || ""
                // }
                // onChange={(e) => handleUpdateThreadName(e.target.value)}
                value={updatedThreadName}
                onChange={(e) => setUpdatedThreadName(e.target.value)}
                placeholder="Enter name"
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleUpdateModalClosed}>
            Cancel
          </Button>

          <Button variant="primary" onClick={handleUpdateThreadName}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateThreadModal;
