import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";

const SubscriptionCancleModal = ({ handleCloseModal }) => {
  const navigate = useNavigate();
  const logedinUser = JSON.parse(localStorage.getItem("userDetails"));
  const [agree, setAgree] = useState(false);

  const cancleSubscriptionHandler = async () => {
    if (!logedinUser?.user?.subscriptionId) {
      return;
    }

    const requestPayload = {
      subscriptionId: logedinUser?.user?.subscriptionId,
    };

    try {
      const response = await axiosSecure.put(
        "/cancel-subscription",
        requestPayload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response?.data?.message);

      const newUserDetails = JSON.stringify({
        token: logedinUser?.token,
        user: {
          ...logedinUser?.user,
          subscriptionId: null,
        },
      });
      localStorage.setItem("userDetails", newUserDetails);
      handleCloseModal();
      navigate("/dashboard", { replace: true });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <div className="cancleSubscription pb-5">
      <div className="header">
        <h5>Terms and Conditions</h5>
      </div>
      <div className="description">
        <p>
          We understand that circumstances may change, and you may need to
          cancel your subscription plan with us. Please review our cancelation
          policy below:
        </p>
        <ul>
          <li>
            No Refunds After Unsubscription: Once you have chosen to unsubscribe
            from your subscription plan, we regret to inform you that no refunds
            will be provided for the remaining duration of your subscription. We
            encourage you to fully utilize the services until the expiration
            date of your plan.
          </li>
          <li>
            Validity until Expiration Date: Even after you have unsubscribed,
            your subscription plan will remain active and valid until the
            original expiration date. You will continue to have access to all
            the benefits and features associated with your subscription during
            this period.
          </li>
          <li>
            Auto-Renewal Disabled: Upon cancelation, your subscription plan will
            no longer be auto-renewed. This means that once the current
            subscription period ends, you will not be charged for any further
            periods, and your access to the subscription services will be
            discontinued.
          </li>
          <li>
            Continued Access: We encourage you to make the most of your
            subscription until its expiration date. You will retain access to
            all the services, content, and features provided as part of your
            subscription during this time.
          </li>
          <li>
            Plan Changes and Downgrades: If you wish to change or downgrade your
            subscription plan, we recommend contacting our customer support
            team. They will assist you in making the necessary modifications
            while ensuring a smooth transition and uninterrupted access to the
            services.
          </li>
        </ul>
        <p>
          Please note that this cancelation policy applies to our standard
          subscription plans. If you have subscribed through a third-party
          platform or have a specialized plan with different terms, please refer
          to the specific cancelation policy provided by that platform or
          contact our customer support for more information. We value your
          understanding of our cancelation policy. If you have any questions or
          require further assistance, please don't hesitate to reach out to our
          customer support team, who will be happy to assist you.
        </p>
      </div>
      {/* <hr /> */}

      <div className="checkbox">
        <Form.Check aria-label="option 1" onChange={() => setAgree(!agree)} />
        <p className="ps-2"> I have read and agree to the terms of service.</p>
      </div>

      <div className="d-flex justify-content-end align-items-center gap-3 btnSection">
        <Button
          variant="secondary"
          className="btn px-4 my-3"
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          className="btn px-4 my-3"
          onClick={() => cancleSubscriptionHandler()}
          disabled={!agree}
        >
          Unsubscribe
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCancleModal;
