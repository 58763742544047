import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FaEllipsisH } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { shortenText } from "../../../Utility/utility";

const AssistantListItem = ({
  assistant,
  i,
  activeAssistant,
  handleAssistantClick,
  handleAssistantDeleteClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <li
        key={i}
        className={`single__thread ${i === activeAssistant ? "active" : ""}`}
        onClick={() => handleAssistantClick(assistant, i)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {shortenText(assistant?.name, 15)}
        {isHovered && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover id="popover-positioned-bottom">
                <Popover.Body className="pophover__body">
                  <div
                    onClick={() => handleAssistantDeleteClick(assistant?._id)}
                    style={{ color: "#BF3131" }}
                    className="pophover__item"
                  >
                    <MdDelete /> <span> Delete Assistant </span>
                  </div>
                  {/* <div className="pophover__item">
                    <MdDriveFileRenameOutline />
                    <span>Rename</span>
                  </div> */}
                </Popover.Body>
              </Popover>
            }
          >
            <span className="ellipsis__btn" onClick={toggleDropdown}>
              <FaEllipsisH />
            </span>
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};

export default AssistantListItem;
