// @flow strict

import * as React from "react";
import { BsChatSquareText, BsLightbulbFill } from "react-icons/bs";
import { FaInstagram, FaRegNewspaper, FaUserCheck } from "react-icons/fa";
import { FcAssistant } from "react-icons/fc";
import { FiMail } from "react-icons/fi";
import { GoNote } from "react-icons/go";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { RiGuideFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ROUTESCONSTANTS } from "../../constants/Routes";

function ContentPromptUI() {
  return (
    <div className="content-prompt-page">
      <div className="container">
        <div className="">
          <h2 className="text-center">Generate Your Content </h2>
          <p className="text-center">
            Get started by selecting a template from the list below.
          </p>
        </div>
        <div className="row mt-3 mt-md-5 g-4">
          {/* <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.marketingAssistant}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <MdAssistant className="prompt-icon" />
                </div>
                <p className="prompt-title">Marketing Assistant</p>
                <p className="prompt-subtitle">
                  This is your Marketing assistant. If you have any
                  marketing-related issues, please let me help you.
                </p>
              </div>
            </Link>
          </div> */}

          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.assistants}>
              <div className="content-prompt-card">
                <div className="text-center">
                  {/* <SiSalesforce className="prompt-icon" /> */}
                  <FcAssistant className="prompt-icon" />
                </div>
                <p className="prompt-title">Assistants</p>
                <p className="prompt-subtitle">
                  This is your assistant. If you have any issues related to
                  information, please let me help you.
                </p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.generateBiography}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <FaUserCheck className="prompt-icon" />
                </div>
                <p className="prompt-title">Generate Biography</p>
                <p className="prompt-subtitle">
                  Generate your biography using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.generateContent}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <MdOutlineRealEstateAgent className="prompt-icon" />
                </div>
                <p className="prompt-title">Property Description</p>
                <p className="prompt-subtitle">
                  Generate property description using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.generateBlog}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <GoNote className="prompt-icon" />
                </div>
                <p className="prompt-title">Real Estate Blog Post</p>
                <p className="prompt-subtitle">
                  Generate real estate blog post using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.generateNewsLetter}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <FaRegNewspaper className="prompt-icon" />
                </div>
                <p className="prompt-title">Newsletter Campaign</p>
                <p className="prompt-subtitle">
                  Generate newsletter for your business using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.generateEmail}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <FiMail className="prompt-icon" />
                </div>
                <p className="prompt-title">12-Touches Email Campaign</p>
                <p className="prompt-subtitle">
                  Generate Mail for your business marketing using Real Estate
                  AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.generateInsta}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <FaInstagram className="prompt-icon" />
                </div>
                <p className="prompt-title">Realtor Insta Quotes</p>
                <p className="prompt-subtitle">
                  Generate Instagram quotes for your business using Real Estate
                  AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.leedGeneration}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <BsLightbulbFill className="prompt-icon" />
                </div>
                <p className="prompt-title">Ways to Generate Leads</p>
                <p className="prompt-subtitle">
                  Get inspired ideas now using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.improveContent}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <BsChatSquareText className="prompt-icon" />
                </div>
                <p className="prompt-title">Improve Content</p>
                <p className="prompt-subtitle">
                  Improve your content using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-md-6  col-md-offset-4">
            <Link to={ROUTESCONSTANTS.stepBystepGuide}>
              <div className="content-prompt-card">
                <div className="text-center">
                  <RiGuideFill className="prompt-icon" />
                </div>
                <p className="prompt-title">Step by Step Guide</p>
                <p className="prompt-subtitle">
                  Get step by step guide for your business using Real Estate AI.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentPromptUI;
