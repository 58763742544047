// @flow strict

import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import SubscriptionHistoryData from '../../component/SubscriptionHistory/SubscriptionHistory';

function SubscriptionHistory() {
  const [totalHistory, setTotalHistory] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    const controller = new AbortController();

    const fetchSubscriptionsHistory = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(
          `/orders?page=${currentPage}&limit=10`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        // console.log(response?.data)

        setSubscriptions(response?.data?.data);
        setTotalHistory(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err.response.data?.message);
      } finally {
        setDataLoader(false)
      }
    };
    fetchSubscriptionsHistory();

    return () => controller && controller.abort();
  }, [currentPage]);

  return (
    <div>
      <SubscriptionHistoryData
        dataLoader={dataLoader}
        subscriptions={subscriptions}
        handlePagination={handlePagination}
        totalHistory={totalHistory}
        currentPage={currentPage}
      />
    </div>
  );
};

export default SubscriptionHistory;