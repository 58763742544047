import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service";

function SideNav({ gotoView, userType }) {
  const loacation = useLocation();
  const { width } = useWindowSize();

  const navigate = useNavigate();
  const handleAdminSideNavClick = (path) => {
    navigate(path);
    if (width < 992) {
      gotoView();
    }
  };

  return (
    <div className="super-side-navs">
      <ul>
        <li>
          <button
            onClick={() => handleAdminSideNavClick(ROUTESCONSTANTS.dashboard)}
            className={
              loacation.pathname === ROUTESCONSTANTS.dashboard
                ? " admin-nav-item active"
                : "admin-nav-item"
            }
          >
            Dashboard
          </button>
        </li>
        <li>
          <button
            onClick={() => handleAdminSideNavClick(ROUTESCONSTANTS.profile)}
            className={
              loacation.pathname === ROUTESCONSTANTS.profile
                ? " admin-nav-item active"
                : "admin-nav-item"
            }
          >
            Profile
          </button>
        </li>
        <li>
          <button
            onClick={() => handleAdminSideNavClick(ROUTESCONSTANTS.editProfile)}
            className={
              loacation.pathname === ROUTESCONSTANTS.editProfile
                ? " admin-nav-item active"
                : "admin-nav-item"
            }
          >
            Update Profile
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              handleAdminSideNavClick(ROUTESCONSTANTS.updatePassword)
            }
            className={
              loacation.pathname === ROUTESCONSTANTS.updatePassword
                ? " admin-nav-item active"
                : "admin-nav-item"
            }
          >
            Change Password
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              handleAdminSideNavClick(ROUTESCONSTANTS.subscriptionHistory)
            }
            className={
              loacation.pathname === ROUTESCONSTANTS.subscriptionHistory
                ? " admin-nav-item active"
                : "admin-nav-item"
            }
          >
            Subscription History
          </button>
        </li>
        <li>
          <button
            onClick={() => handleAdminSideNavClick(ROUTESCONSTANTS.assistants)}
            className={
              loacation.pathname === ROUTESCONSTANTS.assistants
                ? " admin-nav-item active"
                : "admin-nav-item"
            }
          >
            Assistants
          </button>
        </li>
        {userType === 1 ? (
          <>
            <li>
              <button
                onClick={() =>
                  handleAdminSideNavClick(ROUTESCONSTANTS.superAdminAllPrompts)
                }
                className={
                  loacation.pathname ===
                  ROUTESCONSTANTS.dashboard +
                    "/" +
                    ROUTESCONSTANTS.superAdminAllPrompts
                    ? " admin-nav-item active"
                    : "admin-nav-item"
                }
              >
                All Prompts
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  handleAdminSideNavClick(ROUTESCONSTANTS.superAdminAddPrompt)
                }
                className={
                  loacation.pathname ===
                  ROUTESCONSTANTS.dashboard +
                    "/" +
                    ROUTESCONSTANTS.superAdminAddPrompt
                    ? " admin-nav-item active"
                    : "admin-nav-item"
                }
              >
                Add New Prompt
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  handleAdminSideNavClick(ROUTESCONSTANTS.superAdminClients)
                }
                className={
                  loacation.pathname ===
                  ROUTESCONSTANTS.dashboard +
                    "/" +
                    ROUTESCONSTANTS.superAdminClients
                    ? " admin-nav-item active"
                    : "admin-nav-item"
                }
              >
                Clients
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  handleAdminSideNavClick(ROUTESCONSTANTS.superAdminUserPlan)
                }
                className={
                  loacation.pathname ===
                  ROUTESCONSTANTS.dashboard +
                    "/" +
                    ROUTESCONSTANTS.superAdminUserPlan
                    ? " admin-nav-item active"
                    : "admin-nav-item"
                }
              >
                User Plan
              </button>
            </li>
          </>
        ) : null}
      </ul>
      <div className="dashboard-signout-wrapper">
        <button
          className="dashboard-signout d-inline-flex align-items-center"
          onClick={() => {
            logout();
            navigate(0);
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default SideNav;
