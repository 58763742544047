// @flow strict
import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import SelectBox from "../../../Common/SelectBox";
import TextField from "../../../Common/TextField";
import { unlimitedCreditEmails } from "../../../config/unlimitedCreditEmails";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import {
  propertyTypes,
  states,
  styleTonsData,
} from "../../../Utility/content-options-data";
import { isExpired } from "../../../Utility/utility";

function GenerateContentUI({
  onChangeInputData,
  inputData,
  handleSubmit,
  handleStateError,
  errors,
  outputData,
  loading,
  handleSaveContent,
  setInputData,
  logedInUser,
  cities,
  onChangeStatesData,
  remaining,
}) {
  return (
    <div className="content-generator-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            {unlimitedCreditEmails.includes(logedInUser.email) ? (
              <h6 className="text-center million-title mb-3">
                You have an unlimited amount of credit available.
              </h6>
            ) : (
              isExpired() ?
                <h6 className="text-center mb-3">
                  <span className="text-danger">You subscription plan has been expired.</span>
                  <span>{" "}Buy more
                    credits{" "}</span>
                  <Link
                    to={ROUTESCONSTANTS.buyCredit}
                    className="text-blue-theme million-title"
                  >
                    here
                  </Link>
                  .
                </h6>
                :
                <h6 className="text-center million-title mb-3">
                  You have {remaining?.socialMediaContentRemaining} credit left.
                  Buy more credits{" "}
                  <Link
                    to={ROUTESCONSTANTS.buyCredit}
                    className="text-blue-theme"
                  >
                    here
                  </Link>
                  .
                </h6>
            )}
            <div className="content-generator-inner card">
              <form onSubmit={handleSubmit} className="">
                <div className="text-center mb-4">
                  <h2>Content Generator</h2>
                </div>
                <div className="row">
                  <div className="col-12 mb-3">
                    <SelectBox
                      defaultValue={inputData?.state}
                      onChange={(data) => onChangeStatesData(data.value)}
                      options={states}
                      className="basic-single"
                      classNamePrefix="select"
                      label="States:"
                      onBlur={() => handleStateError("state", "States")}
                    />
                    <div style={{ color: "red", marginTop: "4px" }}>
                      {errors?.state && <span>States is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <SelectBox
                      defaultValue={inputData?.city}
                      onChange={(data) => onChangeInputData("city", data.value)}
                      options={cities}
                      className="basic-single"
                      classNamePrefix="select"
                      label="City:"
                      onBlur={() => handleStateError("city", "City")}
                    />
                    <div style={{ color: "red", marginTop: "4px" }}>
                      {errors?.city && <span>City is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      placeholder="Street"
                      value={inputData?.street}
                      onChange={(e) =>
                        onChangeInputData("street", e.target.value)
                      }
                      onBlur={() => handleStateError("street", "Street")}
                    />

                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {errors?.street && <span>Steet is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <SelectBox
                      defaultValue={inputData?.property}
                      onChange={(data) =>
                        onChangeInputData("property", data.value)
                      }
                      options={propertyTypes}
                      className="basic-single"
                      classNamePrefix="select"
                      label="Property type:"
                      onBlur={() =>
                        handleStateError("property", "Property type")
                      }
                    />
                    <div style={{ color: "red", marginTop: "4px" }}>
                      {errors?.property && (
                        <span>Property type is required!</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      placeholder="Number of Bed"
                      type="number"
                      value={inputData?.bed}
                      onChange={(e) => onChangeInputData("bed", e.target.value)}
                      onBlur={() => handleStateError("bed", "Number of Bed")}
                    />

                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {errors?.bed && <span>Number of Bed is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      placeholder="Number of Bath"
                      type="number"
                      value={inputData?.bath}
                      onChange={(e) =>
                        onChangeInputData("bath", e.target.value)
                      }
                      onBlur={() => handleStateError("bath", "Number of Bath")}
                    />

                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {errors?.bath && <span>Number of Bath is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      placeholder="SqFt"
                      type="number"
                      value={inputData?.SqFt}
                      onChange={(e) =>
                        onChangeInputData("SqFt", e.target.value)
                      }
                      onBlur={() => handleStateError("SqFt", "SqFt")}
                    />

                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {errors?.SqFt && <span>SqFt is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <Form.Control
                      as="textarea"
                      placeholder="Additional Property Info"
                      style={{ height: "100px" }}
                      value={inputData?.additionalInfo}
                      onChange={(e) =>
                        onChangeInputData("additionalInfo", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      placeholder="Target Buyer"
                      value={inputData?.targetBuyer}
                      onChange={(e) =>
                        onChangeInputData("targetBuyer", e.target.value)
                      }
                      onBlur={() =>
                        handleStateError("targetBuyer", "Target Buyer")
                      }
                    />

                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {errors?.targetBuyer && (
                        <span>Target Buyer is required!</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <SelectBox
                      defaultValue={inputData?.styleTons}
                      onChange={(data) =>
                        onChangeInputData("styleTons", data.value)
                      }
                      options={styleTonsData}
                      className="basic-single"
                      classNamePrefix="select"
                      label="Style and Tone:"
                      onBlur={() =>
                        handleStateError("styleTons", "Style and Tone")
                      }
                    />
                    <div style={{ color: "red", marginTop: "4px" }}>
                      {errors?.styleTons && (
                        <span>Style and Tone are required!</span>
                      )}
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <TextField
                      placeholder="Platform"
                      value={inputData?.platform}
                      onChange={(e) =>
                        onChangeInputData("platform", e.target.value)
                      }
                      onBlur={() => handleStateError("platform", "Platform")}
                    />

                    <div style={{ color: "red", marginTop: "-1rem" }}>
                      {errors?.platform && <span>Platform is required!</span>}
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    {loading ? (
                      <Button
                        type="submit"
                        className="btn btn-base w-100 content-generation-btn"
                        disabled={loading}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ps-1">
                          Just a moment, we're generating your content...
                        </span>
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className="btn btn-base w-100 content-generation-btn"
                      >
                        Generate Content
                      </Button>
                    )}
                  </div>
                </div>
              </form>
              {outputData?.length > 0 && (
                <div className="border-top mt-4 pt-4">
                  {outputData.map((week, i) => (
                    <div key={i} className="mb-3">
                      <div
                        dangerouslySetInnerHTML={{ __html: week.content }}
                      ></div>
                    </div>
                  ))}
                  <div className="mt-4 d-flex justify-content-center">
                    <Button
                      className="btn btn-base content-generation-btn"
                      onClick={handleSaveContent}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateContentUI;
