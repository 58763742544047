import React, { useContext, useEffect, useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
import { useWindowSize } from "react-use";
import logo from "../../../assests/images/logo1.png";
import { SidebarContext } from "../../../contexts/SidebarContext";
import "./Sidebar.scss";
import SidebarNav from "./SidebarNav";
import { Link } from "react-router-dom";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { getLogedInUser } from "../../../Utility/utility";

// const superAdminNav = "super-admin";
// const trainerNav = "trainer";

const Sidebar = () => {
  const { activeMenu, setActiveMenu } = useContext(SidebarContext);
  const logedInUser = getLogedInUser();

  const { width } = useWindowSize();
  // console.log(activeMenu);

  // const navigationItem = useMemo(() => {
  //   if (userType === 1) {
  //     return superAdminNav;
  //   }
  //   return trainerNav;
  // }, [userType]);

  // useEffect(() => {
  //   if (width < 992) {
  //     setActiveMenu(false);
  //   }
  // }, [setActiveMenu, width]);

  return (
    <Offcanvas
      className=""
      show={activeMenu}
      onHide={() => setActiveMenu(false)}
    >
      <Offcanvas.Header
        className="text-white sidbar-header"
        closeVariant="white"
        closeButton
      >
        <div className="logo">
          <Link to={ROUTESCONSTANTS.home}>
            <img src={process.env.PUBLIC_URL + "/logo.png"} alt="img" />
          </Link>
        </div>
        {/* <hr className="bg-white" /> */}
      </Offcanvas.Header>
      <div className="transition d-flex">
        <div className="d-flex flex-column flex-shrink-0 px-3 pt-4 w-100">
          <SidebarNav firstName={logedInUser?.firstName ?? null} />
        </div>
      </div>
    </Offcanvas>
  );
};

export default Sidebar;
