import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import * as Yup from "yup";
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ROUTESCONSTANTS } from '../../constants/Routes';

const ChangePassword = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [newVisible, setNewVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);

    // Define Yup validation schema
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Current password is required'),
        newPassword: Yup.string()
            .required('New password is required')
            .min(8, 'New password must be at least 8 characters long'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    // Initial form values
    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    }

    // Form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        console.log(values, "Update Pass");
        const requestPayload = {
            ...values,
        };

        try {
            const response = await axiosSecure.put(
                "/change-password",
                requestPayload,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );
            toast.success(response?.data?.message);
            navigate(ROUTESCONSTANTS.dashboard);
        } catch (err) {
            toast.error(err?.response?.data?.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="signup-page-area">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center card">
                    <div className="col-12 col-lg-8">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, touched, isSubmitting }) => (
                                <Form className="signup-inner">
                                    <div className="text-center mb-5">
                                        <h2>Update Password</h2>
                                    </div>

                                    <div
                                        className="form-group mb-4"
                                        style={{ position: "relative" }}
                                    >
                                        {/* <label htmlFor="oldPassword">Old Password</label> */}
                                        <Field
                                            placeholder="Enter Current Password"
                                            type={visible ? "text" : "password"}
                                            className={`form-control ${errors.oldPassword && touched.oldPassword
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            id="oldPassword"
                                            name="oldPassword"
                                        />

                                        <ErrorMessage
                                            name="oldPassword"
                                            component="div"
                                            className="text-danger"
                                        />

                                        {visible ? (
                                            <AiOutlineEye
                                                size={25}
                                                onClick={() => setVisible(false)}
                                                className="visibleIcon"
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                size={25}
                                                onClick={() => setVisible(true)}
                                                className="visibleIcon"
                                            />
                                        )}

                                    </div>

                                    <div
                                        className="form-group mb-4"
                                        style={{ position: "relative" }}
                                    >
                                        {/* <label htmlFor="newPassword">New Password</label> */}
                                        <Field
                                            placeholder="Enter New Password"
                                            type={newVisible ? "text" : "password"}
                                            // type="password"
                                            className={`form-control ${errors.newPassword && touched.newPassword
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            id="newPassword"
                                            name="newPassword"
                                        />

                                        <ErrorMessage
                                            name="newPassword"
                                            component="div"
                                            className="text-danger"
                                        />

                                        {newVisible ? (
                                            <AiOutlineEye
                                                size={25}
                                                onClick={() => setNewVisible(false)}
                                                className="visibleIcon"
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                size={25}
                                                onClick={() => setNewVisible(true)}
                                                className="visibleIcon"
                                            />
                                        )}
                                    </div>

                                    <div
                                        className="form-group mb-4"
                                        style={{ position: "relative" }}
                                    >
                                        {/* <label htmlFor="confirmPassword">Confirm Password</label> */}
                                        <Field
                                            placeholder="Enter Confirm Password"
                                            type={confirmVisible ? "text" : "password"}
                                            className={`form-control ${errors.confirmPassword && touched.confirmPassword
                                                ? "is-invalid"
                                                : ""
                                                }`}
                                            id="confirmPassword"
                                            name="confirmPassword"
                                        />

                                        <ErrorMessage
                                            name="confirmPassword"
                                            component="div"
                                            className="text-danger"
                                        />
                                        {confirmVisible ? (
                                            <AiOutlineEye
                                                size={25}
                                                onClick={() => setConfirmVisible(false)}
                                                className="visibleIcon"
                                            />
                                        ) : (
                                            <AiOutlineEyeInvisible
                                                size={25}
                                                onClick={() => setConfirmVisible(true)}
                                                className="visibleIcon"
                                            />
                                        )}
                                    </div>

                                    <div className="col-12 mb-4">
                                        <button className="btn btn-base w-100 sign-form-btn">
                                            Update Password
                                        </button>
                                    </div>
                                    {/* <div className="col-12 link-wrapper">
                                        <Link to={ROUTESCONSTANTS.forgetPassword}>
                                            Forgot Password
                                        </Link>
                                        <Link to={ROUTESCONSTANTS.signup}>Signup</Link>
                                    </div> */}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
