import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getLogedInUser } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";
import EditForm from "./editForm";

const EditPrompt = () => {
  const logedInUser = getLogedInUser();
  const [dymanicInputs, setDymanicInputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { promptId } = useParams();

  const [editablePrompt, setEditablePrompt] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const fetchPromptData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(`/admin/prompt/${promptId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setEditablePrompt(response?.data?.data);
      } catch (err) {
        console.log(err);
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin);
        }
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    if (logedInUser && logedInUser.userType === 1) {
      fetchPromptData();
    }

    return () => controller && controller.abort();
  }, [promptId]);

  if (loading) {
    return (
      <>
        <div className="user_card_wrapper m-auto">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (logedInUser && logedInUser?.userType !== 1) {
    return <h2 className="text-center">Page Not Found</h2>;
  }

  return editablePrompt ? (
    <EditForm defaultValue={editablePrompt} />
  ) : (
    <h3 className="text-center">Can't load editable prompt's data.</h3>
  );
};

export default EditPrompt;
