// @flow strict

import axios from 'axios';
import * as React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { axiosOpen } from '../../api/axios';
import { convertDate, getLogedInUser, getRealtorProfile } from "../../Utility/utility";
import UpdateRealtorProfile from "./UpdateRealtorProfile";

function UserProfile() {
  const [isRealtorVisible, setIsRealtorVisible] = useState(false);
  const loggedInUserData = getLogedInUser();
  const realtorProfile = getRealtorProfile();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [loading, setLoading] = useState(false);

  const handleSyncRealtorProfile = async () => {
    setLoading(true);

    const options = {
      method: 'GET',
      url: 'https://realtor-com4.p.rapidapi.com/agents/profile',
      params: { id: realtorProfile?.id },
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST
      }
    };

    try {
      const response = await axios.request(options);
      const realtorRes = response.data;

      const userDetailsPayload = {
        user: userDetails.user,
        token: userDetails?.token,
        realtorProfile: realtorRes,
      };

      await axiosOpen.post("auth/save-profile", {
        userId: userDetails.user._id,
        profile: realtorRes,
      });

      localStorage.userDetails = JSON.stringify(userDetailsPayload);
      toast.success("Realtor profile synced successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    };
  };

  return (
    <>
      <div className="user-profile-container">
        <div className="d-flex border-bottom pb-3 mb-4 justify-content-between align-items-center">
          <h3 className="user-profile-title">
            User Profile
          </h3>
          {
            realtorProfile ?
              <button
                onClick={handleSyncRealtorProfile}
                disabled={loading}
                className="realtor-connect-btn">
                {loading ? "Syncing Profile..." : "Sync Realtor Profile"}
              </button>
              :
              <button onClick={() => setIsRealtorVisible(true)} className="realtor-connect-btn">
                Connect Realtor Profile
              </button>
          }

        </div>
        <div className="user-profile-content">
          <div className="d-flex align-items-center gap-4">
            <p className="content-title">First Name:</p>
            <p className="content-value">{loggedInUserData.firstName}</p>
          </div>
          <div className="d-flex align-items-center gap-4">
            <p className="content-title">Last Name:</p>
            <p className="content-value">{loggedInUserData?.lastName || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-4">
            <p className="content-title">Email:</p>
            <p className="content-value">{loggedInUserData?.email || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-4">
            <p className="content-title">Phone Number:</p>
            <p className="content-value">{loggedInUserData?.phoneNumber || ""}</p>
          </div>
          <div className="d-flex align-items-center gap-4">
            <p className="content-title">Website:</p>
            <p className="content-value">{loggedInUserData?.website || ""}</p>
          </div>
          {/* <div className="d-flex align-items-center gap-4">
          <p className="content-title">Account Type:</p>
          <p className="content-value">
            {loggedInUserData?.userType === 1
              ? "Admin Account"
              : "User Account"}
          </p>
        </div> */}
          <div className="d-flex align-items-center gap-4">
            <p className="content-title">Joined At:</p>
            <p className="content-value">
              {convertDate(loggedInUserData?.createdAt)}
            </p>
          </div>
        </div>
      </div>
      <UpdateRealtorProfile
        isRealtorVisible={isRealtorVisible}
        setIsRealtorVisible={setIsRealtorVisible}
      />
    </>
  );
}

export default UserProfile;
