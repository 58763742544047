import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

function ThreadModal({
  show,
  handleClose,
  thread,
  setThread,
  createAssistantThread,
}) {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Enter conversation name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your conversation name."
                value={thread}
                onChange={(e) => setThread(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={createAssistantThread}>
            Create Converseation
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ThreadModal;
