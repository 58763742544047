import React from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function UploadDropZone({ onUpload }) {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        let url;
        reader.onloadend = () => {
          url = reader.result;
          onUpload(Object.assign(file, { url }));
        };
      });
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  return (
    <article className="dragNdrop__container">
      <div {...getRootProps({ className: "dropzone dragNdrop__box" })}>
        <input {...getInputProps()} />
        <span className="btn--upload">Upload an Image</span>
        <p>...or drag and drop an image.</p>
      </div>
    </article>
  );
}

export default UploadDropZone;
