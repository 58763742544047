import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from "yup";
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../service';

const UpdateProfileForm = ({ defaultValue }) => {
    const navigate = useNavigate();
    let userInfo = getUserDetails();

    const { firstName, lastName, phoneNumber, website, _id } = defaultValue;

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .max(35, "First name must be at most 35 characters")
            .required("First name is required"),
        lastName: Yup.string()
            .max(35, "Last name must be at most 35 characters")
            .required("Last name is required"),
        website: Yup.string().notRequired(),
        phoneNumber: Yup.string().matches(
            /^\+?[0-9]{1,3}-?[0-9]{6,14}$/g,
            'Please enter a valid phone number'
        )
    });

    const initialValues = {
        firstName: firstName,
        lastName: lastName,
        website: website,
        phoneNumber: phoneNumber,
    };

    // Form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        const requestPayload = {
            ...values,
            uid: _id
        };
        try {
            const response = await axiosSecure.put(
                "/update-profile",
                requestPayload,
                {
                    headers: { Authorization: getAuthorizationHeader() },
                }
            );
            toast.success(response?.data?.message);
            localStorage.setItem("userDetails", JSON.stringify({ token: userInfo?.token, user: response.data?.data }));
            navigate(0);
        } catch (err) {
            toast.error(err?.response?.data?.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="signup-page-area">
            <div className="container">
                <div className="row justify-content-center card">
                    <div className="col-12">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form className="signup-inner">
                                    <div className="text-center mb-4">
                                        <h2>Update Profile</h2>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6'>
                                            <div className="form-group mb-3">
                                                <label htmlFor="firstName" style={{ color: "gray" }}>First Name</label>
                                                <Field
                                                    type="text"
                                                    placeholder="First Name"
                                                    className={`form-control ${errors.firstName && touched.firstName
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    id="firstName"
                                                    name="firstName"
                                                    value={values.firstName.slice(0, 35)}
                                                />
                                                <ErrorMessage
                                                    name="firstName"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>

                                        <div className='col-sm-12 col-md-6'>
                                            <div className="form-group mb-3">
                                                <label htmlFor="lastName" style={{ color: "gray" }}>Last Name</label>
                                                <Field
                                                    placeholder="Last Name"
                                                    type="text"
                                                    className={`form-control ${errors.lastName && touched.lastName
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    id="lastName"
                                                    name="lastName"
                                                    value={values.lastName.slice(0, 35)}
                                                />
                                                <ErrorMessage
                                                    name="lastName"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6'>
                                            <div className="form-group mb-3">
                                                <label htmlFor="website" style={{ color: "gray" }}>Website Url</label>
                                                <Field
                                                    placeholder="Website Url"
                                                    type="text"
                                                    className={`form-control ${errors.website && touched.website ? "is-invalid" : ""
                                                        }`}
                                                    id="website"
                                                    name="website"
                                                />
                                                <ErrorMessage
                                                    name="website"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>

                                        <div className='col-sm-12 col-md-6'>
                                            <div className="form-group mb-3">
                                                <label htmlFor="phoneNumber" style={{ color: "gray" }}>Phone Number</label>
                                                <Field
                                                    placeholder="Phone Number"
                                                    type="text"
                                                    className={`form-control ${errors.phoneNumber && touched.phoneNumber
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                />
                                                <ErrorMessage
                                                    name="phoneNumber"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 my-4">
                                        <button
                                            type="submit"
                                            className="btn btn-base w-100 create-account-btn"
                                        >
                                            Update Profile
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateProfileForm;
