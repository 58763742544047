import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FullScreenLoader from "../../../Common/Loader/FullScreenLoader";
import SelectBox from "../../../Common/SelectBox";
import TextField from "../../../Common/TextField";
import { getDynamicInputArray } from "../../../Utility/prompt-dynamic-data";
import { getLogedInUser } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";

function PromptsAddForm() {
  const logedInUser = getLogedInUser();

  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const dynamicInputRef = useRef(null);
  const [promptTitle, setPromptTitle] = useState("");
  const [titleError, setTitleError] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [cursorPosition, setCursorPosition] = useState(0);
  const [promptError, setPromptError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [acitvePrompt, setAcitvePrompt] = useState(false);
  const [promptIdentifiers, setPromptIdentifiers] = useState([]);
  const [selectIdentifier, setSelectIdentifier] = useState("");
  const [dymanicInputs, setDymanicInputs] = useState([]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "i") {
        event.preventDefault();
        dynamicInputRef.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function promptErrorValidation() {
    if (!promptTitle) {
      setTitleError("Prompt Title is required");
    } else {
      setTitleError(null);
    }
  }

  const handleUpdateTemplate = (template) => {
    setDymanicInputs(getDynamicInputArray(template.value));
    setSelectIdentifier(template.value);
  };

  function handleDynamicInputChange(input) {
    const dynamicValue = input.value + " ";
    setPrompt((prevPrompt) => {
      const oldPrompt = prevPrompt;
      if (oldPrompt.length === 0) return dynamicValue;
      else {
        const firstPart = oldPrompt.slice(0, cursorPosition);
        const lastPart = oldPrompt.slice(cursorPosition);

        return [firstPart, dynamicValue, lastPart].join("");
      }
    });
    textareaRef.current.focus();
    // textareaRef.current.setSelectionRange(0, cursorPosition);
  }

  const handleBlur = () => {
    if (textareaRef.current) {
      const position = textareaRef.current.selectionStart;
      setCursorPosition(position);
    }
  };

  const handlePromptSubmit = async (e) => {
    e.preventDefault();
    console.log("first");
    if (!promptTitle) {
      setTitleError("Prompt Title is required");
      return;
    } else if (titleError) {
      setTitleError(null);
    }
    if (!prompt) {
      setPromptError("Prompt is required");
      return;
    } else if (promptError) {
      setPromptError(null);
    }
    setSubmitting(true);
    const requestPayload = {
      title: promptTitle,
      prompt: prompt,
      isActive: acitvePrompt,
      templateIdentifier: selectIdentifier,
    };

    try {
      const response = await axiosSecure.post(
        "/admin/prompt/add",
        requestPayload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response?.data?.message);
      navigate("/dashboard/prompts", { replace: true });
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchPromptData = async () => {
      try {
        const response = await axiosSecure.get(`admin/templates`, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        setPromptIdentifiers(response?.data?.data);
      } catch (err) {
        console.log(err);
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      } finally {
        // setLoading(false);
      }
    };

    fetchPromptData();
    return () => controller && controller.abort();
  }, [navigate]);

  return logedInUser && logedInUser.userType === 1 ? (
    <>
      {submitting && <FullScreenLoader />}

      <form className="prompts-form-wrapper" onSubmit={handlePromptSubmit}>
        <header className="text-center mb-4">
          <h3>Add Prompt</h3>
        </header>
        <div className="w-100 mb-4">
          <div className="d-flex align-items-center ">
            <Form.Check
              type="switch"
              id="toggle-switch"
              label=""
              checked={acitvePrompt}
              onChange={() => setAcitvePrompt(!acitvePrompt)}
            />

            {acitvePrompt ? (
              <strong className="ms-2">Active Prompt</strong>
            ) : (
              <strong className="ms-2 text-muted">Inactive Prompt</strong>
            )}
          </div>
        </div>

        <div className="w-100 mb-4">
          <TextField
            type="text"
            value={promptTitle}
            onChange={(e) => setPromptTitle(e.target.value)}
            onBlur={promptErrorValidation}
            placeholder="Prompt Title"
            required
            className="prompt-title"
          />
          <div style={{ color: "red", marginTop: "-1rem" }}>
            {" "}
            {titleError && <span>{titleError}</span>}{" "}
          </div>
        </div>

        <div className="w-100">
          <div className="custom-editor-wrapper">
            <header className="custom-editor-header">
              <div className="d-flex align-items-center gap-3 flex-column flex-md-row">
                <SelectBox
                  onChange={handleUpdateTemplate}
                  options={promptIdentifiers.map((item) => ({
                    label: item.title,
                    value: item.identifier,
                  }))}
                  className="basic-single"
                  classNamePrefix="select"
                  label="Select a prompt template"
                />

                <SelectBox
                  onChange={handleDynamicInputChange}
                  options={dymanicInputs}
                  className="basic-single"
                  classNamePrefix="select"
                  label="Select a dymanic input"
                  ref={dynamicInputRef}
                />
              </div>
              <h6 className="text-muted mb-0 mt-2">
                {"' Ctrl / Command (mac) + i '"}
                {"  "}
                {"to search dymanic input"}
              </h6>
            </header>
            <div className="custom-editor-textarea">
              <textarea
                ref={textareaRef}
                name="editor"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onBlur={handleBlur}
                placeholder="Write your prompt here..."
              />
              {promptError && (
                <div className="text-center text-danger my-3">
                  {" "}
                  <span>{promptError}</span>{" "}
                </div>
              )}
            </div>
          </div>

          <div className="custom-editor-submit-area mt-4 d-flex justify-content-center align-items-center">
            <button
              className="custom-editor-submit btn btn-base d-inline-flex align-items-center"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  ) : (
    <h2 className="text-center">Page Not Found</h2>
  );
}

export default PromptsAddForm;
