import React from "react";
import { Modal } from "react-bootstrap";
import { HiOutlineTrash } from "react-icons/hi";

const DeleteModal = ({ show, onHide, onDelete, itemName }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="delete-modal-body d-flex flex-column justify-content-center align-items-center gap-2">
          <div className="delete-modal-iconWrapper">
            <HiOutlineTrash />
          </div>
          <h6 className="mt-2">{itemName}</h6>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center gap-2 w-100">
          <button className="cancel-btn-modal" onClick={onHide}>
            Cancel
          </button>
          <button className="delete-btn-modal" onClick={onDelete}>
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
