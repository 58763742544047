import React from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

const HomeShowcase = () => {
  return (
    <section className="home-showcase">
      <div className="container">
        <div className="section-title text-center">
          <h2>Generating dreams using AI</h2>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src="./showcase/original-new-1.webp"
                  alt="original photo"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src="./showcase/generated-new-1.webp"
                  alt="generated photo"
                />
              }
              className="compare-slider row-fast"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src="./showcase/original-new-2.webp"
                  alt="original photo"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src="./showcase/generated-new-2.webp"
                  alt="generated photo"
                />
              }
              className="compare-slider"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src="./showcase/original-1.webp"
                  alt="original photo"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src="./showcase/generated-1.webp"
                  alt="generated photo"
                />
              }
              className="compare-slider row-fast"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src="./showcase/original-2.webp"
                  alt="original photo"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src="./showcase/generated-2.webp"
                  alt="generated photo"
                />
              }
              className="compare-slider"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src="./showcase/original-3.webp"
                  alt="original photo"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src="./showcase/generated-3.webp"
                  alt="generated photo"
                />
              }
              className="compare-slider row-fast"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ReactCompareSlider
              itemOne={
                <ReactCompareSliderImage
                  src="./showcase/original-new-3.webp"
                  alt="original photo"
                />
              }
              itemTwo={
                <ReactCompareSliderImage
                  src="./showcase/generated-new-3.webp"
                  alt="generated photo"
                />
              }
              className="compare-slider"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeShowcase;
