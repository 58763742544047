// @flow strict

import * as React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdOutlineSaveAlt } from 'react-icons/md';
import { VscServerProcess } from 'react-icons/vsc';

function HowWorks() {
  return (
    <section className="how-its-works">
      <div className="container">
        <div className="section-title text-center">
          <h2>How Does it Work?</h2>
        </div>
        <div className="">
          <div className="d-flex align-items-start gap-3 gap-md-5 my-5">
            <div className="icon-box">
              <AiOutlineCloudUpload className='icons' />
            </div>
            <div className="">
              <p className="works-card-title">
                Upload your source image(s)
              </p>
              <p className="works-card-desc">
                Please provide us with the source imagery of your hotel, villa, or room, and we will use the magic of AI to bring it to life.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-start gap-3 gap-md-5 my-5">
            <div className="icon-box">
              <VscServerProcess className='icons' />
            </div>
            <div className="">
              <p className="works-card-title">
                Let our AI make your images truly magic.
              </p>
              <p className="works-card-desc">
                Just wait a few moments for our advanced algorithms to perform their magic, and you will receive stunning new images that will make your competition envious. Do not accept mediocre marketing materials; allow us to enhance your visual content and elevate your brand to new heights.
              </p>
            </div>
          </div>
          <div className="d-flex align-items-start gap-3 gap-md-5 my-5">
            <div className="icon-box">
              <MdOutlineSaveAlt className='icons' />
            </div>
            <div className="">
              <p className="works-card-title">
                Download & Save
              </p>
              <p className="works-card-desc">
                We process your data on-site. We do not share your information with any third party and it will not be used for model improvement. You can download and save your generated image for later use.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWorks;