import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { convertDate } from "../../../Utility/utility";

function UpdateCreditModal({
  handleClose,
  show,
  profile,
  setProfile,
  remainingCount,
}) {


  const [loading, setLoading] = useState(false);
  const [creditsInput, setCreditsInput] = useState({
    roomRemaining: remainingCount?.roomRemaining,
    biographyRemaining: remainingCount?.biographyRemaining,
    socialMediaContentRemaining: remainingCount?.socialMediaContentRemaining,
    blogPostRemaining: remainingCount?.blogPostRemaining,
    newsletterCampaignRemaining: remainingCount?.newsletterCampaignRemaining,
    emailCampaignRemaining: remainingCount?.emailCampaignRemaining,
    instagramQuotesRemaining: remainingCount?.instagramQuotesRemaining,
    leadsGenerationRemaining: remainingCount?.leadsGenerationRemaining,
    improveContentRemaining: remainingCount?.improveContentRemaining,
    stepByStepGuideRemaining: remainingCount?.stepByStepGuideRemaining,
    subscriptionExpiration: profile?.subscriptionExpiration,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const payload = {
      userId: profile?._id,
      subscriptionExpiration: creditsInput?.subscriptionExpiration,
      numberOfRoom: creditsInput?.roomRemaining,
      numberOfBiography: creditsInput?.biographyRemaining,
      numberOfSocialMediaContent: creditsInput?.socialMediaContentRemaining,
      numberOfBlogPost: creditsInput?.blogPostRemaining,
      numberOfNewsletterCampaign: creditsInput?.newsletterCampaignRemaining,
      numberOfEmailCampaign: creditsInput?.emailCampaignRemaining,
      numberOfInstagramQuotes: creditsInput?.instagramQuotesRemaining,
      numberOfLeadsGeneration: creditsInput?.leadsGenerationRemaining,
      numberOfImproveContent: creditsInput?.improveContentRemaining,
      numberOfStepByStepGuide: creditsInput?.stepByStepGuideRemaining,
    };

    try {
      const response = await axiosSecure.post(
        "/admin/update-user-credits",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      setProfile((prev) => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp.remainingCount = response?.data?.userSubscriptionData;
        temp.subscriptionExpiration = response?.data?.subscriptionExpiration;
        return temp;
      });
      toast.success(response?.data?.message);
      handleClose();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const isoDate = new Date(creditsInput?.subscriptionExpiration);
  const formattedDate = isoDate.toISOString().split('T')[0];

  return (
    <>
      <>
        <Modal.Header closeButton>
          <Modal.Title>Update Credit</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">No. Of Biography</Form.Label>
                <Form.Control
                  type="number"
                  value={creditsInput?.biographyRemaining}
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      biographyRemaining: e.target.value,
                    })
                  }
                  placeholder="Enter number of credit"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">No. of Blog</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      blogPostRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.blogPostRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">No. of Email</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      emailCampaignRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.emailCampaignRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">
                  No. of Improve Content
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      improveContentRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.improveContentRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">
                  No. of Instagram Quote
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      instagramQuotesRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.instagramQuotesRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">
                  No. of Lead Generation
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      leadsGenerationRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.leadsGenerationRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">No. of Newsletter</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      newsletterCampaignRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.newsletterCampaignRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">
                  No. of Room Generation
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      roomRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.roomRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">
                  No. of Social Media Content
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      socialMediaContentRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.socialMediaContentRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className="fw-light">
                  No. of Step by Step Guide
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      stepByStepGuideRemaining: e.target.value,
                    })
                  }
                  value={creditsInput?.stepByStepGuideRemaining}
                  placeholder="Enter number of credit"
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="formDate">
                <Form.Label className="fw-light">
                  Update an Expire Date:
                </Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) =>
                    setCreditsInput({
                      ...creditsInput,
                      subscriptionExpiration: e.target.value,
                    })
                  }
                  value={formattedDate}
                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="px-4">
          <button
            onClick={handleSubmit}
            type="submit"
            className="btn btn-base w-100 create-account-btn"
          >
            Update Credit
          </button>
        </Modal.Footer>
      </>
    </>
  );
}

export default UpdateCreditModal;
