import React from "react";
import SideNav from "../../../component/SuperAdminDashboard/SideNav";
import { Outlet } from "react-router-dom";

function SuperAdminDashboard({ userType }) {
  const gotoView = () => {
    const outletArea = document.getElementById("outlet_id");
    if (outletArea) {
      outletArea.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <section className="super-admin-dashboard">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <SideNav gotoView={gotoView} userType={userType} />
          </div>
          <div className="col-lg-9" id="outlet_id">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SuperAdminDashboard;
