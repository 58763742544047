import { Formik } from "formik";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import PlanInput from "../PlanInput";

const validationSchema = Yup.object({
  planName: Yup.string().required("Plan Name is Required"),
  price: Yup
    .number()
    .required('Price is required')
    .positive('Price must be a positive number')
    .min(0, 'Price must be greater than or equal to 0'),
  numberOfRoom: Yup.number().required("Number Of Room is required"),
  numberOfBiography: Yup.number().required("Number Of Biography is required"),
  numberOfSocialMediaContent: Yup.number().required(
    "Number Of Social Media Post is required"
  ),
  numberOfBlogPost: Yup.number().required("Number Of Blog Post is required"),
  numberOfNewsletterCampaign: Yup.number().required("Number Of Newsletter is required"),
  numberOfEmailCampaign: Yup.number().required("Number Of Email is required"),
  numberOfInstagramQuotes: Yup.number().required("Number Of Instagram quotes is required"),
  numberOfLeadsGeneration: Yup.number().required("Number Of Lead generation is required"),
  numberOfImproveContent: Yup.number().required("Number Of improve content is required"),
  numberOfStepByStepGuide: Yup.number().required("Number Of Step By Step Guide is required"),
  stripePriceKey: Yup.string().when('price', {
    is: (price) => parseInt(price) !== 0,
    then: Yup.string().required("Stripe Price key is required"),
    otherwise: Yup.string(),
  }),
});

const initialValues = {
  planName: "",
  price: "",
  numberOfRoom: "",
  numberOfBiography: "",
  numberOfSocialMediaContent: "",
  numberOfBlogPost: "",
  numberOfNewsletterCampaign: "",
  numberOfEmailCampaign: "",
  numberOfInstagramQuotes: "",
  numberOfLeadsGeneration: "",
  numberOfImproveContent: "",
  numberOfStepByStepGuide: "",
  stripePriceKey: "",
};

const AddUserPlan = () => {
  const navigate = useNavigate();

  const handleOnSubmit = async (values, { setSubmitting }) => {

    const payload = JSON.stringify({
      planName: values.planName,
      price: values.price.toString(),
      numberOfRoom: values.numberOfRoom,
      numberOfBiography: values.numberOfBiography,
      numberOfSocialMediaContent: values.numberOfSocialMediaContent,
      numberOfBlogPost: values.numberOfBlogPost,
      numberOfNewsletterCampaign: values.numberOfNewsletterCampaign,
      numberOfEmailCampaign: values.numberOfEmailCampaign,
      numberOfInstagramQuotes: values.numberOfInstagramQuotes,
      numberOfLeadsGeneration: values.numberOfLeadsGeneration,
      numberOfImproveContent: values.numberOfImproveContent,
      numberOfStepByStepGuide: values.numberOfStepByStepGuide,
      stripePriceKey: (values.price) !== 0 ? values.stripePriceKey : "N/A",
    });

    try {
      const response = await axiosSecure.post("/admin/user-plan/add", payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      toast.success(response.data.message);
      navigate("/dashboard/user-plan", { replace: true });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="flex-grow-1">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Container className="add-user-page d-flex flex-column justify-content-center">
              <div className="d-flex mb-4 align-items-center justify-content-between ">
                <h2>Add User Plan</h2>
                <button
                  className="btn btn-white shadow-sm rounded-2 align-items-center "
                  onClick={() => navigate(`/dashboard/${ROUTESCONSTANTS.superAdminUserPlan}`)}
                >
                  <span className="btn-text">Back</span>
                </button>
              </div>
              <Row>
                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='planName'
                  type="text"
                  title="Plan Name"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='price'
                  type="number"
                  title="Price"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfRoom'
                  type="number"
                  title="Number of Room"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfBiography'
                  type="number"
                  title="Number of Biography"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfSocialMediaContent'
                  type="number"
                  title="Number of Property Description"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfBlogPost'
                  type="number"
                  title="Number of Blog Post"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfNewsletterCampaign'
                  type="number"
                  title="Number of Newsletter"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfEmailCampaign'
                  type="number"
                  title="Number of Email"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfInstagramQuotes'
                  type="number"
                  title="Number of Instagram Quotes"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfLeadsGeneration'
                  type="number"
                  title="Number of Lead Generation"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfImproveContent'
                  type="number"
                  title="Number of Improve Content"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='numberOfStepByStepGuide'
                  title="Number of Step By Step Guide"
                  type="number"
                />

                <PlanInput
                  touched={touched}
                  errors={errors}
                  name='stripePriceKey'
                  title="Stripe Price Key"
                  type="text"
                />
              </Row>
              <Row>

              </Row>
              <Col md={12} lg={12} xl={12} className="mt-4 mb-4 ">
                <Button
                  className="btn btn-base rounded-2"
                  type="submit" disabled={isSubmitting}>
                  CREATE USER PLAN
                </Button>
              </Col>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddUserPlan;


