import React from "react";
import { Link } from "react-router-dom";
import { ROUTESCONSTANTS } from "../../../constants/Routes";

function HeroBanner() {
  return (
    <div
      className="banner-area banner-area-1 banner-area-bg"
      style={{ background: "url(./hero-banner.jpg)" }}
    >
      <div className="container">
        <div className="banner-area-inner">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="banner-inner text-center">
                <h2 className="fw-semibold">
                  Transform Listings, Ignite Engagement: Unlock the Power of AI for Dynamic Property Marketing!
                </h2>
                <div className="line mb-5" />
                <div className=" align-items-center justify-content-center hero-btn-wrapper">
                  <Link
                    className="btn btn-blue d-inline-flex align-items-center"
                    to={ROUTESCONSTANTS.generateContent}
                  >
                    <span className="btn-text">Generate Content</span>
                  </Link>
                  <Link
                    className="btn btn-base d-inline-flex align-items-center"
                    to={ROUTESCONSTANTS.generateRoom}
                  >
                    <span className="btn-text">Generate Room</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
