// @flow strict

import * as React from 'react';
import PaginationComponent from '../Pagination/Pagination';
import SavedContentCard from './SavedContentCard';

function SavedContentUI({ savedContents, totalContent, handlePagination, currentPage }) {

  return (
    <div className="saved-content-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="saved-content-inner card">
              {
                savedContents.length === 0 ?
                  <h3 className="text-center">You have no saved Content</h3>
                  :
                  <>
                    <div className="">
                      <h3 className='border-bottom pb-2'>Saved Content</h3>
                      <div className="row gap-4 mt-4">
                        {
                          savedContents.map((content, i) => (
                            <SavedContentCard key={i} content={content} />
                          ))
                        }
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 ">
                      <PaginationComponent
                        total={totalContent}
                        itemsPerPage={10}
                        currentPage={currentPage}
                        onPageChange={(page) => handlePagination(page)}
                      />
                    </div>
                  </>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedContentUI;