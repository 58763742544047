// @flow strict

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo2 from "../../assests/images/logo2.png";
import { getUserDetails } from '../../service';

function WarningModal({ handleCloseModal, handleChoosePlan }) {
  const navigate = useNavigate();
  const { user } = getUserDetails();

  return (
    <div className="plan-warning-modal p-4">
      <img className="warning-logo mb-3" alt="Crafted Meals" src={logo2}/>
      <p className="warning-text  my-3">
       <b>Dear {user?.firstName},</b> <br/><br/>
        We hope this message finds you well. We would like to inform you that your subscription plan
        for our real estate project is about to expire. To continue enjoying the benefits and 
        exclusive features of our services, we kindly invite you to renew your subscription plan.
      </p>
      <div className="d-flex justify-content-center align-content-end">
        <Button
          variant="success"
          onClick={() => navigate("/buy-credits")}
          className='btn upgrade-btn px-4 my-2'>
          Upgrade Now
        </Button>
      </div>
      <div className="d-flex justify-content-center align-content-end">
        <Button
          variant="text"
          onClick={() => handleCloseModal()}
          className='no-upgrade-btn p-0'
        >
          No Thanks
        </Button>
      </div>
    </div>
  );
};

export default WarningModal;