// @flow strict
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LimitWarningModal from "../../../Common/Modal/LimitWarningModal";
import useRemaining from "../../../Hooks/useRemaining";
import { getLogedInUser } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import GenerateInstaQuotesUI from "../../../component/AIContentGenerate/GenerateInsta";
import PageHeader from "../../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";
import { unlimitedCreditEmails } from "../../../config/unlimitedCreditEmails";

function GenerateInstaQuotes() {
  const logedInUser = getLogedInUser();
  const [outputData, setOutputData] = useState(``);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [topic, setTopic] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { remaining, setRemaining } = useRemaining();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !unlimitedCreditEmails.includes(logedInUser.email) &&
      remaining &&
      remaining?.instagramQuotesRemaining === 0
    ) {
      setShowModal(true);
      return;
    }
    setLoading(true);
    setIsCopied(false);

    const payload = JSON.stringify({
      topic: topic,
      templateIdentifier: "quotes-for-instagram",
    });

    try {
      const response = await axiosSecure.post("/call-open-ai", payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      const answer = response?.data?.data;
      setOutputData(answer);
      setLoading(false);
      setRemaining((prev) => ({
        ...prev,
        instagramQuotesRemaining: prev?.instagramQuotesRemaining - 1,
      }));
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
      setLoading(false);
    }
  };

  const handleCopyBlog = async (html) => {
    // Create a temporary element to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Append the temporary element to the document body
    document.body.appendChild(tempElement);

    // Create a range object to select the content
    const range = document.createRange();
    range.selectNodeContents(tempElement);

    // Create a selection object and add the range to it
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Copy the selected content to the clipboard
    document.execCommand("copy");

    // Remove the temporary element
    document.body.removeChild(tempElement);
    setIsCopied(true);
  };

  return (
    <>
      <PageHeader />
      {!logedInUser ? (
        <section className="content-generator-area">
          <div className="container">
            <div className=" row justify-content-center ">
              <div className="col-xl-8 col-lg-9 content-g-signin-text text-center">
                <h6 className="million-title">
                  Over 1 million users have used RealEstate AI so far.
                </h6>
                <h1 className="fw-bold mb-3">
                  Generate instagram quotes for real estate without any
                  thinking!
                </h1>
                <p>
                  Enjoy the benefits of a free account and unlock the
                  opportunity to transform your space. Sign up now and receive 3
                  credits to create stunning room images and captivating social
                  media posts.
                </p>
                <p className="mb-3 fw-medium">
                  If you’re already a member, simply sign in here.
                </p>
                <Link
                  className="btn btn-base d-inline-flex align-items-center"
                  to={ROUTESCONSTANTS.signin}
                >
                  <span className="btn-text">Sign in</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <GenerateInstaQuotesUI
          setTopic={setTopic}
          topic={topic}
          handleSubmit={handleSubmit}
          outputData={outputData}
          loading={loading}
          handleCopyBlog={handleCopyBlog}
          isCopied={isCopied}
          remaining={remaining}
          logedInUser={logedInUser}
        />
      )}
      <Modal
        scrollable={true}
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <LimitWarningModal handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
}

export default GenerateInstaQuotes;
