import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12  text-center">
              <p>
                © {new Date().getFullYear()} Real Estate, powered by{" "}
                <span style={{ color: "#ef233c" }}>❤</span>{" "}
                <Link
                  to="https://buildyourai.consulting/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="copyright-link"
                >
                  Build Your AI Consulting{" "}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
