import React, { useEffect, useState } from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service";

const SavedRoomDetails = () => {
  let { roomId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState(null);
  // const [sideBySide, setSideBySide] = useState(true);
  const [photoName, setPhotoName] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchRoomData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(`/generated-rooms/${roomId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        setRoom(response?.data?.data);
        const imageNameArray = response.data?.data?.outputImage?.split("/");
        setPhotoName(imageNameArray[imageNameArray.length - 1]);
      } catch (err) {
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        } setError(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchRoomData();

    return () => controller && controller.abort();
  }, [roomId]);

  if (loading) {
    return (
      <>
        <PageHeader />
        <section className="saved-rooms">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="user_card_wrapper m-auto">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageHeader />
        <section className="saved-rooms" style={{ minHeight: "80vh" }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title text-center">
                  <h2 className="text-capitalize mb-3">{error}</h2>
                  <button
                    className="btn btn-white shadow d-inline-flex align-items-center"
                    onClick={() => navigate(-1)}
                  >
                    <span className="btn-text">Back</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <PageHeader />
      <section className="saved-rooms">
        <div className="container">
          {room && (
            <div className="section-title text-center">
              <h2 className="text-capitalize mb-3">{room?.prompt}</h2>
              {/* <div className="d-flex justify-content-center">
                <Form>
                  <div className="d-flex align-items-center">
                    <span className="me-2">Compare Side by Side</span>
                    <Form.Check
                      type="switch"
                      id="toggle-switch"
                      label=""
                      checked={sideBySide}
                      onChange={() => setSideBySide(!sideBySide)}
                    />
                    <span className="ms-2">{sideBySide ? "On" : "Off"}</span>
                  </div>
                </Form>
              </div> */}
            </div>
          )}
          {/* {room && !sideBySide && (
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <article className="product-case">
                  <div className="room-cover">
                    <img src={room?.inputImage} alt="Original room" />
                  </div>
                  <header className="py-3 text-center">
                    <h5 className="mb-0">Original Room</h5>
                  </header>
                </article>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <article className="product-case mt-5 mt-md-0">
                  <div className="room-cover">
                    <img src={room?.outputImage} alt="Generated room" />
                  </div>
                  <header className="py-3 text-center">
                    <h5 className="mb-0">Generated Room</h5>
                  </header>
                </article>
              </div>
            </div>
          )} */}
          {/* after generated Compare slider */}
          {room && (
            <div className="d-flex justify-content-center mt-4">
              <ReactCompareSlider
                style={{ borderRadius: "8px" }}
                itemOne={
                  <ReactCompareSliderImage
                    src={room?.inputImage}
                    alt="original photo" />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src={room?.outputImage}
                    alt="generated photo" />
                }
                portrait
              />
            </div>
          )}
          {/* after generated buttons */}
          <div className="mt-4 align-items-center justify-content-center hero-btn-wrapper w-100">
            {/* <button
                className="btn btn-base shadow d-inline-flex align-items-center"
                onClick={() => {
                  downloadPhoto(room?.outputImage, appendNewToName(photoName));
                }}
              >
                <span className="btn-text">Download Generated Room</span>
              </button> */}
            <button
              className="btn btn-white shadow d-inline-flex rounded-2 align-items-center"
              onClick={() => navigate(-1)}
            >
              <span className="btn-text">Back</span>
            </button>
          </div>
          {/* {room && room?.outputImage ? (
            <div className="mt-4 align-items-center justify-content-center hero-btn-wrapper w-100">
              <button
                className="btn btn-base shadow d-inline-flex align-items-center"
                onClick={() => {
                  downloadPhoto(room?.outputImage, appendNewToName(photoName));
                }}
              >
                <span className="btn-text">Download Generated Room</span>
              </button>
              <button
                className="btn btn-white shadow d-inline-flex align-items-center"
                onClick={() => navigate(-1)}
              >
                <span className="btn-text">Back</span>
              </button>
            </div>
          ) : null} */}
        </div>
      </section>
    </>
  );
};

export default SavedRoomDetails;
