// @flow strict

import * as React from "react";
import { HiBadgeCheck, HiOutlineTrash, HiPencil } from "react-icons/hi";
import DeleteModal from "../../Modal";
import Truncate from "./Truncate";
function PromptCard({
  prompt,
  makeItActive,
  deleteThisPrompt,
  editThisPrompt,
}) {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleDeletePrompt = () => {
    setShowDeleteModal(false);
    deleteThisPrompt();
  };

  return (
    <>
      <div className="prompt-body">
        <div
          className="prompt-header d-flex flex-column flex-md-row align-items-start 
           justify-content-between"
        >
          <div className="">
            <h4 className="prompt-title">{prompt.title}</h4>
            <p className="mt-0 fs-8">{prompt.templateIdentifier}</p>
          </div>
          <div className="prompt-action d-flex align-items-center gap-2">
            {prompt.isActive ? (
              <button
                className="icon-button active-btn d-inline-flex justify-content-center align-items-center"
                disabled={prompt.isActive}
              >
                <HiBadgeCheck />
              </button>
            ) : (
              <button
                className="icon-button inactive-btn d-inline-flex justify-content-center align-items-center"
                disabled={prompt.isActive}
                onClick={makeItActive}
              >
                <HiBadgeCheck />
              </button>
            )}
            <button
              className="icon-button edit-btn d-inline-flex justify-content-center align-items-center"
              onClick={editThisPrompt}
            >
              <HiPencil />
            </button>
            {prompt.isActive ? null : (
              <button
                onClick={() => setShowDeleteModal(true)}
                className="icon-button delete-btn d-inline-flex justify-content-center align-items-center"
              >
                <HiOutlineTrash />
              </button>
            )}
          </div>
        </div>
        <Truncate character={420}>{prompt.prompt}</Truncate>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeletePrompt}
        itemName={`Are you sure!  you want to delete this prompt?`}
      />
    </>
  );
}

export default PromptCard;
