// @flow strict

import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { Button, Spinner } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function RealtorProfile({ isRealtorVisible, setIsRealtorVisible, setInitialValues, setRealtorProfile }) {
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);

  const getRealtorProfile = async () => {
    setLoading(true);

    const options = {
      method: 'GET',
      url: 'https://realtor-com4.p.rapidapi.com/agents/profile',
      params: { id: id },
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
        'X-RapidAPI-Host': process.env.REACT_APP_RAPID_API_HOST
      }
    };

    try {
      const response = await axios.request(options);
      const realtorProfile = response.data;

      setRealtorProfile(realtorProfile);
      setInitialValues((prev) => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp.firstName = realtorProfile.person_name;
        temp.phoneNumber = realtorProfile?.phones[0]?.number;
        temp.website = realtorProfile?.web_url;

        return temp;
      })
      setIsRealtorVisible(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={isRealtorVisible} onHide={() => setIsRealtorVisible(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Connect Realtor Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className='py-4'>
        <Form.Label htmlFor="id">
          Realtor Profile ID
        </Form.Label>
        <Form.Control
          type="text"
          id="id"
          placeholder='EX: 5bbf9674wa5abd0011fe539e'
          className='py-2'
          onChange={(e) => setId(e.target.value)}
        />
        <Form.Text  id="id" muted>
          Use your realtor.com profile ID to connect your profile.
        </Form.Text>

        <Link className="realtor-link" target="_blank" to="/docs/connect-realtor">How to get Realtor Profile ID?</Link>
      </Modal.Body>
      <Modal.Footer>
        {loading ? (
          <Button
            type="submit"
            className="btn btn-base rounded-2 content-generation-btn"
            disabled={loading}
            onClick={getRealtorProfile}
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="ps-1">
              Connecting...
            </span>
          </Button>
        ) : (
          <Button
            type="submit"
            onClick={getRealtorProfile}
            className="btn btn-base rounded-2 content-generation-btn"
            disabled={id === ""}
          >
            Connect Profile
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RealtorProfile;