import { useState } from "react";

const Truncate = ({ children, character = 150, onClick }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleLines = () => {
    setExpanded((prev) => !prev);
  };
  function handleClick(e) {
    if (onClick) {
      return onClick(e);
    }
    toggleLines();
  }
  if (!children) return null;
  const isCharacterLimitExceeded = children.length > character;
  if (!isCharacterLimitExceeded) {
    return <>{children}</>;
  }
  return (
    <p className="">
      {!expanded ? children.substring(0, character) + "..." : children}
      <span>
        <button
          onClick={handleClick}
          className={"mt-1 d-inline-block truncate-btn"}
        >
          {!expanded ? "See more" : "See less"}
        </button>
      </span>
    </p>
  );
};
export default Truncate;
