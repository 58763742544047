import styles from "./loading-dots.module.css";

const LoadingDots = ({ color = "#000", size = "small" }) => {
  return (
    <span className={size === "small" ? styles.loading2 : styles.loading}>
      <span style={{ backgroundColor: color }} />
      <span style={{ backgroundColor: color }} />
      <span style={{ backgroundColor: color }} />
    </span>
  );
};

export default LoadingDots;
