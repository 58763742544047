import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaPlus, FaRegPaperPlane } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";
import ChatList from "../ChatList";
import "./ChatContent.scss";
import ChatItem from "./ChatItem";

const ChatContent = () => {
  const { assistantId } = useParams();
  const [threadId, setThreadId] = useState("");
  const [allMessages, setAllMessages] = useState(null || []);
  const [allThreadsList, setAllThreadsList] = useState([]);
  const [allAssistant, setAllAssistant] = useState([]);

  const [input, setInput] = useState("");
  // End Create Thread Functionalities
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      user_input: input,
      thread_id: threadId,
      assistant_id: assistantId,
    };

    try {
      setLoading(true);
      setInput("");
      scrollToBottom();
      const response = await axiosSecure.post("/assistants/chat", payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      const { message } = response?.data;

      if (!threadId) {
        setThreadId(message?.thread_id);

        const newThread = {
          name: input,
          assistant_id: assistantId,
          thread_id: message.thread_id,
        };
        setAllThreadsList((prevAllThreads) => {
          const tempData = JSON.parse(JSON.stringify(prevAllThreads));
          tempData.unshift(newThread);
          return tempData;
        });
      }

      setAllMessages((prevAllMsg) => [...prevAllMsg, message]);

      if (response?.data?.success) {
        try {
          const res = await axiosSecure.post(
            "/assistants/chat/response",
            {
              thread_id: threadId || message?.thread_id,
              assistant_id: assistantId,
            },
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );
          setAllMessages((prevAllMsg) => [...prevAllMsg, res?.data?.message]);
        } catch (error) {
          if (error.response.status === 403) {
            logout();
            navigate(ROUTESCONSTANTS.signin, { replace: true });
          } else {
            toast.error(error?.response?.data?.message);
          }
        }
      }
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axiosSecure.post(
          `/get-thread-messages`,
          {
            thread_id: threadId,
          },
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        setAllMessages(response?.data?.messages?.body?.data?.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (threadId) {
      fetchMessages();
    }
  }, [threadId]);

  //Get all Thread List and Assistant List
  // Get All Threads List
  useEffect(() => {
    // const controller = new AbortController();
    const fetchAssistants = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(
          `/assistants/threads/${assistantId}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        const threads = response?.data?.messageThreadList;

        if (threads?.length > 0) {
          setThreadId(threads[0].thread_id);
          setAllThreadsList(threads);
        } else {
          setThreadId("");
          setAllThreadsList([]);
          setAllMessages([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssistants();

    // return () => controller && controller.abort();
  }, [assistantId]);

  // Get All Assistant List  from API
  useEffect(() => {
    const controller = new AbortController();
    const fetchAllAssistants = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/assistants`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setAllAssistant(response?.data?.assistants);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllAssistants();

    return () => controller && controller.abort();
  }, []);

  return (
    <div className="main__chatcontent">
      <ChatList
        setThreadId={setThreadId}
        allThreadsList={allThreadsList}
        setAllThreadsList={setAllThreadsList}
        allAssistant={allAssistant}
        setAllAssistant={setAllAssistant}
        setAllMessages={setAllMessages}
      />
      {/* Sidebar */}
      <div className="main__body">
        <div className="content__body">
          <div className="chat__items">
            {allMessages?.map((itm, index) => {
              return (
                <ChatItem
                  animationDelay={1}
                  key={itm?.id}
                  role={itm.role === "assistant" ? "assistant" : "me"}
                  time={itm?.created_at}
                  msg={itm.content[0].text.value || itm.msg}
                  scrollToBottom={scrollToBottom}
                />
              );
            })}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div>
          <div className="content__footer">
            <form onSubmit={handleSubmit} className="message__area">
              <div className="sendNewMessage">
                <button className="addFiles">
                  <FaPlus />
                </button>
                <input
                  type="text"
                  placeholder="Type a message here"
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                />
                {loading ? (
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btnSendMsg"
                    id="sendMsgBtn"
                    disabled={loading}
                  >
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btnSendMsg"
                    id="sendMsgBtn"
                  >
                    <FaRegPaperPlane />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContent;
