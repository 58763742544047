import React from "react";

const FullScreenLoader = () => {
  return (
    <div className="loading-backdrop">
      <div className="loading-wrapper">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
