import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTESCONSTANTS } from "../../constants/Routes";

function RoomCard({ imgUrl, prompt, id }) {
  const navigate = useNavigate();
  return (
    <article
      className="room-case"
      onClick={() => navigate(`${ROUTESCONSTANTS.savedRoom}/${id}`)}
    >
      <div className="room-cover">
        <img src={imgUrl} alt={prompt} />
      </div>
      <header className="py-3 text-center">
        <h6 className="mb-0">{prompt}</h6>
      </header>
    </article>
  );
}

export default RoomCard;
