// @flow strict

import * as React from "react";
import { GiMeal } from "react-icons/gi";
import { ImPriceTags } from "react-icons/im";
import {
  MdAccountCircle,
  MdDashboard,
  MdLogin,
  MdLogout,
} from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import { limitWord } from "../../../Utility/utility";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { logout } from "../../../service";

function SidebarNav({ firstName }) {
  const { activeMenu, setActiveMenu } = React.useContext(SidebarContext);
  // const { width } = useWindowSize();
  const navigate = useNavigate();

  const truncatedFirstName = React.useMemo(() => {
    if (firstName) return limitWord(firstName, 10);
    return null;
  }, [firstName]);

  return (
    <>
      <nav className="h-100vh">
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            {/* "active" should be added while switching the route */}
            <NavLink
              className={({ isActive }) =>
                `regular-nav-link d-inline-flex align-items-center px-3 ${
                  isActive ? "active" : " "
                } `
              }
              end={true}
              onClick={() => setActiveMenu(false)}
              to={ROUTESCONSTANTS.home}
            >
              <span>Home</span>
            </NavLink>
          </li>
          <li className="nav-item">
            {/* "active" should be added while switching the route */}
            <NavLink
              className={({ isActive }) =>
                `regular-nav-link d-inline-flex align-items-center px-3 ${
                  isActive ? "active" : " "
                }`
              }
              end={true}
              onClick={() => setActiveMenu(false)}
              to={ROUTESCONSTANTS.generateRoom}
            >
              <span>Room Generator</span>
            </NavLink>
          </li>
          <li className="nav-item">
            {/* "active" should be added while switching the route */}
            <NavLink
              className={({ isActive }) =>
                `regular-nav-link d-inline-flex align-items-center px-3 ${
                  isActive ? "active" : " "
                }`
              }
              end={true}
              onClick={() => setActiveMenu(false)}
              to={ROUTESCONSTANTS.generateContent}
            >
              <span>Content Generator</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <button
              className="btn btn-base sidebar-sign-in w-100 d-inline-flex align-items-center justify-content-center"
              onClick={() => {
                navigate(
                  firstName ? ROUTESCONSTANTS.dashboard : ROUTESCONSTANTS.signin
                );
                setActiveMenu(false);
              }}
            >
              {firstName ? (
                <MdAccountCircle className="login-icon" />
              ) : (
                <MdLogin className="login-icon" />
              )}
              <span className="btn-text">
                {truncatedFirstName ?? "Sign In"}
              </span>
            </button>
          </li>
          {firstName && (
            <>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `regular-nav-link d-inline-flex align-items-center px-3 ${
                      isActive ? "active" : " "
                    }`
                  }
                  end={true}
                  onClick={() => setActiveMenu(false)}
                  to={ROUTESCONSTANTS.buyCredit}
                >
                  <span>Buy Credit</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `regular-nav-link d-inline-flex align-items-center px-3 ${
                      isActive ? "active" : " "
                    }`
                  }
                  end={true}
                  onClick={() => setActiveMenu(false)}
                  to={ROUTESCONSTANTS.savedContent}
                >
                  <span>Saved Content</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    `regular-nav-link d-inline-flex align-items-center px-3 ${
                      isActive ? "active" : " "
                    }`
                  }
                  end={true}
                  onClick={() => setActiveMenu(false)}
                  to={ROUTESCONSTANTS.savedRoom}
                >
                  <span>Saved Room</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-base sidebar-sign-in w-100 d-inline-flex align-items-center justify-content-center"
                  onClick={() => {
                    logout();
                    navigate(0);
                    setActiveMenu(false);
                  }}
                >
                  <MdLogout className="login-icon" />
                  <span className="btn-text">{"Logout"}</span>
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
}

export default SidebarNav;

const TranerNavs = () => {
  const { activeMenu, setActiveMenu } = React.useContext(SidebarContext);
  const { width } = useWindowSize();

  return (
    <>
      <li>
        <NavLink
          to="/client?page=1"
          className={({ isActive }) =>
            `nav-link text-white ${isActive ? "active" : undefined}`
          }
          onClick={() => setActiveMenu(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-people pe-none me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <span>Client</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/socialmedia-idea"
          className={({ isActive }) =>
            `nav-link text-white ${isActive ? "active" : undefined}`
          }
          onClick={() => setActiveMenu(false)}
        >
          <MdDashboard className="bi bi-people pe-none me-2" />
          <span>Social Media Post</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/meal-planing"
          className={({ isActive }) =>
            `nav-link text-white ${isActive ? "active" : undefined}`
          }
          onClick={() => setActiveMenu(false)}
        >
          <GiMeal className="bi bi-people pe-none me-2" />
          <span>Meal Planing</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/pricing"
          className={({ isActive }) =>
            `nav-link text-white ${isActive ? "active" : undefined}`
          }
          onClick={() => setActiveMenu(false)}
        >
          <ImPriceTags className="bi bi-people pe-none me-2" />
          <span>Pricing Plan</span>
        </NavLink>
      </li>
    </>
  );
};

const SuperAdminNavs = () => {
  const { activeMenu, setActiveMenu } = React.useContext(SidebarContext);
  const { width } = useWindowSize();
  return (
    <>
      <li>
        <NavLink
          to="/user"
          className={({ isActive }) =>
            `nav-link text-white ${isActive ? "active" : undefined}`
          }
          onClick={() => setActiveMenu(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-people pe-none me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <span>Trainer</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/user-plan"
          className={({ isActive }) =>
            `nav-link text-white ${isActive ? "active" : undefined}`
          }
          onClick={() => setActiveMenu(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-people pe-none me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <span>User Plan</span>
        </NavLink>
      </li>
    </>
  );
};
