import React, { useEffect } from "react";
import Avatar from "./Avatar";
import { timeAgo } from "../../../Utility/utility";

const ChatItem = ({ role, msg, scrollToBottom, time }) => {
  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <div
      style={{ animationDelay: `0.8s` }}
      className={`chat__item ${role ? role : ""}`}
    >
      <div className="chat__item__content">
        <div className="chat__msg">{msg}</div>

        <div className="chat__meta">
          <span> {timeAgo(time)}</span>
          {/* <span>Seen 1.03PM</span> */}
        </div>
      </div>
      <Avatar
        isOnline="active"
        image={
          role === "assistant"
            ? process.env.PUBLIC_URL + "/favicon.png"
            : "https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
        }
      />
    </div>
  );
};

export default ChatItem;
