import React from "react";
import Navbar from "../Header/Header";
import Footer from "../footer/footer";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { ROUTESCONSTANTS } from "../../../constants/Routes";

const GeneralLayout = ({ children }) => {
  const location = useLocation();
  const isDashboardPath = location.pathname.startsWith(
    ROUTESCONSTANTS.dashboard
  );
  return (
    <>
      <Navbar />
      <Sidebar />
      <main className={isDashboardPath ? " dashboard-main " : " "}>
        {children}
      </main>
      <Footer />
    </>
  );
};

export default GeneralLayout;
