import React from 'react';

const TextField = ({ title, ...attributes }) => {
  return (
    <div>
      <label className="single-input-inner style-bg-border">
        <input
          type="text"
          {...attributes}
        />
      </label>
    </div>
  )
}

export default TextField;
