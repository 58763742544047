import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FullScreenLoader from "../../Common/Loader/FullScreenLoader";
import SelectBox from "../../Common/SelectBox";
import useRemaining from "../../Hooks/useRemaining";
import appendNewToName from "../../Utility/appendNewToName";
import downloadPhoto from "../../Utility/downloadPhoto";
import { getLogedInUser } from "../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { logout } from "../../service";
import LoadingComp from "./LoadingComp";
import OriginalPhoto from "./OriginalPhoto";
import StepedLabel from "./StepedLabel";
import UploadDropZone from "./UploadDropZone";
import { unlimitedCreditEmails } from "../../config/unlimitedCreditEmails";

const themeOptions = [
  { value: "Modern", label: "Modern" },
  { value: "Vintage", label: "Vintage" },
  { value: "Minimalist", label: "Minimalist" },
  { value: "Professional", label: "Professional" },
  { value: "Tropical", label: "Tropical" },
  { value: "Industrial", label: "Industrial" },
  { value: "Neoclassic", label: "Neoclassic" },
];

const roomOptions = [
  { value: "Living Room", label: "Living Room" },
  { value: "Dining Room", label: "Dining Room" },
  { value: "Bedroom", label: "Bedroom" },
  { value: "Bathroom", label: "Bathroom" },
  { value: "Office", label: "Office" },
  { value: "Kitchen", label: "Kitchen" },
  { value: "Basement", label: "Basement" },
  { value: "Outdoor Patio", label: "Outdoor Patio" },
  { value: "Gaming Room", label: "Gaming Room" },
];

const RoomGeneration = () => {
  const logedInUser = getLogedInUser();
  const navigate = useNavigate();
  const [theme, setTheme] = useState(themeOptions[0]);
  const [room, setRoom] = useState(roomOptions[0]);
  const [uploading, setUploading] = useState(false); // for design initialy false
  const [savingRooms, setSavingRooms] = useState(false);
  const [savedRooms, setSavedRooms] = useState(false);
  const [sideBySide, setSideBySide] = useState(false);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const [generatedPhoto, setGeneratedPhoto] = useState(null);
  const [photoName, setPhotoName] = useState(null);
  const [generatedResponse, setGeneratedResponse] = useState(null);
  const [regeneratBtn, setRegeneratBtn] = useState(false);
  const [regeneratUrl, setRegeneratUrl] = useState(null);
  const { remaining, setRemaining } = useRemaining();

  const generateRoom = async (url) => {
    setRegeneratBtn(false);
    setRegeneratUrl(null);
    await new Promise((resolve) => setTimeout(resolve, 200));
    const requestPayload = {
      imageUrl: url,
      theme: theme.value,
      room: room.value,
    };
    try {
      const response = await axiosSecure.post(
        "/generate-room",
        requestPayload,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );

      if (response.data.generated) {
        // console.log(response.data);
        setGeneratedPhoto(response.data.generated);
        setGeneratedResponse(response.data);
        setRemaining((prev) => ({
          ...prev,
          roomRemaining: prev?.roomRemaining - 1,
        }));
      } else {
        toast.error("Failed to generate photo");
      }
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(0);
      }
      if (err.response.status === 500) {
        setRegeneratBtn(true);
        setRegeneratUrl(url);
      }
      toast.error(err?.response?.data?.message);
    }
  };

  const handleFileUpload = async (image) => {
    if (regeneratBtn) setRegeneratBtn(false);
    if (regeneratUrl) setRegeneratUrl(null);
    setUploading(true);
    const formData = new FormData();
    formData.append("file", image);
    try {
      const response = await axiosSecure.post("/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: getAuthorizationHeader(),
        },
      });

      setPhotoName(image.name);
      setOriginalPhoto(response.data.imgUrl);
      generateRoom(response.data.imgUrl);
      // toast.success(response?.data?.message);
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleSaveRoom = async () => {
    setSavingRooms(true);
    if (!generatedResponse) return;
    try {
      const response = await axiosSecure.post("/save-room", generatedResponse, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      setSavedRooms(true);
      toast.success(response?.data?.message);
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    } finally {
      setSavingRooms(false);
    }
  };

  return (
    <section className="room-generation-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title mb-4 text-center">
              {logedInUser ? (
                <h6>
                  {unlimitedCreditEmails.includes(logedInUser.email) ? (
                    "You have an unlimited amount of credit available."
                  ) : (
                    <>
                      You have {remaining?.roomRemaining} credit left. Buy more
                      credits{" "}
                      <Link
                        to={ROUTESCONSTANTS.buyCredit}
                        className="text-blue-theme"
                      >
                        here
                      </Link>
                      .
                    </>
                  )}
                </h6>
              ) : (
                <h6>Over 1 million users have used RealEstate AI so far.</h6>
              )}
              <h2>Generate your dream room</h2>
              {!logedInUser ? (
                <div className=" row justify-content-center ">
                  <div className="room-g-signin-text col-xl-8 col-lg-9 text-center">
                    <p>
                      Enjoy the benefits of a free account and unlock the
                      opportunity to transform your space. Sign up now and
                      receive 3 credits to create stunning room images and
                      captivating social media posts.
                    </p>
                    <p className="mb-3 fw-medium">
                      If you’re already a member, simply sign in here.
                    </p>
                    <Link
                      className="btn btn-base d-inline-flex align-items-center"
                      to={ROUTESCONSTANTS.signin}
                    >
                      <span className="btn-text">Sign in</span>
                    </Link>
                  </div>
                </div>
              ) : !uploading && !originalPhoto ? (
                <>
                  <div className="generation-steps m-auto mt-4">
                    <StepedLabel step={1} label="Choose your room theme." />
                    <SelectBox
                      defaultValue={theme}
                      onChange={setTheme}
                      options={themeOptions}
                      className="basic-single mb-5"
                      classNamePrefix="select"
                    />
                    <StepedLabel step={2} label="Choose your room type." />
                    <SelectBox
                      defaultValue={room}
                      onChange={setRoom}
                      options={roomOptions}
                      className="basic-single mb-5"
                      classNamePrefix="select"
                    />
                    <StepedLabel
                      step={3}
                      label="Upload a picture of your room."
                    />
                  </div>
                  <UploadDropZone onUpload={handleFileUpload} />
                </>
              ) : (
                <></>
              )}

              {uploading && (
                <div className="row justify-content-center mt-5">
                  <div className="col-lg-6">
                    <LoadingComp />
                  </div>
                </div>
              )}

              {savingRooms && <FullScreenLoader />}

              {!uploading && originalPhoto && !generatedPhoto && (
                <div className="row justify-content-center mt-5">
                  <div className="col-lg-6">
                    <OriginalPhoto imgUrl={originalPhoto} />
                  </div>
                </div>
              )}
              {/* after generated */}
              {generatedPhoto && (
                <>
                  <div className="mt-3 mb-2">
                    Here's your remodeled <b>{room.label.toLowerCase()}</b> in
                    the <b>{theme.label.toLowerCase()}</b> theme!{" "}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Form>
                      <div className="d-flex align-items-center">
                        <span className="me-2">Compare Side by Side</span>
                        <Form.Check
                          type="switch"
                          id="toggle-switch"
                          label=""
                          checked={sideBySide}
                          onChange={() => setSideBySide(!sideBySide)}
                        />
                        <span className="ms-2">
                          {sideBySide ? "On" : "Off"}
                        </span>
                      </div>
                    </Form>
                  </div>
                </>
              )}
            </div>

            {/* after upload */}

            {/* after generated */}
            {originalPhoto && generatedPhoto && !sideBySide && (
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <article className="product-case">
                    <div className="room-cover">
                      <img src={originalPhoto} alt="Original room" />
                    </div>
                    <header className="py-3 text-center">
                      <h5 className="mb-0">Original Room</h5>
                    </header>
                  </article>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <article className="product-case mt-5 mt-md-0">
                    <div className="room-cover">
                      <img src={generatedPhoto} alt="Generated room" />
                    </div>
                    <header className="py-3 text-center">
                      <h5 className="mb-0">Generated Room</h5>
                    </header>
                  </article>
                </div>
              </div>
            )}
            {/* after generated Compare slider */}
            {generatedPhoto && sideBySide && (
              <div className="d-flex justify-content-center mt-4">
                <ReactCompareSlider
                  style={{ borderRadius: "8px" }}
                  itemOne={
                    <ReactCompareSliderImage
                      src={originalPhoto}
                      alt="original photo"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src={generatedPhoto}
                      alt="generated photo"
                    />
                  }
                  portrait
                />
              </div>
            )}
            {/* after generated buttons */}
            {generatedPhoto ? (
              <div className="mt-4 align-items-center justify-content-center hero-btn-wrapper w-100">
                <button
                  className="btn btn-base shadow d-inline-flex rounded-2 align-items-center"
                  onClick={() => {
                    // console.log(photoName);
                    downloadPhoto(generatedPhoto, appendNewToName(photoName));
                  }}
                >
                  <span className="btn-text">Download Generated Room</span>
                </button>
                <button
                  className="btn btn-white rounded-2 saveRoomButton shadow d-inline-flex align-items-center"
                  onClick={handleSaveRoom}
                  disabled={savedRooms}
                >
                  <span className="btn-text">
                    {savedRooms ? "Saved" : "Save Room"}
                  </span>
                </button>
                <button
                  className="btn btn-blue shadow rounded-2 d-inline-flex align-items-center"
                  onClick={() => {
                    if (savedRooms) {
                      setSavedRooms(false);
                    }
                    setOriginalPhoto(null);
                    setGeneratedPhoto(null);
                    setGeneratedResponse(null);
                    setRegeneratBtn(false);
                    setRegeneratUrl(null);
                  }}
                >
                  <span className="btn-text">Generate New room</span>
                </button>
              </div>
            ) : null}
            {regeneratBtn && regeneratUrl && (
              <div className="mt-4 align-items-center justify-content-center hero-btn-wrapper w-100">
                <button
                  className="btn btn-white shadow rounded-2 d-inline-flex align-items-center"
                  onClick={() => {
                    generateRoom(regeneratUrl);
                  }}
                >
                  <span className="btn-text">Regenerate Room</span>
                </button>
                <button
                  className="btn btn-blue shadow rounded-2 d-inline-flex align-items-center"
                  onClick={() => {
                    setOriginalPhoto(null);
                    setGeneratedPhoto(null);
                    setGeneratedResponse(null);
                    setRegeneratBtn(false);
                    setRegeneratUrl(null);
                  }}
                >
                  <span className="btn-text">Generate New room</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoomGeneration;
