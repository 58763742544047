import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { getUserDetails } from "../../../service";
import SubscriptionCancleModal from "../../Pricing/SubscriptionCancleModal";

const ActivePlanCard = ({ activePlan }) => {
  const [modalShow, setModalShow] = useState(false);
  const logedinUser = getUserDetails();  

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <div className="card h-100 shadow-lg active-card">
      <div className="card-body">
        <div className="text-center p-2">
          <h5 className="card-title pt-4">{activePlan.planName}</h5>
          <p className="d-flex align-items-center justify-content-center gap-1 my-3">
            <span className="fs-2 fw-bold text-dark">${activePlan.price}</span>
            <span className="fs-6 fw-medium">/month</span>
          </p>
        </div>
        <hr className="mt-4" />
      </div>
      <div className="d-flex justify-content-center align-items-start">
        <ul className="list-group list-group-flush">
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Biography</span>
            <span>{activePlan?.numberOfBiography}</span>
          </li>
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Blog Post</span>
            <span>{activePlan?.numberOfBlogPost}</span>
          </li>
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Email Campaign</span>
            <span>{activePlan?.numberOfEmailCampaign}</span>
          </li>

          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Improve Content</span>
            <span>{activePlan?.numberOfImproveContent}</span>
          </li>
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Instagram Quotes</span>
            <span>{activePlan?.numberOfInstagramQuotes}</span>
          </li>

          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Leads Generation</span>
            <span>{activePlan?.numberOfLeadsGeneration}</span>
          </li>
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Newsletter Campaign</span>
            <span>{activePlan?.numberOfNewsletterCampaign}</span>
          </li>

          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Room</span>
            <span>{activePlan?.numberOfRoom}</span>
          </li>
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Social Media Content</span>
            <span>{activePlan?.numberOfSocialMediaContent}</span>
          </li>
          <li className="pb-2 d-flex align-items-center gap-2">
            <BsFillCheckCircleFill className="price-description-icon fs-6" />
            <span>No. of Step By Step Guide</span>
            <span>{activePlan?.numberOfStepByStepGuide}</span>
          </li>
        </ul>
      </div>
      <div className="card-body text-center align-items-end justify-content-center hero-btn-wrapper">
        {parseInt(activePlan.price) === 0 ? (
          <Button
            variant="success"
            className="btn btn-base rounded-pill px-4 my-3"
            disabled
          >
            Activated
          </Button>
        ) : logedinUser?.user?.subscriptionId ? (
          <Button
            variant="warning"
            className="btn px-4 my-3 rounded-pill"
            onClick={() => setModalShow(true)}
            disabled={!logedinUser?.user?.subscriptionId}
          >
            Unsubscribe
          </Button>
        ) : (
          <form
            action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
            method="POST"
          >
            <input
              type="hidden"
              name="priceId"
              value={activePlan?.stripePriceKey}
            />
            <input type="hidden" name="planId" value={activePlan?._id} />
            <Button
              variant="success"
              className="btn btn-base rounded-pill px-4 my-3"
              type="submit"
            >
              Purchase Again
            </Button>
          </form>
        )}
        <p className="currentPlan">Activated</p>
      </div>

      <Modal
        scrollable={true}
        show={modalShow}
        onHide={handleCloseModal}
        centered
        responsive
      >
        <SubscriptionCancleModal handleCloseModal={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default ActivePlanCard;
