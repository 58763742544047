import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AiOutlineHome,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import {
  BiBookContent,
  BiHomeAlt,
  BiObjectsVerticalBottom,
} from "react-icons/bi";
import { BsFilePost } from "react-icons/bs";
import { GiRegeneration } from "react-icons/gi";
import { MdOutlineCampaign, MdOutlineSocialDistance } from "react-icons/md";
import { RiGuideFill } from "react-icons/ri";
import { toast } from "react-toastify";
import UsagePlan from "../../Common/UsagePlan";
import useRemaining from "../../Hooks/useRemaining";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import { getUserDetails } from "../../service";
import ActivePlanCard from "./ActivePlan";
import { unlimitedCreditEmails } from "../../config/unlimitedCreditEmails";

const DashboardInfo = () => {
  const { remaining } = useRemaining();
  const logedinUser = getUserDetails();
  const activePlanId = logedinUser?.user?.activePlanId;
  const [activePlan, setActivePlan] = useState({});
  const [dashboardInfo, setDashboardInfo] = useState({});

  const handleChoosePlan = (index) => {
    setActivePlan(index);
  };

  function calculatePercentage(total, number) {
    const used = total - number;
    const percentage = (parseInt(used) / parseInt(total)) * 100;
    return Math.round(percentage);
  }

  // Get All Dashboard Information
  useEffect(() => {
    const controller = new AbortController();
    const fetchDashboardData = async () => {
      try {
        const response = await axiosSecure.get("/usage-dashboard", {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setDashboardInfo(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };
    fetchDashboardData();

    return () => controller && controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      try {
        const response = await axiosSecure.get(`/user-plan/${activePlanId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setActivePlan(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, [activePlanId]);

  return (
    <div className="dashboard-page-area">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center card">
          <div className="p-4">
            <h3 className="p-2">Total Usages All Time</h3>
            <Row className="p-2">
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Total Generated Rooms"
                  data={dashboardInfo?.totalGeneratedRooms}
                  icon={<AiOutlineHome />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Total Saved Room"
                  data={dashboardInfo?.totalSavedRoom}
                  icon={<BiHomeAlt />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Social Media Content"
                  data={dashboardInfo?.totalSocialMediaContent}
                  icon={<MdOutlineSocialDistance />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Saved Media Content"
                  data={dashboardInfo?.totalSavedSocialMediaContent}
                  icon={<MdOutlineSocialDistance />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Newsletter Campaign"
                  data={dashboardInfo?.totalNewsletterCampaign}
                  icon={<MdOutlineCampaign />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Email Campaign"
                  data={dashboardInfo?.totalEmailCampaign}
                  icon={<AiOutlineMail />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Instagram Quotes"
                  data={dashboardInfo?.totalInstagramQuotes}
                  icon={<AiOutlineInstagram />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Leads Generation"
                  data={dashboardInfo?.totalLeadsGeneration}
                  icon={<GiRegeneration />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Improve Content"
                  data={dashboardInfo?.totalImproveContent}
                  icon={<BiBookContent />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Step By Step Guide"
                  data={dashboardInfo?.totalStepByStepGuide}
                  icon={<RiGuideFill />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="No. Of Biography"
                  data={dashboardInfo?.totalBiography}
                  icon={<BiObjectsVerticalBottom />}
                />
              </Col>
              <Col xxl={4} lg={6} md={12} className="single-card">
                <UsagePlan
                  title="Blog Post"
                  data={dashboardInfo?.totalBlogPost}
                  icon={<BsFilePost />}
                />
              </Col>
            </Row>
          </div>

          {/* Avtive Plan */}
          <div className="p-4">
            <Row className="p-2">
              <Col xl={6} lg={6} md={12}>
                <h3 className="card-title p-2">Activate Plans</h3>
                <div>
                  <ActivePlanCard
                    activePlan={activePlan}
                    handleChoosePlan={handleChoosePlan}
                  />
                </div>
              </Col>

              <Col xl={6} lg={6} md={12}>
                <h3 className="card-title p-2">Remaining Plans</h3>
                <div className="remaining-plans-card">
                  <div className="card-body">
                    <div className="remaining-scrollbar">
                      <div className="row g-2">
                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#009EF7" }}>Number Of Room</h5>
                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of room remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.roomRemaining}/
                                {activePlan?.numberOfRoom} Number Of Room
                                Remaining
                              </p>
                            )}

                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#78CCFB" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfRoom,
                                    remaining?.roomRemaining
                                  )}%`,
                                  backgroundColor: "#009EF7",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#FFC700" }}>
                              Number Of Biography
                            </h5>
                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of biography remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.biographyRemaining}/
                                {activePlan?.numberOfBiography} Number Of
                                Biography Remaining
                              </p>
                            )}

                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#FFE06E" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfBiography,
                                    remaining?.biographyRemaining
                                  )}%`,
                                  backgroundColor: "#FFC700",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#5BA600" }}>
                              Social Media Content
                            </h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of media content remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.socialMediaContentRemaining}/
                                {activePlan?.numberOfSocialMediaContent} Social
                                Media Content Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#57C5B6" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfSocialMediaContent,
                                    remaining?.socialMediaContentRemaining
                                  )}%`,
                                  backgroundColor: "#5BA600",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#19A7CE" }}>Blog Post</h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of blog posts remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.blogPostRemaining}/
                                {activePlan?.numberOfBlogPost} Blog Post
                                Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#62CDFF" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfBlogPost,
                                    remaining?.blogPostRemaining
                                  )}%`,
                                  backgroundColor: "#19A7CE",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#2D2727" }}>
                              Newsletter Campaign
                            </h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of newsletter campaign
                                remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.newsletterCampaignRemaining}/
                                {activePlan?.numberOfNewsletterCampaign}{" "}
                                Newsletter Campaign Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#413543" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfNewsletterCampaign,
                                    remaining?.newsletterCampaignRemaining
                                  )}%`,
                                  backgroundColor: "#2D2727",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#2F58CD" }}>Email Campaign</h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of email campaign remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.emailCampaignRemaining}/
                                {activePlan?.numberOfEmailCampaign} Email
                                Campaign Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#3795BD" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfEmailCampaign,
                                    remaining?.emailCampaignRemaining
                                  )}%`,
                                  backgroundColor: "#2F58CD",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#F14D47" }}>
                              Instagram Quotes
                            </h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of Instagram quotes remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.instagramQuotesRemaining}/
                                {activePlan?.numberOfInstagramQuotes} Instagram
                                Quotes Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#F48484" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfInstagramQuotes,
                                    remaining?.instagramQuotesRemaining
                                  )}%`,
                                  backgroundColor: "#F14D47",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#205295" }}>
                              Leads Generation
                            </h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of leads generation remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.leadsGenerationRemaining}/
                                {activePlan?.numberOfLeadsGeneration} Leads
                                Generation Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#2C74B3" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfLeadsGeneration,
                                    remaining?.leadsGenerationRemaining
                                  )}%`,
                                  backgroundColor: "#205295",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 single-plan">
                          <div className="card p-3">
                            <h5 style={{ color: "#A555EC" }}>
                              Improve Content
                            </h5>

                            {unlimitedCreditEmails.includes(
                              logedinUser?.user?.email
                            ) ? (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                Unlimited amount of improve content remaining
                              </p>
                            ) : (
                              <p className="fw-medium text-muted mt-0 fs-7">
                                {remaining?.improveContentRemaining}/
                                {activePlan?.numberOfImproveContent} Improve
                                Content Remaining
                              </p>
                            )}
                            <div
                              className="progress h-7px mt-7"
                              style={{ backgroundColor: "#D09CFA" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${calculatePercentage(
                                    activePlan?.numberOfImproveContent,
                                    remaining?.improveContentRemaining
                                  )}%`,
                                  backgroundColor: "#A555EC",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardInfo;
