import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import WarningModal from "../../component/Pricing/WorningModal";
import { getUserDetails } from "../../service/service";
import PricingCard from "./pricing-card";

function Pricing() {
  // const [openModal, setOpenModal] = useState(false);
  const [activePlan, setActivePlan] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [userPlans, setUserPlans] = useState([]);
  const logedinUser = getUserDetails();

  const handleChoosePlan = (index) => {
    setActivePlan(index);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      try {
        // setDataLoader(true);
        const response = await axiosSecure.get(`/user-plans`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        console.log(response?.data?.data)
        setUserPlans(response?.data?.data);
        // setCurrentPage(Number(response?.data.paginationData?.currentPage));
        // setTotalPlanCount(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        // setDataLoader(false);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, []);

  return (
    <>
      <PageHeader />
      <section className="pricing-plan my-4">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="section-title">
                <h6 className="text-center">Pricing</h6>
                <h2 className="text-center">We offer competitive price</h2>
                <p className="text-center">
                  Choose an affordable plan that’s packed with the best features
                  for engaging <br />
                  your audience, creating customer loyalty, and driving sales.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            {userPlans.map((plan, index) => (
              <PricingCard
                key={index}
                plan={plan}
                handleOpenModal={handleOpenModal}
                isActive={logedinUser?.user?.activePlanId === plan?._id}
                logedinUser={logedinUser?.user}
              />
            ))}
          </div>
        </div>

        <Modal
          scrollable={true}
          show={activePlan === 1 || showModal}
          onHide={handleCloseModal}
          centered
        >
          <WarningModal
            handleCloseModal={handleCloseModal}
            handleChoosePlan={handleChoosePlan}
          />
        </Modal>
      </section>
    </>
  );
}

export default Pricing;
