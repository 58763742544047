import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import AssistantListItem from "./AssistantListItem";
import "./ChatList.scss";
import CreateAssistantModal from "./DeleteModal/CreateAssistant";
import DeleteAssistantModal from "./DeleteModal/DeleteAssistant";
import DeleteThreadModal from "./DeleteModal/DeleteThread";
import UpdateThreadModal from "./DeleteModal/UpdateThread";
import ListItem from "./ListItem";

const initialState = {
  name: "",
  instructions: "",
  tools: [],
  model: "",
  files: [],
};

const ChatList = ({
  setThreadId,
  threadId,
  type,
  allThreadsList,
  setAllThreadsList,
  allAssistant,
  setAllAssistant,
  setAllMessages,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [activeItem, setActiveItem] = useState(0);
  const [activeAssistant, setActiveAssistant] = useState(0);

  const [delAssistantId, setDelAssistantId] = useState(null);
  const [deleteThreadId, setDeleteThreadId] = useState(null);

  const [showAssistantDeleteModal, setShowAssistantDeleteModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Update Thread Functionalities start
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedThreadId, setSelectedThreadId] = useState(null);
  const [updatedThreadName, setUpdatedThreadName] = useState("");

  const handleUpdateThread = (thread_Id, threadName) => {
    setSelectedThreadId(thread_Id);
    setUpdatedThreadName(threadName);
    setShowUpdateModal(true);
  };

  const handleUpdateModalClosed = () => {
    setShowUpdateModal(false);
    setSelectedThreadId(null);
  };

  const handleUpdateThreadName = async () => {
    const updateThread = allThreadsList?.map((thread) => {
      if (thread._id === selectedThreadId) {
        return { ...thread, name: updatedThreadName };
      }
      return thread;
    });

    setAllThreadsList(updateThread);
    try {
      const payload = {
        name: updatedThreadName,
      };
      const response = await axiosSecure.patch(
        `/assistants/threads/update/${selectedThreadId}`,
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      toast.success(response?.data?.message);
    } catch (error) {
      console.error("Error updating thread:", error);
    }

    setShowUpdateModal(false);
    setSelectedThreadId(null);
    setUpdatedThreadName("");
  };
  // Update Thread Functionalities End

  // Delete Assistant Functionalities
  const deleteAssistantHandler = async () => {
    try {
      // setIsThreadLoading(true);

      const response = await axiosSecure.delete(
        `/assistants/delete/${delAssistantId}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      const responseData = response?.data;
      toast.success(responseData?.message);
      setAllAssistant((prevState) => {
        return prevState.filter((item) => item?._id !== delAssistantId);
      });
      // setActiveAssistant(0);

      handleClose();
    } catch (error) {
      console.error("Error while fetching data:", error);
    } finally {
      setDelAssistantId(null);
    }
    setShowAssistantDeleteModal(false);
  };

  // Delete Thread Functionalities
  const deleteThreadHandler = async () => {
    try {
      const response = await axiosSecure.delete(
        `/assistants/threads/delete/${deleteThreadId}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      const responseData = response?.data;
      toast.success(responseData?.message);
      setAllThreadsList((prevState) => {
        return prevState.filter((item) => item?._id !== deleteThreadId);
      });
      // Clear thread id and message
      setThreadId("");
      setAllMessages([]);
      handleClose();
    } catch (error) {
      toast.error(error);
    } finally {
      setDeleteThreadId(null);
    }
    setShowDeleteModal(false);
  };

  // Handle Delete Assistant Click
  const handleAssistantDeleteClick = (assistantId) => {
    setShowAssistantDeleteModal(true);
    setDelAssistantId(assistantId);
  };

  // Handle Delete Thread Click
  const handleThreadDeleteClick = (threadId) => {
    setShowDeleteModal(true);
    setDeleteThreadId(threadId);
  };

  const handleThreatClick = (thread, i) => {
    setThreadId(thread?.thread_id);
    setActiveItem(i);
  };

  const handleAssistantClick = (assistant, i) => {
    setActiveAssistant(i);
    navigate(`/assistants/${assistant?.assistant_id}`);
  };

  const newConversationHandler = () => {
    setThreadId("");
    setAllMessages([]);
  };

  // Create Assistant Functionalities Start
  const [showToast, setShowToast] = useState(false);
  const [assistantData, setAssistantData] = useState(initialState);
  const [showFileLimitMessage, setShowFileLimitMessage] = useState(false);
  const [showCreateAssistantModal, setShowCreateAssistantModal] =
    useState(false);
  const handleCloseCreateModal = () => setShowCreateAssistantModal(false);
  const handleShowCreateModal = () => setShowCreateAssistantModal(true);

  // handle file changes;
  const handleFileChange = (e) => {
    const files = e.target.files;

    if (!assistantData || !assistantData.files) {
      // Handle the case where assistantData or assistantData.files is undefined
      return;
    }

    const totalFileSize = assistantData.files.reduce(
      (total, file) => total + file?.size,
      0
    );

    if (
      files.length + assistantData.files.length > 5 ||
      totalFileSize > 50 * 1024 * 1024
    ) {
      setShowFileLimitMessage(true);
      setShowToast(true);
    } else {
      setShowFileLimitMessage(false);
      setShowToast(false);
      setAssistantData((prevData) => ({
        ...prevData,
        files: [...prevData.files, ...files],
      }));
      // e.target.value = null; // Clear the file input field
    }
  };

  // Handle Tools Change
  const handleToolChange = (selectedTool) => {
    setAssistantData((prevData) => {
      const toolsArray = prevData?.tools || [];
      const findItem = toolsArray.find((item) => item.type === selectedTool);
      if (findItem) {
        // If tools is already selected, remove it
        return {
          ...prevData,
          tools: toolsArray.filter((tool) => tool.type !== selectedTool),
        };
      } else {
        // If tools is not selected, add it
        return {
          ...prevData,
          tools: [...toolsArray, { type: selectedTool }],
        };
      }
    });
  };

  // Remove file from files array
  const removeFile = (index) => {
    setAssistantData((prevData) => {
      const updatedFiles = [...prevData.files];
      updatedFiles.splice(index, 1);
      const totalFileSize = updatedFiles.reduce(
        (total, file) => total + file?.size,
        0
      );
      if (updatedFiles.length <= 5 && totalFileSize <= 50 * 1024 * 1024) {
        setShowFileLimitMessage(false);
        setShowToast(false);
      }
      return { ...prevData, files: updatedFiles };
    });
  };

  // Format file size
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // Handle Create Assistant
  const handleCreateAssistant = async () => {
    try {
      setShowToast(true);
      const formData = new FormData();
      formData.append("name", assistantData.name);
      formData.append("instructions", assistantData.instructions);
      formData.append("model", assistantData.model);
      if (assistantData.tools) {
        formData.append("tools", JSON.stringify(assistantData.tools));
      }
      (assistantData.files || []).forEach((file) => {
        formData.append("files", file);
      });

      const response = await axiosSecure.post(
        "/assistants/createassistant",
        formData,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      toast.success(response?.data?.message);

      if (response.data?.assistant) {
        setAllAssistant((prevData) => {
          const temp = JSON.parse(JSON.stringify(prevData));
          temp.unshift(response.data?.assistant);
          return temp;
        });
      }
      setAssistantData(initialState);
      navigate(`/assistants/${response.data?.assistant?.assistant_id}`);
      setActiveAssistant(0);
      handleCloseCreateModal();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div className="main__chatlist">
        <div className="chatlist__heading">
          {/* <div className="search_wrap">
            <input type="text" placeholder="Search Here" required />
            <button className="search-btn">
              <IoIosSearch />
            </button>
          </div> */}

          <div className="conversation__btn">
            <div onClick={() => newConversationHandler()} className="new__chat">
              <img
                src={process.env.PUBLIC_URL + "/favicon.png"}
                alt="logo"
                className="chat__logo"
              />
              <span>New Chat</span>
            </div>
          </div>
          <div className="thread__lists">
            <ul className="thread__item">
              {allThreadsList?.map((thread, i) => (
                <ListItem
                  handleThreatClick={handleThreatClick}
                  key={i}
                  i={i}
                  thread={thread}
                  setActiveItem={setActiveItem}
                  activeItem={activeItem}
                  handleThreadDeleteClick={handleThreadDeleteClick}
                  handleUpdateThread={handleUpdateThread}
                />
              ))}
            </ul>
          </div>
        </div>
        {/* <hr /> */}
        <div className="chatList__search">
          <h6>All Assistant</h6>
          {/* Start Render All Assistant */}
          <div className="thread__lists">
            <ul className="thread__item">
              {allAssistant?.map((assistant, i) => (
                <AssistantListItem
                  assistant={assistant}
                  key={i}
                  i={i}
                  activeAssistant={activeAssistant}
                  handleAssistantClick={handleAssistantClick}
                  handleAssistantDeleteClick={handleAssistantDeleteClick}
                />
              ))}
            </ul>
          </div>
          {/* End Render All Assistant */}

          <div className="chatlist__items">
            <div className="chatlist__item" onClick={handleShowCreateModal}>
              <div className="create__btn">Create Assistant</div>
            </div>
          </div>
        </div>
      </div>
      {/* 


      {/* Delete Assistant Confirm Modal */}
      <DeleteAssistantModal
        show={showAssistantDeleteModal}
        onHide={() => setShowAssistantDeleteModal(false)}
        onDelete={deleteAssistantHandler}
        itemName={`Are you sure delete this Assistant?`}
      />
      {/* Delete Thread Confirm Modal */}
      <DeleteThreadModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={deleteThreadHandler}
        itemName={`Are you sure delete this Thread?`}
      />
      <CreateAssistantModal
        showCreateAssistantModal={showCreateAssistantModal}
        handleCloseCreateModal={handleCloseCreateModal}
        handleShowCreateModal={handleShowCreateModal}
        assistantData={assistantData}
        setAssistantData={setAssistantData}
        handleToolChange={handleToolChange}
        handleFileChange={handleFileChange}
        removeFile={removeFile}
        formatBytes={formatBytes}
        showFileLimitMessage={showFileLimitMessage}
        handleCreateAssistant={handleCreateAssistant}
      />

      <UpdateThreadModal
        allThreadsList={allThreadsList}
        selectedThreadId={selectedThreadId}
        showUpdateModal={showUpdateModal}
        handleUpdateThreadName={handleUpdateThreadName}
        handleUpdateModalClosed={handleUpdateModalClosed}
        updatedThreadName={updatedThreadName}
        setUpdatedThreadName={setUpdatedThreadName}
      />
    </>
  );
};

export default ChatList;
