import React from 'react'
import UpdateProfileForm from './UpdateProfileForm';

const UpdateProfile = () => {
    const { user } = JSON.parse(localStorage.getItem('userDetails'));
    return user && user ? (
        <UpdateProfileForm defaultValue={user} />
    ) : (
        <></>
    )
}

export default UpdateProfile;
