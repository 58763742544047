// @flow strict

import * as React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../Utility/utility";
import PaginationComponent from "../Pagination/Pagination";

function UserListUI({
  clientList,
  totalClientCount,
  limit,
  currentPage,
  handlePagination,
  handleChangeUserActivity,
  handleAssistantPermission,
}) {
  const navigate = useNavigate();
  console.log("clientList", clientList); //assistantPermission
  return (
    <div className="user-list-container">
      {clientList && clientList?.length > 0 ? (
        <div>
          <Table bordered responsive>
            <thead>
              <tr>
                <th className="text-start text-uppercase fw-semibold">
                  Full Name
                </th>
                <th className="text-start text-uppercase fw-semibold">Email</th>
                <th className="text-start text-uppercase fw-semibold">
                  Created At
                </th>
                <th className="text-center text-uppercase fw-semibold">
                  User Status
                </th>

                <th className="text-center text-uppercase fw-semibold">
                  Profile View
                </th>

                <th className="text-center text-uppercase fw-semibold">
                  Assistant Permission
                </th>
              </tr>
            </thead>
            <tbody>
              {clientList.map((item, index) => (
                <tr key={index}>
                  <td className="text-start truncated-text">
                    <OverlayTrigger
                      placement="top-start"
                      overlay={
                        <Tooltip>
                          {item.firstName} {item.lastName}
                        </Tooltip>
                      }
                    >
                      <span>
                        {" "}
                        {item.firstName} {item.lastName}
                      </span>
                    </OverlayTrigger>
                  </td>

                  <td className="text-start truncated-text">
                    <OverlayTrigger
                      placement="top-start"
                      overlay={<Tooltip>{item.email}</Tooltip>}
                    >
                      <span> {item.email}</span>
                    </OverlayTrigger>
                  </td>

                  <td className="text-start"> {convertDate(item.createdAt)}</td>
                  <td className="text-center d-flex justify-content-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {item.isActive
                            ? "Account Active"
                            : "Account Inactive"}
                        </Tooltip>
                      }
                    >
                      <Form.Check
                        size="small"
                        type="switch"
                        checked={item.isActive}
                        onChange={(e) =>
                          handleChangeUserActivity(item._id, e.target.checked)
                        }
                        label=""
                      />
                    </OverlayTrigger>
                  </td>

                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Profile View</Tooltip>}
                    >
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        className="action-button"
                        onClick={() =>
                          navigate(`/dashboard/clients/${item._id}`)
                        }
                      >
                        View
                      </Button>
                    </OverlayTrigger>
                  </td>

                  {/* Permission Field */}

                  <td className="text-center d-flex justify-content-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          {item.assistantPermission
                            ? "Permission Active"
                            : "Permission Inactive"}
                        </Tooltip>
                      }
                    >
                      <Form.Check
                        size="small"
                        type="switch"
                        checked={item.assistantPermission}
                        onChange={(e) =>
                          handleAssistantPermission(item._id, e.target.checked)
                        }
                        label=""
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>User Type</th>
                  <th>Assistant Permission</th>
                  <th>CreatedAt</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody></tbody>
            </Table>
            <Card>
              <Card.Body>
                <Card.Title className="text-danger text-center">
                  No client data available
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
        <PaginationComponent
          total={totalClientCount}
          itemsPerPage={limit}
          currentPage={currentPage}
          onPageChange={(page) => handlePagination(page)}
        />
      </div>
    </div>
  );
}

export default UserListUI;
