import React from "react";
import "./ChatContent.scss";
import { FaRegPaperPlane } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import ChatItem from "./ChatItem";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { logout } from "../../../service";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useEffect } from "react";
import ChatList from "../ChatList";

const ChatContent = ({ threadId }) => {
  const [allMessages, setAllMessages] = useState(null || []);

  const [input, setInput] = useState("");
  // End Create Thread Functionalities
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      thread_id: threadId,
      user_input: input,
      type: "marketing",
    };

    try {
      setLoading(true);
      setInput("");
      scrollToBottom();
      const response = await axiosSecure.post(
        "/call-open-ai-assistant",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      const { message } = response?.data;
      setAllMessages((prevAllMsg) => [...prevAllMsg, message]);
      if (response?.data?.success) {
        try {
          const res = await axiosSecure.post("/get-message-response", payload, {
            headers: { Authorization: getAuthorizationHeader() },
          });

          const { message } = res?.data;
          setAllMessages((prevAllMsg) => [...prevAllMsg, message]);
        } catch (error) {
          if (error.response.status === 403) {
            logout();
            navigate(ROUTESCONSTANTS.signin, { replace: true });
          } else {
            toast.error(error?.response?.data?.message);
          }
        }
      }
    } catch (err) {
      if (err.response.status === 403) {
        logout();
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } else {
        toast.error(err?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axiosSecure.post(
          `/get-thread-messages`,
          {
            thread_id: threadId,
          },
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        setAllMessages(response?.data?.messages?.body?.data?.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (threadId) {
      fetchMessages();
    }
  }, [threadId]);

  return (
    <div className="main__chatcontent">
      <ChatList /> {/* Sidebar */}
      <div className="main__body">
        <div className="content__body">
          <div className="chat__items">
            {allMessages?.map((itm, index) => {
              return (
                <ChatItem
                  animationDelay={1}
                  key={itm?.id}
                  role={itm.role === "assistant" ? "assistant" : "me"}
                  time={itm?.created_at}
                  msg={itm.content[0].text.value || itm.msg}
                  scrollToBottom={scrollToBottom}
                />
              );
            })}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div>
            <div className="content__footer">
              <form onSubmit={handleSubmit} className="message__area">
                <div className="sendNewMessage">
                  <button className="addFiles">
                    <FaPlus />
                  </button>
                  <input
                    type="text"
                    placeholder="Type a message here"
                    onChange={(e) => setInput(e.target.value)}
                    value={input}
                  />
                  {loading ? (
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btnSendMsg"
                      id="sendMsgBtn"
                      disabled={loading}
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btnSendMsg"
                      id="sendMsgBtn"
                    >
                      <FaRegPaperPlane />
                    </button>
                  )}
                </div>
              </form>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContent;
