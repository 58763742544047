// @flow strict

import * as React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SubscriptionCancleModal from "./SubscriptionCancleModal";
import SubscriptionInfo from "./SubscriptionInfo";

function PricingCard({ plan, isActive, logedinUser }) {
  const [openModal, setOpenModal] = useState(false);
  const [beforePurchaseModal, setBeforePurchaseModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div
        className={
          isActive ? "card h-100 shadow-lg active-card" : "card h-100 shadow-lg"
        }
      >
        <div className="card-body">
          <div className="text-center">
            <h5 className="card-title pt-4">{plan.planName}</h5>
            <p className="d-flex align-items-center justify-content-center gap-1 my-3">
              <span className="fs-2 fw-bold text-dark">${plan.price}</span>
              <span className="fs-6 fw-medium">/month</span>
            </p>
          </div>
         
          <hr className="mt-4" />
        </div>
        <div className="d-flex justify-content-center align-items-start">
          <ul className="list-group list-group-flush">
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Biography: {plan?.numberOfBiography}</span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Blog: {plan?.numberOfBlogPost}</span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Email: {plan?.numberOfEmailCampaign}</span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Improve Content: {plan?.numberOfImproveContent}</span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Instagram Quotes: {plan?.numberOfInstagramQuotes}
              </span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Leed Generation: {plan?.numberOfLeadsGeneration}
              </span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Newsletter: {plan?.numberOfNewsletterCampaign}</span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>No of Room Generation: {plan?.numberOfRoom}</span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Property Details: {plan?.numberOfSocialMediaContent}
              </span>
            </li>
            <li className="pb-2 text-left">
              <BsFillCheckCircleFill className="price-description-icon fs-6 me-2" />
              <span>
                No of Step By Step Guide: {plan?.numberOfStepByStepGuide}
              </span>
            </li>
          </ul>
        </div>
        <div className="card-body text-center align-items-end justify-content-center hero-btn-wrapper">
          {isActive ? (
            <>
              {parseInt(plan.price) === 0 ? (
                <Button
                  variant="success"
                  className="btn btn-base rounded-pill px-4 my-3"
                  disabled
                >
                  Activated
                </Button>
              ) : logedinUser?.subscriptionId ? (
                <Button
                  variant="warning"
                  className="btn px-4 my-3 rounded-pill"
                  onClick={() => handleOpenModal()}
                  disabled={!logedinUser?.subscriptionId}
                >
                  Unsubscribe
                </Button>
              ) : (
                <form
                  action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="priceId"
                    value={plan?.stripePriceKey}
                  />
                  <input type="hidden" name="planId" value={plan?._id} />
                  <Button
                    variant="success"
                    className="btn btn-base rounded-pill px-4 my-3"
                    type="submit"
                  >
                    Purchase Again
                  </Button>
                </form>
              )}
            </>
          ) : parseInt(plan.price) === 0 ? (
            <p className="free-empty"></p>
          ) : logedinUser?.subscriptionId ? (
            <Button
              variant="success"
              className="btn btn-base rounded-pill px-4 my-3"
              onClick={() => setBeforePurchaseModal(true)}
            >
              Purchase
            </Button>
          ) : (
            <form
              action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
              method="POST"
            >
              <input
                type="hidden"
                name="priceId"
                value={plan?.stripePriceKey}
              />
              <input type="hidden" name="planId" value={plan?._id} />
              <Button
                variant="success"
                className="btn btn-base rounded-pill px-4 my-3"
                type="submit"
              >
                Purchase
              </Button>
            </form>
          )}
        </div>
        <Modal
          scrollable={true}
          show={beforePurchaseModal}
          onHide={() => setBeforePurchaseModal(false)}
          centered
        >
          <SubscriptionInfo
            plan={plan}
            onHide={() => setBeforePurchaseModal(false)}
          />
        </Modal>
        <Modal
          scrollable={true}
          show={openModal}
          onHide={handleCloseModal}
          centered
          responsive
        >
          <SubscriptionCancleModal handleCloseModal={handleCloseModal} />
        </Modal>
      </div>
    </div>
  );
}

export default PricingCard;
