import React from "react";
import PageHeader from "../../component/PageHeader";
import RoomGeneration from "../../component/RoomGeneration";

const GenerateRoom = () => {
  return (
    <>
      <PageHeader />
      <RoomGeneration />
    </>
  );
};

export default GenerateRoom;
