import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { Card, Container } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { convertDate } from "../../../Utility/utility";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import InvoicePdf from "./InvoicePdf";

const OrderUI = ({ subscription }) => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const plan = subscription?.plan[0];
  const navigate = useNavigate();

  console.log(subscription)

  return (
    <div className="order-content">
      <Container>
        <Card className="shadow border-0 rounded p-4 pb-5">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h2 className="text-center">Plan - {subscription?.plan[0]?.planName}</h2>
            <button
              className="btn btn-white shadow-sm rounded-2 align-items-center "
              onClick={() => navigate(`${ROUTESCONSTANTS.subscriptionHistory}`)}
            >
              <span className="btn-text">Back</span>
            </button>
          </div>
          <div className="order-container">
            <div className="d-flex justify-content-between flex-wrap mb-3 order-header">
              <p>
                <strong>Invoice Id:</strong>
                <span>{subscription?.invoiceId}</span>
              </p>
              <p>
                <strong>subscription Id:</strong>
                <span>{subscription?.subscriptionId}</span>
              </p>
            </div>
            <div className="order-body">
              <div className="row g-3">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfBiography}
                    </b>
                    <p className="mt-2">No. Of Biography</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfBlogPost}
                    </b>
                    <p className="mt-2">No. of Blog</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfEmailCampaign}
                    </b>
                    <p className="mt-2">No. of Email</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfImproveContent}
                    </b>
                    <p className="mt-2">No. of Improve Content</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfInstagramQuotes}
                    </b>
                    <p className="mt-2">No. of Instagram Quote</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfLeadsGeneration}
                    </b>
                    <p className="mt-2">No. of Lead Generation</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfNewsletterCampaign}
                    </b>
                    <p className="mt-2">No. of Newsletter</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfRoom}
                    </b>
                    <p className="mt-2">No. of Room Generation</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfSocialMediaContent}
                    </b>
                    <p className="mt-2">No. of Property Description</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {plan?.numberOfStepByStepGuide}
                    </b>
                    <p className="mt-2">No. of Step by Step Guide</p>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-between">
                <div className="col-lg-3 col-md-6 col-sm-6 offset-3 ml-auto price-section">
                  <h5 className="header">Total Amount</h5>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Sub Total</strong>:
                    <span className="w-8/12">
                      ${(subscription?.amountSubtotal / 100).toFixed(2)}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Total</strong>:
                    <span className="w-8/12">
                      ${(subscription?.amountTotal / 100).toFixed(2)}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Tax</strong>:
                    <span className="w-8/12">$00.00</span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Discount</strong>:
                    <span className="w-8/12">$00.00</span>
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 ml-auto info-section">
                  <h5 className="header">Order Details</h5>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Name</strong>
                    <span className="w-8/12">
                      {user?.firstName} {user?.lastName}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Email</strong>
                    <span className="w-8/12">{user?.email}</span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Activate Date</strong>
                    <span className="w-8/12">
                      {convertDate(subscription?.activatedAt)}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Created Date</strong>
                    <span className="w-8/12">
                      {convertDate(subscription?.plan[0]?.createdAt)}
                    </span>
                  </p>

                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Expires Date</strong>
                    <span className="w-8/12">
                      {convertDate(subscription?.expiresAt)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <PDFDownloadLink
              document={<InvoicePdf subscription={subscription} user={user} />}
              fileName={`Real_Estate_invoice_${subscription?.invoiceId}.pdf`}
            >
              <>
                <button type="button" className="btn btn-base rounded-3">
                  <FaDownload />
                  <span className="ml-2"> Download Invoice</span>
                </button>
              </>
            </PDFDownloadLink>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default OrderUI;
