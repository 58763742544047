import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { CiCirclePlus } from "react-icons/ci";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { shortenText } from "../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import { ROUTESCONSTANTS } from "../../constants/Routes";
import { getUserDetails, logout } from "../../service";
import AddAssistantModal from "./AddAssistantModal";
import UpdateAssistantModal from "./UpdateAssistantModal";
import ViewAssistantModal from "./ViewAssistantModal";

const initialState = {
  name: "",
  instructions: "",
  tools: [],
  model: "",
  files: [],
};

const AssistantPages = () => {
  const loggedInUser = getUserDetails();
  // console.log(loggedInUser?.user?.assistantPermission);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [allAssistant, setAllAssistant] = useState([]);
  const [showFileLimitMessage, setShowFileLimitMessage] = useState(false);
  const [assistantData, setAssistantData] = useState(initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  // handle file changes;
  const handleFileChange = (e) => {
    const files = e.target.files;

    if (!assistantData || !assistantData.files) {
      // Handle the case where assistantData or assistantData.files is undefined
      return;
    }

    const totalFileSize = assistantData.files.reduce(
      (total, file) => total + file?.size,
      0
    );

    if (
      files.length + assistantData.files.length > 5 ||
      totalFileSize > 50 * 1024 * 1024
    ) {
      setShowFileLimitMessage(true);
      setShowToast(true);
    } else {
      setShowFileLimitMessage(false);
      setShowToast(false);
      setAssistantData((prevData) => ({
        ...prevData,
        files: [...prevData.files, ...files],
      }));
      // e.target.value = null; // Clear the file input field
    }
  };

  // Handle Tools Change
  const handleToolChange = (selectedTool) => {
    setAssistantData((prevData) => {
      const toolsArray = prevData?.tools || [];
      const findItem = toolsArray.find((item) => item.type === selectedTool);
      if (findItem) {
        // If tools is already selected, remove it
        return {
          ...prevData,
          tools: toolsArray.filter((tool) => tool.type !== selectedTool),
        };
      } else {
        // If tools is not selected, add it
        return {
          ...prevData,
          tools: [...toolsArray, { type: selectedTool }],
        };
      }
    });
  };

  // Remove file from files array
  const removeFile = (index) => {
    setAssistantData((prevData) => {
      const updatedFiles = [...prevData.files];
      updatedFiles.splice(index, 1);
      const totalFileSize = updatedFiles.reduce(
        (total, file) => total + file?.size,
        0
      );
      if (updatedFiles.length <= 5 && totalFileSize <= 50 * 1024 * 1024) {
        setShowFileLimitMessage(false);
        setShowToast(false);
      }
      return { ...prevData, files: updatedFiles };
    });
  };

  // Format file size
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  // Handle Create Assistant
  const handleCreateAssistant = async () => {
    setIsCreating(true);

    try {
      setShowToast(true);
      const formData = new FormData();
      formData.append("name", assistantData.name);
      formData.append("instructions", assistantData.instructions);
      formData.append("model", assistantData.model);
      if (assistantData.tools) {
        formData.append("tools", JSON.stringify(assistantData.tools));
      }
      (assistantData.files || []).forEach((file) => {
        formData.append("files", file);
      });

      const response = await axiosSecure.post(
        "/assistants/createassistant",
        formData,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      toast.success(response?.data?.message);

      if (response.data?.assistant) {
        setAllAssistant((prevData) => {
          const temp = JSON.parse(JSON.stringify(prevData));
          temp.unshift(response.data?.assistant);
          return temp;
        });
      }
      setAssistantData(initialState);
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsCreating(false);
    }
  };

  // Get All Assistants
  useEffect(() => {
    const controller = new AbortController();
    const fetchAssistants = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/assistants`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setAllAssistant(response?.data?.assistants);
      } catch (error) {
        if (error.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(error?.response?.data?.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAssistants();

    return () => controller && controller.abort();
  }, [navigate]);

  return (
    <>
      <div className="assistant-prompt-page">
        <div className="container">
          <div className="">
            <h2 className="text-center">Generate Your Assistant </h2>
            <p className="text-center">
              Get started by selecting a template from the list below.
            </p>
          </div>
          <div className="row mt-3 mt-md-2 g-4">
            <div className="col-lg-4 col-md-6  col-md-offset-4">
              <Link onClick={handleShow}>
                <div className="assistant-prompt-card bg-white">
                  <div className="text-center">
                    <CiCirclePlus size={60} className="prompt-icon" />
                  </div>
                  <p className="prompt-title mt-2">Add New Assistant</p>
                </div>
              </Link>
            </div>

            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              allAssistant?.length > 0 &&
              allAssistant.map((assistant, i) => (
                <AssistantCard
                  key={assistant?._id}
                  assistant={assistant}
                  allAssistant={allAssistant}
                  setAllAssistant={setAllAssistant}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <AddAssistantModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        assistantData={assistantData}
        setAssistantData={setAssistantData}
        handleToolChange={handleToolChange}
        handleFileChange={handleFileChange}
        removeFile={removeFile}
        formatBytes={formatBytes}
        showFileLimitMessage={showFileLimitMessage}
        handleCreateAssistant={handleCreateAssistant}
        isCreating={isCreating}
      />
    </>
  );
};

export default AssistantPages;

const AssistantCard = ({
  assistant,
  allAssistant,
  setAllAssistant,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showViewModal, setShowViewModal] = useState(false);
  const handleCloseViewModal = () => setShowViewModal(false);
  const handleShowViewModal = () => setShowViewModal(true);

  return (
    <>
      <div className="col-lg-4 col-md-6  col-md-offset-4">
        <div className="asst-card">
          <Link to={`/assistants/${assistant?.assistant_id}`}>
            <div className="assistant-prompt-card">
              <div className="text-center">
                <img
                  // src={assistantLogo}
                  src={process.env.PUBLIC_URL + "/favicon.png"}
                  alt="Assistant Logo"
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <p className="prompt-title">{shortenText(assistant?.name, 15)}</p>
              <p className="prompt-subtitle">
                {shortenText(assistant?.instructions, 60)}
              </p>
            </div>
          </Link>
          {/* {isHovered && ( */}
          <div className="cardButtons">
            <button className="custom__btn" onClick={handleShow}>
              <FaEdit />
            </button>
            <button className="custom__btn" onClick={handleShowViewModal}>
              <FaEye />
            </button>
          </div>
          {/* )} */}
        </div>
      </div>

      <UpdateAssistantModal
        show={show}
        handleClose={handleClose}
        assistant={assistant}
        setAllAssistant={setAllAssistant}
      />

      <ViewAssistantModal
        showViewModal={showViewModal}
        handleCloseViewModal={handleCloseViewModal}
        assistant={assistant}
      />
    </>
  );
};
