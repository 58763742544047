
import 'bootstrap/dist/css/bootstrap.min.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { axiosOpen } from "../../api/axios";
import PageHeader from "../../component/PageHeader";
import { ROUTESCONSTANTS } from "../../constants/Routes";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  // Define Yup validation schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

  // Initial form values
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  // Form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    const requestPayload = {
      ...values,
    };

    const resetToken = localStorage.getItem("resetPassToken");
    if (resetToken) {
      const payload = {
        ...requestPayload,
        resetToken,
      }
      try {
        const response = await axiosOpen.put("/auth/reset-password", payload);

        toast.success(response.data.message);
        localStorage.removeItem("resetPassToken");
        navigate(ROUTESCONSTANTS.signin, { replace: true });
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setSubmitting(false)
      }
    } else {
      toast.error("Reset Password Token expired.");
    }
  };

  return (
    <>
      <PageHeader />
      <div className="signup-page-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form className="signup-inner card">
                    <div className="text-center mb-4">
                      <h2>Reset Password</h2>
                    </div>

                    <div className="form-group mb-3" style={{ position: "relative" }}>
                      {/* <label htmlFor="password">Password</label> */}
                      <Field
                        placeholder="New password"
                        type={visible ? 'text' : 'password'}
                        className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                        id="password"
                        name="password"
                      />

                      <ErrorMessage name="password" component="div" className="text-danger" />
                      {visible ? (
                        <AiOutlineEye
                          size={25}
                          onClick={() => setVisible(false)}
                          className="visibleIcon"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={25}
                          onClick={() => setVisible(true)}
                          className="visibleIcon"
                        />
                      )}
                    </div>

                    <div className="form-group mb-3" style={{ position: "relative" }}>
                      {/* <label htmlFor="password">Password</label> */}
                      <Field
                        placeholder="Confirm password"
                        type={confirmVisible ? 'text' : 'password'}
                        className={`form-control ${errors.confirmPassword && touched.confirmPassword ? 'is-invalid' : ''}`}
                        id="confirmPassword"
                        name="confirmPassword"
                      />

                      <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                      {confirmVisible ? (
                        <AiOutlineEye
                          size={25}
                          onClick={() => setConfirmVisible(false)}
                          className="visibleIcon"
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          size={25}
                          onClick={() => setConfirmVisible(true)}
                          className="visibleIcon"
                        />
                      )}
                    </div>

                    <div className="col-12 mb-4">
                      <button className="btn btn-base w-100 sign-form-btn">
                        Reset Password
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;




// import React, { useState } from "react";
// import PageHeader from "../../component/PageHeader";
// import TextField from "../../Common/TextField";
// import { toast } from "react-toastify";
// import { axiosOpen } from "../../api/axios";
// import { useNavigate } from "react-router-dom";
// import { ROUTESCONSTANTS } from "../../constants/Routes";
// import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

// const ResetPassword = () => {
//   const navigate = useNavigate();
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [passwordError, setPasswordError] = useState("");
//   const [confirmPasswordError, setConfirmPasswordError] = useState("");
//   const [visible, setVisible] = useState(false);
//   const [confirmVisible, setConfirmVisible] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log({ password, confirmPassword });
//     const resetToken = localStorage.getItem("resetPassToken");
//     if (resetToken) {
//       try {
//         const response = await axiosOpen.put("/auth/reset-password", {
//           resetToken,
//           password,
//           confirmPassword,
//         });

//         toast.success(response.data.message);
//         localStorage.removeItem("resetPassToken");
//         navigate(ROUTESCONSTANTS.signin, { replace: true });
//       } catch (err) {
//         toast.error(err?.response?.data?.message);
//       }
//     } else {
//       toast.error("Reset Password Token expired.");
//     }
//   };

//   const validatePassword = () => {
//     if (!password) {
//       setPasswordError("Password is required");
//     } else if (password.length < 6) {
//       setPasswordError("Password must be at least 6 characters long");
//     } else {
//       setPasswordError("");
//     }
//   };

//   const validateConfirmPassword = () => {
//     if (!confirmPassword) {
//       setConfirmPasswordError("Confirm password is required");
//     } else if (confirmPassword !== password) {
//       setConfirmPasswordError("Passwords do not match");
//     } else {
//       setConfirmPasswordError("");
//     }
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//     setPasswordError("");
//     if (confirmPassword) {
//       validateConfirmPassword();
//     }
//   };

//   const handleConfirmPasswordChange = (e) => {
//     setConfirmPassword(e.target.value);
//     setConfirmPasswordError("");
//     validateConfirmPassword();
//   };

//   const handlePasswordBlur = () => {
//     validatePassword();
//     validateConfirmPassword();
//   };

//   const handleConfirmPasswordBlur = () => {
//     validateConfirmPassword();
//   };

//   return (
//     <div>
//       <PageHeader />
//       <div className="signin-page-area pd-top-100 ">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-xl-6 col-lg-7">
//               <form onSubmit={handleSubmit} className="signin-inner card mt-5">
//                 <div className="text-center mb-4">
//                   <h2>Reset Password</h2>
//                 </div>
//                 <div className="row">
//                   <div className="col-12 mb-3 passwordField">
//                     <TextField
//                       type={visible ? "text" : "password"}
//                       value={password}
//                       onChange={handlePasswordChange}
//                       onBlur={handlePasswordBlur}
//                       placeholder="New Password"
//                       required
//                     />
//                     {
//                       visible ? (
//                         <AiOutlineEye
//                           className='visibleIcon'
//                           size={25}
//                           onClick={() => setVisible(false)}
//                         />
//                       ) : (
//                         <AiOutlineEyeInvisible
//                           className='visibleIcon'
//                           size={25}
//                           onClick={() => setVisible(true)}
//                         />
//                       )
//                     }
//                     <div style={{ color: "red", marginTop: "-1rem" }}>
//                       {" "}
//                       {passwordError && <span>{passwordError}</span>}{" "}
//                     </div>
//                   </div>
//                   <div className="col-12  mb-3 passwordField">
//                     <TextField
//                       type={confirmVisible ? "text" : "password"}
//                       placeholder="Confirm Password"
//                       value={confirmPassword}
//                       onChange={handleConfirmPasswordChange}
//                       onBlur={handleConfirmPasswordBlur}
//                       required
//                     />
//                     {
//                       confirmVisible ? (
//                         <AiOutlineEye
//                           className='visibleIcon'
//                           size={25}
//                           onClick={() => setConfirmVisible(false)}
//                         />
//                       ) : (
//                         <AiOutlineEyeInvisible
//                           className='visibleIcon'
//                           size={25}
//                           onClick={() => setConfirmVisible(true)}
//                         />
//                       )
//                     }
//                     <div style={{ color: "red", marginTop: "-1rem" }}>
//                       {" "}
//                       {confirmPasswordError && (
//                         <span>{confirmPasswordError}</span>
//                       )}{" "}
//                     </div>
//                   </div>
//                   <div className="col-12">
//                     <button className="btn btn-base w-100">Submit</button>
//                   </div>
//                   {/* <div className="col-12">
// 			              <Link to="/forgot-password">Forgottem Your Password</Link>
// 			              <Link to="/sign-up"><strong>Signup</strong></Link>
// 			            </div> */}
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ResetPassword;
