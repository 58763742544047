import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../api/axios";
import { ROUTESCONSTANTS } from "../constants/Routes";
import { logout } from "../service";

const useRemaining = () => {
  const [remaining, setRemaining] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();

    const fetchRemainingPlan = async () => {
      try {
        const response = await axiosSecure.get("/remaining", {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setRemaining(response?.data?.data);
      } catch (err) {
        if (err.response.status === 403) {
          logout();
          navigate(ROUTESCONSTANTS.signin, { replace: true });
        } else {
          toast.error(err?.response?.data?.message);
        }
      }
    };
    fetchRemainingPlan();

    return () => controller && controller.abort();
  }, [navigate]);

  return { remaining, setRemaining };
};

export default useRemaining;
