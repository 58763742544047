const inputPropertyDescriptionDynamicPrompt = [
  { value: "{{property}}", label: "Property Type" },
  { value: "{{state}}", label: "State" },
  { value: "{{city}}", label: "City" },
  { value: "{{street}}", label: "Street" },
  { value: "{{targetBuyer}}", label: "Target Buyer" },
  { value: "{{styleTons}}", label: "Style Tons" },
  { value: "{{additionalInfo}}", label: "Additional Info" },
  { value: "{{language}}", label: "Language" },
  { value: "{{bed}}", label: "Bed" },
  { value: "{{bath}}", label: "Bath" },
  { value: "{{SqFt}}", label: "SqFt" },
  { value: "{{platform}}", label: "Platform" },
];

const inputBiographyDynamicPrompt = [
  { value: "{{additionalInfo}}", label: "Additional Info" },
  { value: "{{firstName}} {{lastName}}", label: "User Name" },
  { value: "{{phoneNumber}}", label: "Phone Number" },
  { value: "{{email}}", label: "Email" },
  { value: "{{website}}", label: "Website" },
];

const inputNewsLetterDynamicPrompt = [
  { value: "{{schedule}}", label: "Schedule" },
];

const inputEmailCampaignDynamicPrompt = [
  { value: "{{recipient}}", label: "Recipient" },
];

const inputBlogPostDynamicPrompt = [
  { value: "{{headline}}", label: "Headline" },
];

const inputQuotesForInstagramDynamicPrompt = [
  { value: "{{topic}}", label: "Topic" },
];
const inputLeadGenerationDynamicPrompt = [
  { value: "{{topic}}", label: "Topic" },
];
const inputImproveContentDynamicPrompt = [
  { value: "{{content}}", label: "Content" },
];
const inputStepByStepGuideDynamicPrompt = [
  { value: "{{topic}}", label: "Topic" },
];

export const getDynamicInputArray = (templateIdentifier) => {
  switch (templateIdentifier) {
    case "property-description":
      return inputPropertyDescriptionDynamicPrompt;
    case "biography":
      return inputBiographyDynamicPrompt;
    case "real-estate-blog-post":
      return inputBlogPostDynamicPrompt;
    case "newsletter-campaign":
      return inputNewsLetterDynamicPrompt;
    case "email-campaign":
      return inputEmailCampaignDynamicPrompt;
    case "quotes-for-instagram": // new template comes in
      return inputQuotesForInstagramDynamicPrompt;
    case "leads-generation":
      return inputLeadGenerationDynamicPrompt;
    case "improve-content":
      return inputImproveContentDynamicPrompt;
    case "step-by-step-guide":
      return inputStepByStepGuideDynamicPrompt;

    default:
      return [];
  }
};
