import { Field, Formik } from "formik";
import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { Toaster } from "../../../component/Toaster/Toaster";
import { ROUTESCONSTANTS } from "../../../constants/Routes";
import { logout } from "../../../service";
import "./users.scss";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is Required"),
  lastName: Yup.string().required("Last Name is Required"),
  email: Yup.string()
    .email("Invalid your email format")
    .required("Email is Required!"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is Required"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

// const handleOnSubmit = (values) =>
//   axiosSecure.post(
//     "/auth/register",
//     {
//       fname: values.firstName,
//       lname: values.lastName,
//       password: values.password,
//       branch: values.branch,
//       email: values.email,
//       username: values.username,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${
//           localStorage.userDetails && JSON.parse(localStorage.userDetails).token
//         }`,
//       },
//     }
//   );

const AddUser = () => {
  const navigate = useNavigate();
  const [showAddToaster, setShowAddToaster] = useState(false);
  const [showErrorToaster, setShowErrorToaster] = useState(false);
  const [error, setError] = useState("");

  return (
    <div className="flex-grow-1">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const requestPayload = {
            ...values,
            userType: 2,
          };
          try {
            const response = await axiosSecure.post(
              "/admin/user/add",
              requestPayload,
              {
                headers: { Authorization: getAuthorizationHeader() },
              }
            );

            toast.success(response?.data?.message);
            navigate("/user", { replace: true });
          } catch (err) {
            if (err.response.status === 403) {
              logout();
              navigate(ROUTESCONSTANTS.signin, { replace: true });
            } else {
              toast.error(err?.response?.data?.message);
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Container className="add-user-page d-flex flex-column justify-content-center">
              <div className="form-title">
                <h5 className="fw-bold mb-3">Add Trainer</h5>

                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="mb-3"
                  onClick={() => navigate(-1)}
                >
                  <BiArrowBack /> Back
                </Button>
              </div>
              {/* <h5 className="fw-bold mb-3">User Details</h5> */}
              <Row>
                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="firstName"
                    label="First Name"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className={`form-control ${touched.firstName && errors.firstName
                          ? "is-invalid"
                          : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.firstName && errors.firstName
                        ? errors.firstName
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="lastName"
                    label="Last Name"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.lastName && errors.lastName
                        ? errors.lastName
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="email"
                    label="Email"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.email && errors.email ? errors.email : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="floatingLastName"
                    label="Password"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="password"
                      name="password"
                      placeholder="Password"
                      className={`form-control ${touched.password && errors.password ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.password && errors.password
                        ? errors.password
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row></Row>
              <Col md={12} lg={12} xl={12} className="mt-4 mb-4 ">
                <Button type="submit" disabled={isSubmitting}>
                  CREATE USER
                </Button>
              </Col>
            </Container>
          </Form>
        )}
      </Formik>
      <Toaster
        title="User added successfully"
        bg="success"
        showToaster={showAddToaster}
        setShowToaster={setShowAddToaster}
        to="user"
      ></Toaster>
      <Toaster
        title={error}
        bg="danger"
        showToaster={showErrorToaster}
        setShowToaster={setShowErrorToaster}
        to="user/add"
      ></Toaster>
    </div>
  );
};

export default AddUser;
