import React from "react";
import LoadingDots from "../../Common/LoadingDots";

const OriginalPhoto = ({ imgUrl }) => {
  return (
    <article className="product-case">
      <div className="room-cover">
        <img src={imgUrl} alt="Original room" />
      </div>
      <header className="py-3 text-center">
        <button disabled className="btn dot-loading">
          <span className="">
            <LoadingDots color="white" size="large" />
          </span>
        </button>
      </header>
    </article>
  );
};

export default OriginalPhoto;
