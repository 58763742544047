import { Field } from "formik";
import { Col, FloatingLabel, FormControl } from "react-bootstrap";

const PlanInput = ({ touched, errors, name, title, type }) => {

  return (

    <Col md={6} lg={6} xl={6}>
      <FloatingLabel
        controlId={name}
        label={title}
        className="mb-3"
      >
        <Field
          as={FormControl}
          type={type || 'text'}
          name={name}
          placeholder={title}
          className={`form-control ${touched[name] &&
            errors[name]
            ? "is-invalid"
            : ""
            }`}
        />
        <div className="invalid-feedback">
          {touched[name] &&
            errors[name]
            ? errors[name]
            : null}
        </div>
      </FloatingLabel>
    </Col>
  )
}

export default PlanInput;