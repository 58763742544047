const convertDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // getMonth() returns 0-based month index
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;
  } else {
    return "";
  }
};

export { convertDate };

export const getLogedInUser = () =>
  localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails")).user
    : null;

export const getRealtorProfile = () =>
  localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails")).realtorProfile
    : null;

export const limitWord = (word, limit) => {
  if (word.length <= limit) {
    return word;
  }
  return word.slice(0, limit) + "...";
};

export function timeAgo(timestamp) {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds

  const seconds = currentTimestamp - timestamp;

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "min", seconds: 60 },
    { label: "sec", seconds: 1 },
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const count = Math.floor(seconds / interval.seconds);

    if (count >= 1) {
      return count === 1
        ? `${count} ${interval.label} ago`
        : `${count} ${interval.label}s ago`;
    }
  }

  return "Just now";
}

export function shortenText(text, maxLength) {
  // Check if the text is longer than the specified maxLength
  if (text.length > maxLength) {
    // Shorten the text and add an ellipsis
    return text.slice(0, maxLength) + "...";
  } else {
    // If the text is already within the limit, return the original text
    return text;
  }
}

export function isExpired() {
  const userData = localStorage.getItem("userDetails")
    && JSON.parse(localStorage.getItem("userDetails")).user;

  const exDate = new Date(userData.subscriptionExpiration);
  const today = new Date();
  const isToday = checkIsToday(exDate, today);
  if (isToday) {
    return false;
  };

  return (today > exDate);
};

const checkIsToday = (exDate, today) => {
  return (
    exDate.getFullYear() === today.getFullYear() &&
    exDate.getMonth() === today.getMonth() &&
    exDate.getDate() === today.getDate()
  );
};
