import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

function ViewAssistantModal({
  showViewModal,
  handleCloseViewModal,
  assistant,
  handleViewAssistant,
}) {
  return (
    <>
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h6>View Assistant</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="custom-card">
            <Card.Body>
              <Card.Text>
                <b>Assistant Name:</b> {assistant?.name}
              </Card.Text>
              <Card.Text className="my-2 text-muted">
                <b>Model:</b> {assistant?.model}
              </Card.Text>
              <Card.Text>
                <b>Instruction:</b> {assistant?.instructions}
              </Card.Text>

              <Card.Text>
                <b>Tools:</b> {assistant?.tools.map((tool) => tool.type)}
              </Card.Text>

              <Card.Text>
                <b>Created Date:</b> {assistant?.createdAt}
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleViewAssistant}>
            Update
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ViewAssistantModal;
